<template>
  <v-flex d-flex class="flash-game-info">
    <v-flex d-flex v-if="!!roomCurrentMission && !!roomID" text-xs-center>
      <v-layout column>
        <v-flex d-flex>
          <PlayMode v-if="currentMission" />
        </v-flex>
        <v-flex shrink>
          <v-layout row>
            <v-flex d-flex shrink>
              <v-layout row>
                <v-flex d-flex align-center mr-2>
                  <img
                    class="ice-icon"
                    :src="require('../assets/ice-icon.svg')"
                  />
                </v-flex>
                <v-flex d-flex align-center class="bottom-trivia-text">
                  <v-layout class="ice-breakers-trivia-text">
                    <v-flex d-flex align-end class="ice-breakers-text">
                      ICE BREAKERS
                    </v-flex>
                    <v-flex
                      d-flex
                      align-end
                      class="ice-breakers-separator"
                      ml-2
                      mr-2
                      >|</v-flex
                    >
                    <v-flex d-flex align-end class="trivia-text">
                      {{ game.name }}
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex />
            <v-flex shrink d-flex class="flash-card-nav-bar">
              <v-layout>
                <v-layout
                  v-if="!firstMission"
                  @click="prevMission"
                  class="prev"
                >
                  <v-flex d-flex align-center>
                    <v-flex class="arrow" />
                  </v-flex>
                  <v-flex d-flex align-center ml-1 mr-2 class="arrow-text"
                    >BACK</v-flex
                  >
                </v-layout>
                <v-flex
                  d-flex
                  align-center
                  class="arrow-separator"
                  v-if="!firstMission && !lastMission"
                  >|</v-flex
                >
                <v-layout v-if="!lastMission" @click="nextMission" class="next">
                  <v-layout>
                    <v-flex d-flex align-center ml-2 mr-1 class="arrow-text"
                      >NEXT</v-flex
                    >
                    <v-flex d-flex align-center style="margin-right: 150px">
                      <v-flex class="arrow" />
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex"
import PlayMode from "@/components/GroupTeams/Common/GameModes/PlayModePreGame"

export default {
  name: "GameFlashCards",
  components: {
    PlayMode
  },
  props: {
    game: {
      type: Object,
      required: true
    },
    announcementVideo: String,
    selectedUserID: String
  },
  data() {
    return {
      timeout: null,
      playAnnouncement: false,
      loading: false,
      time: 0,
      startTime: null,
      activeTab: null
    }
  },
  beforeDestroy() {
    this.$store.dispatch("unsubscribeFromPlays")
  },
  async created() {
    await this.$store.dispatch("fetchMissions", this.game.id)
    await this.$store.dispatch("setLocalGame", this.game)
    await this.$store.dispatch("updatePreGameMode", "play")
    await this.$store.dispatch("subscribeToPlays", null, { root: true })
    if (this.roomID && !this.roomCurrentMission && this.missions) {
      console.log("MISSIONS", this.missions[0])
      await this.$store.dispatch("livechat/updateRoomMission", this.missions[0])
      await this.$store.dispatch("setLocalCurrentMission", this.missions[0])
    }
  },
  // async mounted() {
  //
  // },
  methods: {
    nextMission() {
      this.moveMission("next")
    },
    prevMission() {
      this.moveMission("previous")
    },
    moveMission(direction) {
      var pos = 0
      var arr = this.missions
      console.log("Current Mission ID", this.currentMission.id)
      console.log("")
      var currentPos = 0
      for (var i in arr) {
        if (arr[i].id == this.currentMission.id) {
          console.log("Mission Name ", arr[i].name)
          console.log("Mission ID ", arr[i].id)
          currentPos = i
        }
      }
      if (direction == "next") {
        if (currentPos < arr.length) {
          pos = parseInt(currentPos) + 1
        } else {
          pos = 0
        }
      }
      if (direction == "previous") {
        if (currentPos > 0) {
          pos = parseInt(currentPos) - 1
        } else {
          pos = arr.length
        }
      }
      console.log("Current POS = ", currentPos)
      console.log("NEW POS = ", pos)
      arr[pos].roomID = this.roomID
      console.log("ARR")
      this.$store.dispatch("livechat/updateRoomMission", arr[pos])
    },
    onGameIntro() {
      console.log("GAME INTRO")
      if (this.isHost) {
        console.log("GAME INTRO")
        this.$emit("onGameIntro", this.game.id)
      } else {
        this.playVideo()
      }
    },
    playVideo() {
      if (this.announcementVideo) {
        this.playAnnouncement = true
        this.$refs.video.currentTime = 0
        this.$refs.video.play()
      }
    },
    videoEnded() {
      console.info("ended")
      this.playAnnouncement = false
    }
  },
  watch: {
    roomID(newVal) {
      if (this.missions && newVal) {
        this.missions[0].roomID = newVal
        console.log("NEW ROOMID", newVal)
        console.log("THE MISSION TO SET", this.missions[0])
        this.$store.dispatch("livechat/updateRoomMission", this.missions[0])
        this.$store.dispatch("setLocalCurrentMission", this.missions[0])
      }
    },
    roomCurrentMission: {
      async handler(value) {
        if (value) {
          await this.$store.dispatch("setLocalCurrentMission", value)
          await this.$store.dispatch("updatePreGameMode", "play")
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters("livechat", ["roomID", "room", "max"]),
    ...mapGetters("auth", ["user", "isHost"]),
    missions() {
      var arr = Object.entries(this.$store.getters.missions)
      var newArr = []
      for (var i in arr) {
        arr[i][1].id = arr[i][0]
        arr[i][1].theKey = arr[i][0]
        newArr.push(arr[i][1])
      }
      return newArr
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    roomCurrentMission() {
      return this.room ? this.room.currentMission : null
    },
    firstMission() {
      return (
        this.currentMission &&
        this.missions[0] &&
        this.currentMission.id == this.missions[0].id
      )
    },
    lastMission() {
      return (
        this.currentMission &&
        this.missions[this.missions.length - 1] &&
        this.currentMission.id == this.missions[this.missions.length - 1].id
      )
    }
  }
}
</script>

<style lang="scss">
@import "../scss/main";
.flash-game-info {
  line-height: 1;
  .ice-icon {
    width: 33px;
    height: auto;
  }
  .flash-card-nav-bar {
    user-select: none;
    color: #fff;
    font-size: 24px;
    font-weight: bold;

    .arrow-text {
      font-size: 20px;
    }
    .arrow-separator {
      font-size: 17px;
      height: 31px;
    }
  }
  .trivia-text {
    color: $primary_accent_color;
    font-size: 17px;
    font-weight: 800;
  }
  .bottom-trivia-text {
    font-weight: bold;
  }
  .trivia-text {
    height: 28px;
    line-height: 24px;
    text-shadow: 1px 1px 1px rgba($color-black, 0.5);
  }
  .ice-breakers-text {
    font-size: 24px;
    line-height: 27px;
    color: $color-white;
    font-weight: 800;
    white-space: nowrap;
    text-shadow: 1px 1px 1px rgba($color-black, 0.25);
  }
  .ice-breakers-separator {
    color: $color-white;
    line-height: 27px;
  }
  .nav-icon {
    font-weight: bold;
    font-size: 40px !important;
  }
  .arrow {
    position: relative;
    width: 23px;
    height: 23px;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transition: all 0.1s ease-out;
    }
    &:before {
      background-image: url("../assets/arrow-white.svg");
    }
    &:after {
      background-image: url("../assets/arrow-orange.svg");
      opacity: 0;
    }
  }
  .next .arrow {
    &:before,
    &:after {
      right: 0;
    }
  }
  .prev .arrow {
    &:before,
    &:after {
      left: 0;
      transform: scaleX(-1);
    }
  }
  .next,
  .prev {
    transition: all 0.1s ease-out;
    cursor: pointer;
    &:hover {
      color: $primary_accent_color;
      .arrow:before {
        opacity: 0;
      }
      .arrow:after {
        opacity: 1;
      }
    }
  }
}
</style>
