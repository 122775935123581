var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "tournament-standing__body", attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c("v-flex", { attrs: { shrink: "" } }, [
            _c("header", { staticClass: "tournament-standing__body-header" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("svg-icon", { attrs: { name: "game-on" } })],
                1
              ),
              _c("h4", [
                _c("span", { staticClass: "font-bold" }, [
                  _vm._v(_vm._s(_vm.name))
                ]),
                !!_vm.round.label
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "tournament-standing__body-header__round-label"
                      },
                      [_vm._v(" ROUND " + _vm._s(_vm.round.num + 1) + " ")]
                    )
                  : _vm._e()
              ]),
              _c("span", {
                staticClass: "tournament-standing__body-header__divider"
              }),
              _c(
                "span",
                { staticClass: "tournament-standing__body-header__accent" },
                [_vm._v(_vm._s(_vm.numberOfTeams) + " ")]
              ),
              _c(
                "span",
                { staticClass: "tournament-standing__body-header__label" },
                [_vm._v("Teams")]
              ),
              _c("span", {
                staticClass: "tournament-standing__body-header__divider"
              }),
              _c(
                "span",
                { staticClass: "tournament-standing__body-header__accent" },
                [_vm._v(_vm._s(_vm.numberOfGames) + " ")]
              ),
              _c(
                "span",
                { staticClass: "tournament-standing__body-header__label" },
                [
                  _vm.status == "ended"
                    ? _c("span", [_vm._v("Ended ")])
                    : _vm.status == "active"
                    ? _c("span", [_vm._v("Active ")])
                    : _vm.status == "incoming"
                    ? _c("span", [_vm._v("Incoming ")])
                    : _vm._e(),
                  _c("span", [_vm._v("Games")])
                ]
              )
            ])
          ]),
          _vm.round && !!_vm.round.teams
            ? _c(
                "v-flex",
                {
                  staticClass: "tournament-standing__body-inner",
                  attrs: { "d-flex": "" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "tournament-standing__body-inner__absolute-wrap"
                    },
                    [
                      !!_vm.finalGameWinner
                        ? _c(
                            "v-flex",
                            {
                              staticClass:
                                "tournament-standing__body__absolute-winner",
                              attrs: { "ml-4": "" }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticStyle: { position: "relative" },
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass:
                                          "tournament-standing__body__absolute-winner__final-round-cup",
                                        attrs: { name: "cup", size: "large" }
                                      }),
                                      _c("logo-small", {
                                        staticClass:
                                          "tournament-standing__body__absolute-winner__rtb-logo svg-logo--colored"
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        "d-flex": "",
                                        "align-center": "",
                                        "ml-3": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { column: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "tournament-standing__body__absolute-winner__title"
                                            },
                                            [
                                              _c("h1", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.finalGameWinner.name
                                                  ) + " WIN!!!"
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "tournament-standing__body__absolute-winner__score-text"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "tournament-standing__body__absolute-winner__score"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.finalGameWinner
                                                          .score
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "tournament-standing__body__absolute-winner__pts"
                                                },
                                                [_vm._v(" PTS ")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            column: "",
                            "ml-3": "",
                            "mr-3": "",
                            "mt-2": "",
                            "mb-2": ""
                          }
                        },
                        _vm._l(_vm.normalizedTeamsGroupedByGameId, function(
                          group,
                          gameID
                        ) {
                          return _c(
                            "TournamentStandingsGame",
                            {
                              key: "tournament-standing-" + gameID,
                              class:
                                _vm.final && !!_vm.gameInfo[gameID].endTimestamp
                                  ? "tournament-standing__absolute-winner"
                                  : null,
                              attrs: {
                                user: _vm.getHostByGroup(group),
                                broadcast: !!_vm.broadcastUrl(gameID)
                              },
                              on: {
                                onMouseOver: _vm.onMouseOver,
                                onMouseLeave: _vm.onMouseLeave,
                                onWatchLive: function($event) {
                                  return _vm.onWatchLive(gameID)
                                }
                              }
                            },
                            _vm._l(group, function(team) {
                              return _c("TournamentTeam", {
                                key: "tournament-standing-team-" + team.id,
                                staticClass: "tournament-standing__body-team",
                                attrs: { team: team },
                                on: {
                                  onMouseOver: _vm.onMouseOver,
                                  onMouseLeave: _vm.onMouseLeave
                                }
                              })
                            }),
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            : _c(
                "v-flex",
                {
                  staticClass: "tournament-standing__body-inner",
                  attrs: {
                    "d-flex": "",
                    "align-center": "",
                    "text-xs-center": ""
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "tournament-standing__body-inner__no-data" },
                    [_vm._v(" NOT STARTED ")]
                  )
                ]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }