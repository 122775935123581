var render = function(_h, _vm) {
  var _c = _vm._c
  return _vm._m(0)
}
var staticRenderFns = [
  function(_h, _vm) {
    var _c = _vm._c
    return _c("div", { staticClass: "tournament-watch-live-small" }, [
      _c("div", { staticClass: "tournament-watch-live-small__watch" }, [
        _vm._v("WATCH")
      ]),
      _c("div", { staticClass: "tournament-watch-live-small__live" }, [
        _vm._v("LIVE")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }