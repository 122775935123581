<script>
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"
import { VIcon } from "vuetify/lib"

export default Vue.extend({
  name: "VidePlayPauseBtn",
  functional: true,
  props: {
    state: {
      /** @type { import("vue").PropType<"pause", | "play"> } */
      type: String,
      required: true,
      validator: val => ["pause", "play"].includes(val)
    }
  },
  render(h, { props, data }) {
    return h(
      "button",
      mergeData(
        { attrs: { type: "button" }, staticClass: "video-play-pause-btn" },
        data
      ),
      [h(VIcon, null, props.state === "pause" ? "pause" : "play_arrow")]
    )
  }
})
</script>

<style lang="scss">
.video-play-pause-btn {
  width: 40px;
  height: 40px;
  background-color: $primary_accent_color;
  border-radius: 50%;
  color: $color-white;

  &:focus {
    // TODO(Andrew): a11y
    outline: 0;
  }
}

.video-container {
  $block: &;

  &__btn {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    // TODO(Andrew): remove magic number
    transform: translate(-50%, calc(-50% - 8px));
    opacity: 0;
  }

  &:hover {
    #{$block}__btn {
      opacity: 1;
    }
  }
}
</style>
