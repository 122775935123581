<template>
  <v-layout row fill-height class="pre-play-mode-wrap" justify-center>
    <v-flex xs2 pr-2 />
    <v-flex xs6 class="play-mode">
      <RtbCard class="canvas-rtb-card" v-if="isDrawingMission">
        <transition name="mission-result-transition" mode="out-in">
          <ResultContainer
            v-if="currentMode === 'results'"
            class="result-component"
          />
          <Drawing v-else :bgColor="'#efefef'" :width="510" :height="270" />
        </transition>
      </RtbCard>

      <MissionContainer v-else :mode="currentMode">
        <MultipleChoice
          v-if="missionType === 'Multiple Choice'"
          :mode="currentMode"
        />
        <Poll v-else-if="missionType === 'Poll'" :mode="currentMode" />
        <Giphy v-else-if="missionType === 'Giphy'" :mode="currentMode" />
        <Lipdub v-else-if="missionType === 'Lipdub'" :mode="currentMode" />
        <TakePhoto
          v-else-if="missionType === 'Take Photo'"
          :mode="currentMode"
        />
        <Photo v-else-if="missionType === 'Photo'" :mode="currentMode" />
        <URL v-else-if="missionType === 'URL'" :mode="currentMode" />
        <TextInput
          v-else-if="
            missionType === 'Text' ||
            missionType === 'Freeform' ||
            missionType === 'Team Name' ||
            missionType === 'Fact Match'
          "
          :mode="currentMode"
        />
        <Categories
          v-else-if="missionType === 'Categories'"
          :mode="currentMode"
        />
        <TextOnly
          v-else-if="
            missionType === 'Buzz In' ||
            missionType === 'Player Rating' ||
            missionType === 'Info'
          "
          :mode="currentMode"
        />
      </MissionContainer>
    </v-flex>
    <v-flex d-flex xs4 pl-2>
      <transition name="canvas-tools-slide-transition" mode="out-in">
        <v-flex :key="`asset`" v-if="hasAssets" class="asset-column">
          <v-layout column justify-center fill-height>
            <v-flex d-flex class="play-asset">
              <AssetMapper :mode="currentMode" />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          :key="`tool`"
          d-flex
          align-center
          v-else-if="hasPallette"
          class="asset-column"
        >
          <RtbCard class="canvas-tools-rtb-card">
            <v-layout row>
              <v-flex shrink>
                <v-layout column class="draw-tools-left-col">
                  <v-flex d-flex shrink>
                    <v-layout column>
                      <v-flex align-center style="display: flex">
                        <div
                          class="draw-tool-btn"
                          :class="{
                            disabled: false, //!canPlayerDraw(),
                            active: isPen
                          }"
                          @click="setToPen"
                        >
                          <img
                            v-if="isPen"
                            width="28"
                            class="draw-tool-icon"
                            :src="
                              require('../../../../assets/pen-icon-yellow.png')
                            "
                          />
                          <img
                            v-else
                            width="28"
                            class="draw-tool-icon"
                            :src="
                              require('../../../../assets/pen-icon-white.png')
                            "
                          />
                          <div>Draw</div>
                        </div>
                      </v-flex>
                      <v-flex align-center style="display: flex">
                        <div
                          class="draw-tool-btn"
                          :class="{
                            disabled: false, //!canPlayerDraw(),
                            active: !isPen
                          }"
                          @click="setToEraser"
                        >
                          <img
                            v-if="!isPen"
                            width="28"
                            class="draw-tool-icon"
                            :src="
                              require('../../../../assets/eraser-icon-yellow.png')
                            "
                          />
                          <img
                            v-else
                            width="28"
                            class="draw-tool-icon"
                            :src="
                              require('../../../../assets/eraser-icon-white.png')
                            "
                          />
                          <div>Erase</div>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex d-flex shrink v-if="isHost">
                    <v-layout column>
                      <v-flex>
                        <v-btn class="clear-board-btn" @click="onClearTeam">
                          CLEAR<br />THIS
                        </v-btn>
                      </v-flex>
                      <v-flex>
                        <v-btn class="clear-all-btn" @click="onClearAll">
                          CLEAR<br />ALL
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex style="overflow: hidden; background-color: #fff">
                <v-layout column fill-height>
                  <v-flex>
                    <div class="pen-size-container">
                      <div
                        class="pen-size"
                        v-for="idx in 7"
                        :key="'pen-size-' + idx"
                        @click="onSizeClick(idx + 1)"
                        :class="{ active: isCurrentSize(idx + 1) }"
                      >
                        <svg
                          class="pen-size-svg"
                          :height="brushSizeBase(idx + 1)"
                          :width="brushSizeBase(idx + 1)"
                        >
                          <circle
                            class="filled-circle"
                            :cx="brushSizeBase(idx + 1) / 2"
                            :cy="brushSizeBase(idx + 1) / 2"
                            :r="brushSizeBase(idx + 1) / 2 - 3"
                            fill="#68625f"
                          />
                          <circle
                            class="outline-circle"
                            :cx="brushSizeBase(idx + 1) / 2"
                            :cy="brushSizeBase(idx + 1) / 2"
                            :r="brushSizeBase(idx + 1) / 2 - 2"
                            stroke-width="1"
                            fill="none"
                          />
                          Sorry, your browser does not support inline SVG.
                        </svg>
                      </div>
                    </div>
                  </v-flex>
                  <v-flex shrink class="select-color-text">
                    Select Color
                  </v-flex>
                  <v-flex d-flex>
                    <chrome-picker
                      class="color-picker"
                      v-model="colors"
                      :disableFields="true"
                      @input="onColorPickerUpdate"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </RtbCard>
        </v-flex>
      </transition>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"

const AssetMapper = () =>
  import("@/components/GroupTeams/Common/AssetMapper.vue")
import ResultContainer from "@/components/GroupTeams/Common/Games/ResultContainer.vue"
const MultipleChoice = () =>
  import("@/components/GroupTeams/Common/Games/MultipleChoice.vue")
const Poll = () => import("@/components/GroupTeams/Common/Games/Poll.vue")
const Giphy = () => import("@/components/GroupTeams/Common/Games/Giphy.vue")
const Lipdub = () => import("@/components/GroupTeams/Common/Games/Lipdub.vue")
const TextInput = () =>
  import("@/components/GroupTeams/Common/Games/TextInput.vue")
const TextOnly = () =>
  import("@/components/GroupTeams/Common/Games/TextOnly.vue")
const Categories = () =>
  import("@/components/GroupTeams/Common/Games/Categories.vue")
const TakePhoto = () =>
  import("@/components/GroupTeams/Common/Games/TakePhoto.vue")
const Photo = () => import("@/components/GroupTeams/Common/Games/Photo.vue")
const URL = () => import("@/components/GroupTeams/Common/Games/Url.vue")
const MissionContainer = () =>
  import("@/components/GroupTeams/Common/Games/MissionContainer.vue")
const RtbCard = () => import("@/components/GroupTeams/Common/Games/RtbCard.vue")
const Drawing = () => import("@/components/GroupTeams/Common/Games/Drawing.vue")

import { GameMission } from "@/entities/GameMission"
import { Chrome } from "vue-color"

export default {
  name: "PlayModePreGame",
  components: {
    Poll,
    MultipleChoice,
    TextInput,
    TextOnly,
    Giphy,
    MissionContainer,
    Categories,
    Lipdub,
    TakePhoto,
    Photo,
    URL,
    Drawing,
    RtbCard,
    ResultContainer,
    AssetMapper,
    "chrome-picker": Chrome
  },
  data() {
    return {
      colors: {
        rgba: "(0, 0, 0, 1)"
      }
    }
  },
  mounted() {
    this.setLineWidth({ lineWidth: this.brushSize(3) })
  },
  methods: {
    ...mapActions("drawing", [
      "setPenState",
      "setPenColor",
      "setLineWidth",
      "clearTeams",
      "clearOneTeam"
    ]),
    onClearTeam() {
      this.clearOneTeam({ teamID: this.assignedTeamID })
    },
    onClearAll() {
      this.clearTeams()
    },
    onColorPickerUpdate({ rgba }) {
      this.setPenColor({ color: { rgba } })
    },
    brushSize(value) {
      return Math.round((1 / 8) * Math.pow(value, 2.7))
    },
    brushSizeBase(idx) {
      return this.brushSize(idx) + 8
    },
    onSizeClick(idx) {
      this.setLineWidth({ lineWidth: this.brushSize(idx) })
    },
    isCurrentSize(idx) {
      return this.lineWidth === this.brushSize(idx)
    },
    setToPen() {
      this.setPenState({ isPen: true })
    },
    setToEraser() {
      this.setPenState({ isPen: false })
    }
  },
  computed: {
    ...mapState("group", ["currentGlobalTeam"]),
    ...mapGetters(["getCurrentMode"]),
    ...mapState("drawing", ["lineWidth", "isPen"]),
    ...mapState("drawing", { assignedTeamID: "teamID" }),
    mediaToShow() {
      if (
        this.gameStatus.media === "none" ||
        this.gameStatus.media === "facilitator" ||
        ((this.mode === "results" || this.mode === "welcome") &&
          this.gameStatus.media === "drawing")
      )
        return null

      return this.gameStatus.media
    },
    hasAssets() {
      return (
        !!this.currentMission.photo ||
        !!this.currentMission.audio ||
        !!this.currentMission.youtube
      )
    },
    isDrawingMission() {
      return (
        this.missionType === GameMission.DrawingEachTeam ||
        this.missionType === GameMission.DrawingPictionary ||
        this.missionType === GameMission.DrawingEveryone
      )
    },
    user() {
      return this.$store.state.auth.user
    },
    isPictionary() {
      return this.currentMission.behavior == "Draw: Pictionary"
    },
    hasPallette() {
      if ((this.currentMode !== "results") & (this.currentMode !== "welcome")) {
        if (this.isPictionary) {
          if (this.assignedTeamID == this.team.id || this.isHost) return true
        } else if (this.isDrawingMission) return true
      }
      return false
    },
    isHost() {
      return this.user.role === "facilitator"
    },
    currentMode() {
      return "play"
    },
    missionType() {
      return this.currentMission.behavior
    },
    useTrivia() {
      var c = this.currentMission.behavior
      if (
        c == "Number" ||
        c == "Multiple Choice" ||
        c == "Text" ||
        c == "Freeform" ||
        c == "Team Name"
      ) {
        return true
      } else {
        return false
      }
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    gameStatus() {
      return this.$store.getters.gameStatus
    },
    teams() {
      return this.$store.getters.chats
    },
    team() {
      if (this.isHost) {
        return {
          ...this.teams[this.currentGlobalTeam],
          id: this.currentGlobalTeam
        }
      } else {
        return { ...this.teams[this.user.teamID], id: this.user.teamID }
      }
    }
  }
}
</script>

<style lang="scss">
.pre-play-mode-wrap {
  .mission-result-transition-enter-active,
  .mission-result-transition-leave-active {
    transition: all ease 0.3s;
    opacity: 1;
  }
  .mission-result-transition-enter-active {
    transition-delay: 0.3s;
  }
  .mission-result-transition-enter,
  .mission-result-transition-leave-to {
    opacity: 0;
  }
  .canvas-tools-slide-transition-enter-active,
  .canvas-tools-slide-transition-leave-active {
    transition: all ease 0.35s;
    transform: translateX(0%);
  }
  .canvas-tools-slide-transition-enter,
  .canvas-tools-slide-transition-leave-to {
    transform: translateX(-105%);
  }
  .pen-size-container {
    max-width: 170px;
    margin: auto;
    padding-top: 3px;
    display: flex;
    justify-content: space-between;
  }
  .draw-tool-btn {
    margin: auto;
    font-weight: bold;
    color: $color-white;
    padding-top: 15px;
    &:not(.disabled):not(.active) {
      cursor: pointer;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }
    &.active {
      pointer-events: none;
      color: #efe73c;
    }
  }
  .pen-size {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 3px;
    }
    display: flex;
    .pen-size-svg {
      align-self: center;
      margin: auto;
    }
    .outline-circle {
      opacity: 0;
      stroke: $primary_accent_color;
    }
    &.active {
      .outline-circle {
        opacity: 1;
      }
    }
  }
  .color-picker {
    width: 100%;
    max-width: 170px;
    box-shadow: none !important;
    align-self: center;
    margin: auto;
  }
  .select-color-text {
    color: $primary_accent_color;
  }
  .clear-board-btn,
  .clear-all-btn {
    line-height: 1;
    color: $primary_accent_color !important;
    font-size: 10px;
    min-width: 60px !important;
    padding: 0 8px !important;
    height: 28px !important;
  }
  .canvas-tools-rtb-card {
    position: relative;
    height: 100%;
    max-height: 240px;
    padding: 0 !important;
    background-color: $primary_accent_color !important;
  }
  .play-mode {
    width: 40%;
    max-width: 500px;
    position: relative;
    z-index: 2;
  }
  .canvas-rtb-card {
    position: relative;
    z-index: 2;
    max-height: 300px;
    .disabled {
      pointer-events: none;
    }
  }
  .play-asset {
    .asset {
      @extend .rtb-border, .rtb-border-radius, .rtb-box-shadow;
    }
    position: relative;
    max-height: 240px;
  }
  .asset-column {
    width: 20%;
    max-width: 300px;
    position: relative;
  }
  .draw-tools-left-col {
    height: 100%;
    min-width: 50px;
    background-color: $primary_accent_color;
    padding-right: 5px;
    justify-content: space-between;
  }
  .play-mode {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
