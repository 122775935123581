var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      ref: "expo",
      staticClass: "pre-game",
      attrs: { "fill-height": "", "pa-0": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { attrs: { "d-flex": "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "Header",
                        {
                          attrs: {
                            headline: _vm.$theme.getMeta("hideExpoText")
                              ? undefined
                              : "EXPO"
                          }
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "inner-header-row",
                              attrs: { row: "" }
                            },
                            [
                              _c("v-flex", {
                                staticClass: "page-headline",
                                attrs: {
                                  xs6: "",
                                  "d-flex": "",
                                  "align-center": ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "potato",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-layout",
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass:
                                                  "remote-reception-text",
                                                attrs: {
                                                  shrink: "",
                                                  "d-flex": "",
                                                  "align-center": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.expoText) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs6: "", "d-flex": "", "pr-2": "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", "justify-end": "" } },
                                    [
                                      _vm.isHost
                                        ? _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                shrink: "",
                                                "d-flex": "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _vm.isHost
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "time-link",
                                                      on: {
                                                        click:
                                                          _vm.toggleDeveloperMode
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " DEVELOPER MODE (" +
                                                          _vm._s(
                                                            _vm.developer
                                                              ? "ON"
                                                              : "OFF"
                                                          ) +
                                                          ") "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !!_vm.user.super
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "time-link",
                                                      on: {
                                                        click: _vm.runUserSpread
                                                      }
                                                    },
                                                    [_vm._v(" USER ASSIGN ")]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "time-link",
                                                  on: { click: _vm.editClient }
                                                },
                                                [_vm._v(" EDIT EVENT ")]
                                              ),
                                              _vm.isSuper
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "time-link",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          if (!$event.altKey) {
                                                            return null
                                                          }
                                                          return _vm.resetTimes(
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " RESET 5 MINUTES "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e(),
                                      !!_vm.user
                                        ? [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass:
                                                  "logged-in-as-text",
                                                attrs: {
                                                  shrink: "",
                                                  "pr-1": "",
                                                  "d-flex": "",
                                                  "align-center": ""
                                                }
                                              },
                                              [_vm._v(" Logged in as: ")]
                                            ),
                                            _vm._m(0)
                                          ]
                                        : _vm._e(),
                                      _vm._m(1)
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "live-chat-row",
                      attrs: { shrink: "", "d-flex": "" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass:
                                "reception-client-logo-container-wrap",
                              attrs: { xs4: "", "d-flex": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass:
                                    "reception-client-logo-container video-container",
                                  attrs: { "d-flex": "" }
                                },
                                [
                                  _vm.playAnnouncement
                                    ? _c("video-play-pause-btn", {
                                        staticClass: "video-container__btn",
                                        attrs: {
                                          state: _vm.isVideoPaused
                                            ? "play"
                                            : "pause"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.isVideoPaused
                                              ? _vm.playVideo({ rest: false })
                                              : _vm.pauseVideo()
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "video",
                                    _vm._g(
                                      {
                                        ref: "video",
                                        staticClass: "video-an video-normal",
                                        class: {
                                          flipped:
                                            _vm.announcementClientURL_flipped
                                        },
                                        attrs: {
                                          autoplay: "",
                                          src: _vm.announcementClientURL
                                        },
                                        on: {
                                          ended: _vm.onVideoEnded,
                                          loadeddata: _vm.onLoadVideo
                                        }
                                      },
                                      _vm.videoListeners
                                    )
                                  ),
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        name: "fade-in-transition-yes",
                                        mode: "out-in"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.playAnnouncement,
                                              expression: "!playAnnouncement"
                                            }
                                          ],
                                          staticClass: "reception-client-image",
                                          style: {
                                            "background-image":
                                              _vm.announcementImageStyle
                                          }
                                        },
                                        [
                                          !_vm.client && !_vm.client.imageUrl
                                            ? [_vm._v(" NO LOGO ")]
                                            : _vm._e(),
                                          _vm.isHost || _vm.isSpeaker
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn-client-intro btn-client-intro--record",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.recordClientAnnouncement(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: { name: "record" }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm.announcementClientURL &&
                                              !_vm.isHost
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn-client-intro",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.playVideo(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: { name: "play" }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            [
                              _c("LiveChat", {
                                attrs: {
                                  flashGames: _vm.flashCardGames,
                                  selectedGameID: _vm.selectedGameID
                                },
                                on: {
                                  onDisconnect: _vm.onDisconnect,
                                  onGameClick: _vm.onGameCardSelectEvent,
                                  onPlayerInvite: _vm.onInvite,
                                  onFlashGameSelect: _vm.onFlashGameSelect
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { "d-flex": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          !!_vm.clientID
                            ? _c("SideBar", {
                                ref: "side_bar",
                                staticClass:
                                  "reception-side-bar xs4 grow d-flex",
                                attrs: {
                                  isGamePlayedByUser: _vm.isGamePlayedByUser,
                                  selectedUserID: _vm.selectedUserID,
                                  selectedGameID: _vm.actualSelectedGameID,
                                  games: _vm.sortedGamesArray,
                                  tournament: _vm.tournament
                                },
                                on: {
                                  onUserSelect: _vm.onUserSelect,
                                  addTime: _vm.addTime,
                                  removeTime: _vm.removeTime,
                                  onGameCardSelectEvent:
                                    _vm.onGameCardSelectEvent
                                }
                              })
                            : _vm._e(),
                          _c(
                            "section",
                            { staticClass: "game-info__wrapper" },
                            [
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    name: "notification-transition",
                                    mode: "out-in"
                                  }
                                },
                                [
                                  !!_vm.notification
                                    ? _c(
                                        "article",
                                        {
                                          key: _vm.notificationKey,
                                          staticClass: "notification"
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass: "notification__text",
                                              attrs: { "align-center": "" }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { staticClass: "text" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.notification) +
                                                      " "
                                                  ),
                                                  _vm.notification ==
                                                  _vm.notifications.flashgame
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "countdown"
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("div", [
                                                            _vm._v("3"),
                                                            _c("br"),
                                                            _vm._v("2"),
                                                            _c("br"),
                                                            _vm._v("1")
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              !!_vm.client.returnUrl
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "return-url flex shrink fill-height",
                                                      class: _vm.returnUrlLogo,
                                                      attrs: {
                                                        href:
                                                          _vm.client.returnUrl,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          attrs: {
                                                            "fill-height": "",
                                                            "align-center": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.returnUrlText
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              !!(
                                _vm.tournament &&
                                _vm.showTournamentStanding &&
                                _vm.activeRound
                              )
                                ? _c("TournamentStandings", {
                                    attrs: {
                                      tournament: _vm.tournament,
                                      roundList: _vm.roundList,
                                      activeRound: _vm.activeRound
                                    },
                                    on: {
                                      onSelect: _vm.onUserSelect,
                                      onGameSelect: _vm.onGameCardSelectEvent
                                    }
                                  })
                                : !!_vm.selectedGame
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass: "game-info-container",
                                      attrs: { "d-flex": "" }
                                    },
                                    [
                                      _vm.isStandard(_vm.selectedGame)
                                        ? _c("GameInfo", {
                                            key:
                                              "game-info-" +
                                              _vm.selectedGame.id +
                                              "-" +
                                              _vm.selectedGame.startTimestamp,
                                            attrs: {
                                              selectedUserID:
                                                _vm.selectedUserID,
                                              isTournamentWinner:
                                                _vm.isTournamentWinner,
                                              game: _vm.selectedGame,
                                              announcementVideo:
                                                _vm.announcementGameURL,
                                              isPlayedByUser: _vm.isGamePlayedByUser(
                                                _vm.selectedGame
                                              )
                                            },
                                            on: {
                                              onJoinGame: _vm.onJoinGame,
                                              onLeaveGame: _vm.onLeaveGame,
                                              onEnterGame: _vm.onEnterGame,
                                              onBookGame: _vm.onBookGame,
                                              onSelect: _vm.onUserSelect,
                                              onMessage: _vm.onMessage,
                                              onGameIntro:
                                                _vm.recordGameAnnouncement
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.isGreenRoom(_vm.selectedGame)
                                        ? _c("GameGreenRoom", {
                                            key:
                                              "game-info-" +
                                              _vm.selectedGame.id +
                                              "-" +
                                              _vm.selectedGame.startTimestamp,
                                            attrs: {
                                              selectedUserID:
                                                _vm.selectedUserID,
                                              isTournamentWinner:
                                                _vm.isTournamentWinner,
                                              game: _vm.selectedGame,
                                              announcementVideo:
                                                _vm.announcementGameURL,
                                              isPlayedByUser: _vm.isGamePlayedByUser(
                                                _vm.selectedGame
                                              )
                                            },
                                            on: {
                                              onJoinGame: _vm.onJoinGame,
                                              onLeaveGame: _vm.onLeaveGame,
                                              onEnterGame: _vm.onEnterGame,
                                              onBookGame: _vm.onBookGame,
                                              onSelect: _vm.onUserSelect,
                                              onMessage: _vm.onMessage,
                                              onGameIntro:
                                                _vm.recordGameAnnouncement
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.isBroadcast(_vm.selectedGame)
                                        ? _c("GameBroadcast", {
                                            key:
                                              "game-info-" +
                                              _vm.selectedGame.id +
                                              "-" +
                                              _vm.selectedGame.startTimestamp,
                                            attrs: {
                                              selectedUserID:
                                                _vm.selectedUserID,
                                              game: _vm.selectedGame,
                                              announcementVideo:
                                                _vm.announcementGameURL,
                                              isPlayedByUser: _vm.isGamePlayedByUser(
                                                _vm.selectedGame
                                              )
                                            },
                                            on: {
                                              onJoinGame: _vm.onJoinGame,
                                              onLeaveGame: _vm.onLeaveGame,
                                              onEnterGame: _vm.onEnterGame,
                                              onBookGame: _vm.onBookGame,
                                              onSelect: _vm.onUserSelect,
                                              onMessage: _vm.onMessage,
                                              onGameIntro:
                                                _vm.recordGameAnnouncement
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.isVideo(_vm.selectedGame)
                                        ? _c("GameYouTube", {
                                            attrs: {
                                              game: _vm.selectedGame,
                                              "can-play":
                                                _vm.noAnnouncement &&
                                                _vm.isExpoTourCompleted
                                            }
                                          })
                                        : _vm.isFlashCards(_vm.selectedGame)
                                        ? _c("GameFlashCards", {
                                            key:
                                              "flash-card-game-" +
                                              _vm.selectedGame.id,
                                            attrs: {
                                              selectedUserID:
                                                _vm.selectedUserID,
                                              game: _vm.selectedGame,
                                              announcementVideo:
                                                _vm.announcementGameURL
                                            },
                                            on: {
                                              onJoinGame: _vm.onJoinGame,
                                              onLeaveGame: _vm.onLeaveGame,
                                              onEnterGame: _vm.onEnterGame,
                                              onBookGame: _vm.onBookGame,
                                              onSelect: _vm.onUserSelect,
                                              onMessage: _vm.onGameInfoMessage,
                                              onGameIntro:
                                                _vm.recordGameAnnouncement
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : !!_vm.gamesArray && !!_vm.gamesArray.length
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass: "loading-text-game-section",
                                      attrs: {
                                        "d-flex": "",
                                        "align-center": ""
                                      }
                                    },
                                    [_vm._v(" LOADING... ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "overlay",
                  style: {
                    visibility: _vm.selectedUserID ? "visible" : "hidden"
                  },
                  on: {
                    mousemove: function($event) {
                      if ($event.target !== $event.currentTarget) {
                        return null
                      }
                      return _vm.onMouseLeave($event)
                    }
                  }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "popup" } },
                    [
                      !!_vm.selectedUserID
                        ? _c("Profile", {
                            key: "user-profile-" + _vm.selectedUserID,
                            staticClass: "profile-popup",
                            class: _vm.popupClass,
                            style: { left: _vm.x + "px", top: _vm.y + "px" },
                            attrs: {
                              userID: _vm.selectedUserID,
                              clientID: _vm.clientID
                            },
                            on: {
                              onInvite: _vm.onInvite,
                              onJoin: _vm.onJoin,
                              onGameClick: _vm.onGameCardSelectEvent
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Popup",
        {
          attrs: {
            header: _vm.recordAnnouncementHeader,
            isOpen: _vm.showRecordingDialog
          },
          on: {
            onClose: function($event) {
              _vm.showRecordingDialog = false
            }
          }
        },
        [
          _c("RecordVideo", {
            attrs: {
              clientID: _vm.clientID,
              gameID: _vm.recordAnnouncementGameID,
              videoTarget: _vm.recordAnnouncementTarget
            },
            on: { onPosted: _vm.clientAnnouncementPosted }
          })
        ],
        1
      ),
      _vm.isHost ? _c("UserEditor") : _vm._e(),
      !_vm.isExpoTourCompleted && !_vm.client.noExpoTour
        ? _c("ExpoTour", {
            attrs: { expoHTML: _vm.expoHTML, isHost: _vm.isHost },
            on: { completeExpoTour: _vm.onExpoTourCompleted }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "v-flex",
      {
        staticClass: "logged-in-user-name",
        attrs: { shrink: "", "d-flex": "", "align-center": "" }
      },
      [
        _c("UserName", {
          attrs: { firstname: _vm.user.firstname, lastname: _vm.user.lastname }
        })
      ],
      1
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "v-flex",
      { attrs: { shrink: "", "d-flex": "", "align-center": "" } },
      [
        _c(
          "div",
          {
            staticClass: "logout-btn-wrap",
            on: {
              click: function($event) {
                return _vm.$router.push("/logout")
              }
            }
          },
          [_c("div", { staticClass: "logout-btn" }, [_vm._v("LOG OUT")])]
        ),
        _c("FullscreenToggle", {
          staticClass: "ml-2 self-center",
          staticStyle: { position: "relative", "z-index": "3" }
        })
      ],
      1
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }