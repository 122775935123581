var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tournament-sidebar-header" },
    [
      _c(
        "v-layout",
        {
          staticClass: "tournament-sidebar-header__rounds",
          attrs: { row: "", wrap: "" }
        },
        _vm._l(_vm.rounds, function(round, index) {
          return _c(
            "v-flex",
            {
              key: "tournament-header-round-" + round,
              staticClass: "tournament-sidebar-header__round",
              class: { "--active": _vm.isActiveRound(index) },
              attrs: { shrink: "", "d-flex": "", "align-center": "" }
            },
            [
              _vm.isActiveRound(index)
                ? _c("svg-icon", {
                    attrs: { size: "small", name: "caret-right" }
                  })
                : _vm._e(),
              round.label
                ? [_vm._v(" " + _vm._s(round.label) + " ")]
                : [_vm._v(" Round " + _vm._s(index + 1) + " ")]
            ],
            2
          )
        }),
        1
      ),
      _c("div", { staticClass: "sidebar-divider" }),
      _c(
        "v-btn",
        {
          staticClass: "rtb-background-secondary rtb-color-white",
          on: { click: _vm.showTournament }
        },
        [
          _vm._v(" Tournament "),
          _c("svg-icon", {
            staticClass: "mx-2",
            attrs: { name: "trophy", size: "small" }
          }),
          _vm._v(" Standings ")
        ],
        1
      ),
      _c("div", { staticClass: "sidebar-divider" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }