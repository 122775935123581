var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "room-info", attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "" } },
        _vm._l(_vm.slots, function(user, idx) {
          return _c(
            "v-flex",
            {
              key: "slot-" + idx,
              staticClass: "slot-wrap",
              attrs: { "d-flex": "", "align-center": "" }
            },
            [
              !!user && !!user.image
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "button-reset",
                                      attrs: { icon: "" }
                                    },
                                    on
                                  ),
                                  [
                                    _c("RoomInfoIcon", {
                                      staticClass: "slot defined",
                                      attrs: { imageUrl: user.image }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(user.firstname) + " " + _vm._s(user.lastname)
                        )
                      ])
                    ]
                  )
                : _c("RoomInfoIcon", { staticClass: "slot" })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }