var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { on: { mouseenter: _vm.onMouseOver } },
    [
      _c("RatioContainer", { staticClass: "pre-game-player" }, [
        _c(
          "div",
          { staticClass: "video-container outline" },
          [
            _c(
              "v-flex",
              {
                staticClass: "video-stream-wrap",
                attrs: { "d-flex": "", "justify-center": "" }
              },
              [
                _c(
                  "v-flex",
                  {
                    staticClass: "video-steam-placeholder-wrap",
                    attrs: { "d-flex": "", "justify-center": "" }
                  },
                  [_c("img", { attrs: { src: _vm.imageURL } })]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }