<template>
  <v-flex @mouseenter="onMouseOver">
    <RatioContainer class="pre-game-player">
      <div class="video-container outline">
        <v-flex d-flex justify-center class="video-stream-wrap">
          <v-flex d-flex justify-center class="video-steam-placeholder-wrap">
            <img :src="imageURL" />
          </v-flex>
        </v-flex>
      </div>
    </RatioContainer>
  </v-flex>
</template>

<script>
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier.vue"
import { DEFAULT_PROFILE_IMAGE_BIG } from "@/config"

export default {
  name: "PreGamePlayer",
  components: {
    RatioContainer
  },
  props: {
    imageURL: {
      type: String,
      default: DEFAULT_PROFILE_IMAGE_BIG
    },
    userID: String
  },
  methods: {
    onMouseOver($event) {
      if (!this.userID) return
      this.$emit("onMouseOver", { $event, userID: this.userID })
    }
  }
}
</script>

<style lang="scss">
.pre-game-player {
  user-select: none;

  .video-stream-wrap {
    position: relative;
    max-height: 100%;
    width: 100%;
  }

  .video-steam-placeholder-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    // placeholder text
    & > div {
      align-self: center;
      font-size: 14px;
      color: $color-disabled;
    }
    // placeholder image
    & > img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .player-name {
    position: absolute;
    width: 100%;
    height: 22px;
    padding-top: 3px;
    line-height: 22px;
    font-size: 11px;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }

  .outline {
    border: 2px solid #fff;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }
}
</style>
