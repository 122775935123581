var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      staticClass: "game-card",
      class: { selected: _vm.isSelected },
      attrs: { "d-flex": "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            {
              staticClass: "game-header",
              attrs: { shrink: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.onViewGame($event)
                }
              }
            },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "game-title-wrap", attrs: { "d-flex": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "game-header-top" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { column: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        "d-flex": "",
                                        "align-center": ""
                                      },
                                      on: {
                                        dblclick: function($event) {
                                          return _vm.toggleDectivate()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _vm.game.gameType === "Broadcast"
                                            ? _c(
                                                "v-flex",
                                                {
                                                  staticClass:
                                                    "game-card__broadcast-indication",
                                                  attrs: {
                                                    "d-flex": "",
                                                    shrink: "",
                                                    "align-center": "",
                                                    "mr-1": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "game-card__broadcast-indication__icon"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " fiber_manual_record "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "game-card__broadcast-indication__text"
                                                    },
                                                    [_vm._v("LIVE")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-flex",
                                            { attrs: { "d-flex": "" } },
                                            [
                                              _c("h2", [
                                                _vm._v(
                                                  _vm._s(_vm.deactivated) +
                                                    " " +
                                                    _vm._s(_vm.gameName)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { staticClass: "game-details" },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          !!_vm.startTime && _vm.isStandard
                                            ? _c(
                                                "v-flex",
                                                [
                                                  _vm.time > 0
                                                    ? _c("span", [
                                                        _vm._v(" Starts at ")
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(" Started at ")
                                                      ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "actual-start-time"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.startTime
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _vm.game.duration
                                                    ? [
                                                        _c("span", [
                                                          _vm._v(" | ")
                                                        ]),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "actual-duration-time"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formattedDuration
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            : _c(
                                                "v-flex",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$emit(
                                                        "onClickHostIcon",
                                                        _vm.game.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Viewable anytime ")]
                                              ),
                                          _vm.isStandard
                                            ? _c(
                                                "v-flex",
                                                {
                                                  staticClass: "host-name",
                                                  class: {
                                                    "actual-host": !!_vm.actualHost
                                                  },
                                                  attrs: { "text-xs-right": "" }
                                                },
                                                [
                                                  !!_vm.host
                                                    ? _c("div", [
                                                        _vm._v(" Host: "),
                                                        _c(
                                                          "strong",
                                                          [
                                                            _c("UserName", {
                                                              attrs: {
                                                                firstname:
                                                                  _vm.host
                                                                    .firstname
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ])
                                                    : _c("div", [
                                                        _vm._v("Hosted By: "),
                                                        _c("strong", [
                                                          _vm._v("Pending")
                                                        ])
                                                      ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isStandard
                    ? _c(
                        "v-flex",
                        {
                          staticClass: "host-icon-wrap",
                          attrs: { shrink: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("onClickHostIcon", _vm.game.id)
                            }
                          }
                        },
                        [
                          _vm.isDefaultHostImage
                            ? _c("div", { staticClass: "host-icon disabled" }, [
                                _c("img", {
                                  staticClass: "pending-host-image",
                                  attrs: { src: _vm.profileIconUrl },
                                  on: { click: _vm.editGame }
                                })
                              ])
                            : _c("div", { staticClass: "host-icon" }, [
                                _c("img", {
                                  staticClass: "host-image",
                                  attrs: { src: _vm.host.image },
                                  on: { click: _vm.editGame }
                                })
                              ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "game-card-body",
              style: _vm.background(),
              attrs: { "d-flex": "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.onViewGame($event)
                }
              }
            },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "game-card-body-row",
                      attrs: { shrink: "" }
                    },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "game-card-players" },
                        [
                          _vm.isStandard
                            ? _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        shrink: "",
                                        "d-flex": "",
                                        "align-center": "",
                                        shink: ""
                                      }
                                    },
                                    [_vm._v(" Players ")]
                                  ),
                                  !!_vm.game.players
                                    ? _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "align-center": "",
                                            "d-flex": "",
                                            shrink: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "players-count" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "player-count-number"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.nOfPlayersOnline)
                                                  )
                                                ]
                                              ),
                                              _vm._v(" of "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "player-count-number"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.game.players)
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "align-center": "",
                                            "d-flex": "",
                                            shrink: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "players-count" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "player-count-number"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.nOfPlayersOnline)
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                ],
                                1
                              )
                            : _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        hrink: "",
                                        "d-flex": "",
                                        "align-center": "",
                                        shink: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.editGame()
                                        }
                                      }
                                    },
                                    [_vm._v(" Everyone ")]
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      ),
                      !!_vm.game.description
                        ? [_vm._v(" " + _vm._s(_vm.game.description) + " ")]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("GameCardButton", {
            staticClass: "game-card-footer",
            attrs: {
              game: _vm.game,
              time: _vm.time,
              gameStartTime: _vm.gameStartTime,
              gameEndTime: _vm.gameEndTime,
              isGameBooked: _vm.isGameBooked,
              isSelected: _vm.isSelected,
              isPlayedByUser: _vm.isPlayedByUser,
              loading: _vm.loading
            },
            on: {
              onClick: _vm.onViewGame,
              addTimeToGame: _vm.addTimeToGame,
              removeTimeToGame: _vm.removeTimeToGame
            }
          }),
          !!_vm.game && _vm.editGameDialog
            ? _c(
                "v-dialog",
                {
                  staticClass: "game-settings-wrap",
                  attrs: { width: "60%" },
                  model: {
                    value: _vm.editGameDialog,
                    callback: function($$v) {
                      _vm.editGameDialog = $$v
                    },
                    expression: "editGameDialog"
                  }
                },
                [
                  _c("GameSettings", {
                    attrs: { game: _vm.game },
                    on: {
                      closeGameSettings: function($event) {
                        return _vm.closeEdit()
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }