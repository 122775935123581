<template>
  <span>
    <template v-if="object.hours.number > 9">
      {{ object.hours.string }}h
    </template>
    <template v-else-if="object.hours.number > 0">
      {{ object.hours.string }}:{{ object.minutes.string }}
    </template>
    <template v-else>
      {{ object.minutes.string }}:{{ object.seconds.string }}
    </template>
  </span>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "PreGameCountdown",
  props: {
    endTime: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters({ globalTime: "time" }),
    difference() {
      return this.endTime - this.globalTime
    },
    time() {
      return this.difference > 0 ? this.difference : 0
    },
    object() {
      let hours = this.time / (1000 * 60 * 60)
      let absoluteHours = Math.floor(hours)
      let h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours
      // Get remainder from hours and convert to minutes
      let minutes = (hours - absoluteHours) * 60
      let absoluteMinutes = Math.floor(minutes)
      let m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes
      // Get remainder from minutes and convert to seconds
      let seconds = (minutes - absoluteMinutes) * 60
      let absoluteSeconds = Math.floor(seconds)
      let s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds
      return {
        seconds: {
          absoluteSeconds: s,
          string: s
        },
        minutes: {
          number: absoluteMinutes,
          string: m
        },
        hours: {
          number: absoluteHours,
          string: h
        }
      }
    }
  }
}
</script>
