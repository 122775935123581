var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "tournament-tab-text" },
    [
      _vm.round.num < _vm.activeRound.num
        ? _c("svg-icon", {
            staticClass: "checkbox",
            attrs: { name: "checkbox" }
          })
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.label) + " "),
      _vm.round.num > _vm.activeRound.num
        ? _c("div", { staticClass: "starts-at" }, [
            _vm._v(
              " starts @ " +
                _vm._s(
                  _vm._f("moment")(new Date(_vm.round.round.timestamp), "LT")
                ) +
                " "
            )
          ])
        : _vm._e(),
      _vm.round && _vm.activeRound && _vm.round.num > _vm.activeRound.num
        ? _c("add-to-calendar", {
            staticClass: "calendar",
            attrs: {
              title:
                "Go Remote Tournament - Round " +
                (_vm.round ? _vm.round.num + 1 : null),
              location: "ANYWHERE",
              start: new Date(_vm.round.round.timestamp),
              end: _vm.end,
              details:
                "RTB Tournament - Round " +
                (_vm.round ? _vm.round.num + 1 : null) +
                " - " +
                _vm.name
            },
            inlineTemplate: {
              render: function() {
                var _vm = this
                var _h = _vm.$createElement
                var _c = _vm._self._c || _h
                return _c(
                  "google-calendar",
                  { attrs: { id: "google-calendar" } },
                  [_c("svg-icon", { attrs: { name: "calendar" } })],
                  1
                )
              },
              staticRenderFns: []
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "vertical-divider" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }