var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "pre-game-user-list", attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !!_vm.truncatedUsers.length,
                  expression: "!!truncatedUsers.length"
                }
              ],
              ref: "scrollable",
              staticClass: "scrollable",
              on: {
                "&scroll": function($event) {
                  return _vm.onScroll($event)
                }
              }
            },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                _vm._l(_vm.truncatedUsers, function(player) {
                  return _c(
                    "v-flex",
                    {
                      key: "all-users-user-" + player.id,
                      staticClass: "all-users-user",
                      class: {
                        online: player.status === "online",
                        selected: player.id === _vm.selectedUserID
                      },
                      attrs: { shrink: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c("PreGamePlayer", {
                            class: [
                              "user",
                              "user--role--" + player.role,
                              {
                                online: player.status === "online",
                                onboarding:
                                  player.onboarding ||
                                  player.expotour === false,
                                "user-select": _vm.isHost,
                                "same-game":
                                  !!player.gameID &&
                                  player.gameID === _vm.user.gameID
                              }
                            ],
                            attrs: {
                              imageURL: player.image,
                              userID: player.id
                            },
                            on: { onMouseOver: _vm.onMouseOver },
                            nativeOn: {
                              mouseleave: function($event) {
                                return _vm.onMouseLeave($event)
                              },
                              click: [
                                function($event) {
                                  if (!$event.altKey) {
                                    return null
                                  }
                                  return _vm.edit(player)
                                },
                                function($event) {
                                  if (!$event.shiftKey) {
                                    return null
                                  }
                                  return _vm.edit(player)
                                }
                              ]
                            }
                          }),
                          _c(
                            "v-flex",
                            {
                              staticClass: "user-search-user-name",
                              attrs: { "mt-1": "", "text-xs-center": "" }
                            },
                            [
                              _vm._o(
                                _c("UserName", {
                                  attrs: {
                                    firstname: player.firstname,
                                    lastname: player.lastname
                                  }
                                }),
                                0,
                                "all-users-user-" + player.id
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }