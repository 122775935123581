<template>
  <v-flex d-flex class="game-card" :class="{ selected: isSelected }">
    <v-layout column>
      <v-flex class="game-header" @click.stop="onViewGame" shrink>
        <v-layout row>
          <v-flex d-flex class="game-title-wrap">
            <v-layout column>
              <v-flex class="game-header-top">
                <v-layout column>
                  <v-flex d-flex align-center @dblclick="toggleDectivate()">
                    <v-layout>
                      <v-flex
                        d-flex
                        shrink
                        align-center
                        mr-1
                        v-if="game.gameType === 'Broadcast'"
                        class="game-card__broadcast-indication"
                      >
                        <v-icon class="game-card__broadcast-indication__icon">
                          fiber_manual_record
                        </v-icon>
                        <span class="game-card__broadcast-indication__text"
                          >LIVE</span
                        >
                      </v-flex>
                      <v-flex d-flex>
                        <h2>{{ deactivated }} {{ gameName }}</h2>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex class="game-details">
                    <v-layout>
                      <v-flex v-if="!!startTime && isStandard">
                        <span v-if="time > 0"> Starts at </span>
                        <span v-else> Started at </span>
                        <div class="actual-start-time">
                          {{ startTime }}
                        </div>
                        <template v-if="game.duration">
                          <span> | </span>
                          <span class="actual-duration-time">{{
                            formattedDuration
                          }}</span>
                        </template>
                      </v-flex>
                      <v-flex @click="$emit('onClickHostIcon', game.id)" v-else>
                        Viewable anytime
                      </v-flex>
                      <v-flex
                        v-if="isStandard"
                        text-xs-right
                        class="host-name"
                        :class="{ 'actual-host': !!actualHost }"
                      >
                        <div v-if="!!host">
                          Host:
                          <strong>
                            <UserName :firstname="host.firstname" />
                          </strong>
                        </div>
                        <div v-else>Hosted By: <strong>Pending</strong></div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            v-if="isStandard"
            shrink
            class="host-icon-wrap"
            @click="$emit('onClickHostIcon', game.id)"
          >
            <div class="host-icon disabled" v-if="isDefaultHostImage">
              <img
                @click="editGame"
                class="pending-host-image"
                :src="profileIconUrl"
              />
            </div>
            <div class="host-icon" v-else>
              <img class="host-image" :src="host.image" @click="editGame" />
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        d-flex
        @click.stop="onViewGame"
        class="game-card-body"
        :style="background()"
      >
        <v-layout column>
          <v-flex shrink class="game-card-body-row">
            <v-flex class="game-card-players">
              <v-layout v-if="isStandard" row>
                <v-flex shrink d-flex align-center shink> Players </v-flex>
                <v-flex align-center d-flex shrink v-if="!!game.players">
                  <span class="players-count"
                    ><span class="player-count-number">{{
                      nOfPlayersOnline
                    }}</span>
                    of
                    <span class="player-count-number">{{
                      game.players
                    }}</span></span
                  >
                </v-flex>
                <v-flex align-center d-flex shrink v-else>
                  <span class="players-count"
                    ><span class="player-count-number">{{
                      nOfPlayersOnline
                    }}</span></span
                  >
                </v-flex>
              </v-layout>
              <v-layout v-else>
                <v-flex @click="editGame()" hrink d-flex align-center shink>
                  Everyone
                </v-flex>
              </v-layout>
            </v-flex>
            <template v-if="!!game.description">
              {{ game.description }}
            </template>
          </v-flex>
        </v-layout>
      </v-flex>

      <GameCardButton
        class="game-card-footer"
        @onClick="onViewGame"
        @addTimeToGame="addTimeToGame"
        @removeTimeToGame="removeTimeToGame"
        :game="game"
        :time="time"
        :gameStartTime="gameStartTime"
        :gameEndTime="gameEndTime"
        :isGameBooked="isGameBooked"
        :isSelected="isSelected"
        :isPlayedByUser="isPlayedByUser"
        :loading="loading"
      />
      <v-dialog
        v-model="editGameDialog"
        v-if="!!game && editGameDialog"
        width="60%"
        class="game-settings-wrap"
      >
        <GameSettings :game="game" @closeGameSettings="closeEdit()" />
      </v-dialog>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import moment from "moment"
import { Role, Time } from "../helpers"
import { fetchUser } from "../services/user.service"
import UserName from "@/components/GroupTeams/Common/User/UserName"
import GameCardButton from "@/components/GameCardButton"
const GameSettings = () => import("@/components/Game/GameSettings")

const SELECT_RETRY_TIMEOUT = 10000 // 10 sec
const PROFILE_ICON_URL = require("@/assets/game-card-profile-icon.png")

export default {
  name: "GameCard",
  components: {
    UserName,
    GameCardButton,
    GameSettings
  },
  props: {
    game: {
      type: Object,
      required: true
    },
    selected: String,
    isPlayedByUser: Boolean
  },
  data() {
    return {
      profileIconUrl: PROFILE_ICON_URL,
      assignedHost: null,
      time: null,
      startTime: null,
      editGameDialog: false,
      loading: false,
      loadingTimeout: null,
      actualHost: null
    }
  },
  async created() {
    if (this.game.hostUserID) {
      this.assignedHost = await fetchUser({ userID: this.game.hostUserID })
    }
    if (this.gameStartTime) {
      const { time, startTime } = Time.getCountdownTimes(
        this.gameStartTime,
        this.$store.getters.time
      )
      this.time = time
      this.startTime = startTime
    }
    this.actualHost = this.usersOnline.find(({ role }) => role === Role.Host)
  },
  beforeDestroy() {
    clearTimeout(this.loadingTimeout)
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    toggleDectivate() {
      if (!this.isHost) return
      let obj = {}
      obj.theKey = this.game.id
      obj.deactivate = !this.game.deactivate
      this.updateGameAny(obj)
    },
    closeEdit() {
      this.editGameDialog = false
    },
    editGame() {
      if (!this.isHost) return
      console.log("EDITING GAMESETTINGS")
      this.editGameDialog = true
    },
    addTimeToGame() {
      this.$emit("addTimeToGame", this.game.id)
    },
    removeTimeToGame() {
      this.$emit("removeTimeToGame", this.game.id)
    },
    onUserSelect({ userID }) {
      this.$emit("onUserSelect", { userID })
    },
    background() {
      return { "background-image": "url(" + this.game.image + ")" }
    },
    onViewGame() {
      if (this.isSelected === false) {
        this.$emit("onSelect", { gameID: this.game.id })

        if (this.loading === false) {
          this.loading = true

          // if `isSelected` do not changes in SELECT_RETRY_TIMEOUT – reset loading state
          // to give user an ability to try again
          this.loadingTimeout = setTimeout(() => {
            this.loading = false
          }, SELECT_RETRY_TIMEOUT)
        }
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isHost"]),
    ...mapGetters("allusers", ["onlineUsersGroupedByGameID"]),
    isGameBooked() {
      return this.user.gameID === this.game.id
    },
    gameName() {
      return this.game.externalName || this.game.name
    },
    isStandard() {
      return (
        !this.game.gameType ||
        this.game.gameType == "Standard" ||
        this.game.gameType === "Broadcast"
      )
    },
    deactivated() {
      return this.game.deactivate ? "(OFF)" : null
    },
    gameEndTime() {
      return this.game.endTimestamp || 0
    },
    gameStartTime() {
      return this.game.startTimestamp || 0
    },
    isSelected() {
      return this.selected == this.game.id
    },
    isDefaultHostImage() {
      return !(this.host && this.host.image)
    },
    usersOnline() {
      return this.onlineUsersGroupedByGameID[this.game.id] || []
    },
    playersOnline() {
      return this.usersOnline.filter(({ role }) => role === Role.Player)
    },
    nOfPlayersOnline() {
      return this.playersOnline.length
    },
    host() {
      return this.actualHost || this.assignedHost
    },
    formattedDuration() {
      return this.game.duration !== undefined
        ? Math.round(moment.duration(this.game.duration).asMinutes()) + " mins"
        : ""
    }
  },
  watch: {
    isSelected(value) {
      if (value === true) {
        if (this.loadingTimeout !== null) {
          clearTimeout(this.loadingTimeout)
          this.loadingTimeout = null
        }
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.game-card {
  line-height: 1;
  background: $color-white;
  margin: 0 0 10px;

  @keyframes game-card-bounce {
    0% {
      text-shadow: 0px 0px 4px rgba(249, 0, 62, 0.3),
        0px 0px 4px rgba(249, 0, 62, 0.3);
    }
    50% {
      text-shadow: 0px 0px 4px rgba(249, 0, 62, 1),
        0px 0px 4px rgba(249, 0, 62, 1);
    }
    100% {
      text-shadow: 0px 0px 4px rgba(249, 0, 62, 0.3),
        0px 0px 4px rgba(249, 0, 62, 0.3);
    }
  }

  &__broadcast-indication {
    background-color: $color-secondary-dark;
    border-radius: 4px;
    padding: 1px 2px;
    padding-right: 4px;
    overflow: hidden;
    align-self: center;
    &__icon {
      color: $color-wrong !important;
      font-size: 15px;
      animation-name: game-card-bounce;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
    }
    &__text {
      font-size: 10px;
      color: #fff;
    }
  }

  .game-card-body {
    position: relative;
    color: #fff;
    font-size: 14px;
    line-height: 15px;
    height: 65px;
    background-size: cover;
    background-position: center;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($color-black, 0.5);
    }
    .layout {
      position: relative;
    }
  }
  .host-image {
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .game-in-progress-text,
  .get-ready-text {
    font-size: 18px;
    padding: 4px 8px;
    color: $color-correct;
    font-weight: bold;
  }
  .game-in-progress-text {
    color: $primary_accent_color;
  }
  .game-card-btn {
    color: $primary_accent_color;
    background-color: $color-white;
    padding: 0 12px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    text-transform: uppercase;
    transition: color 0.1s ease-out, background-color 0.1s ease-out;
    outline: none;
    &:hover {
      color: $color-white;
      background-color: $primary_accent_color;
    }
    &.disabled {
      pointer-events: none;
      background-color: $color-secondary-dark;
      color: $color-disabled;
      cursor: initial;
    }
  }
  .countdown {
    line-height: 30px;
    flex: none !important;
    width: 110px;
    text-align: center;
    padding: 0 5px;
    font-size: 11px;
    color: $color-white;
    background: $primary_accent_color;
    span {
      font-size: 14px;
      font-weight: bold;
      vertical-align: top;
      margin-left: 2px;
    }
  }
  &.selected {
    .game-card-btn {
      color: $color-white;
      background: $secondary_accent_color;
      &:hover {
        filter: brightness(1.1);
      }
    }
    .countdown {
      color: $color-black;
      background: $color-white;
      span {
        color: $secondary_accent_color;
      }
    }
    .running {
      color: $secondary_accent_color;
    }
  }
  .game-card-user-link {
    text-decoration: underline;
    cursor: pointer;
    margin-right: 2px;
  }
  .host-icon {
    display: flex;
    overflow: hidden;
    width: 60px;
    height: 60px;
    background-color: $color-disabled;
    position: relative;
  }
  .pending-host-image {
    position: absolute;
    display: block;
    width: 90%;
    height: auto;
    bottom: 0;
    left: 5%;
    margin-left: -1px;
  }
  .actual-start-time,
  .game-card-countdown,
  .actual-duration-time {
    display: inline-block;
    font-weight: bold;
  }
  .game-title-wrap {
    max-width: calc(100% - 60px);
  }
  .host-icon-wrap {
    border-radius: 4px;
    background-color: $primary_accent_color;
    overflow: hidden;
  }
  .game-card-body-row {
    margin: 5px 10px 15px;
    min-height: 71px;
  }
  .players-count {
    color: #fff;
    display: inline-block;
    font-size: 12px;
    height: 17px;
    padding: 1px 3px;
  }
  .player-count-number {
    font-size: 12px;
    color: $color-white;
    font-weight: bold;
  }
  .game-card-players {
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .game-header-top {
    padding-right: 7px;
    h2 {
      padding: 4px 0 5px;
      font-size: 18px;
      line-height: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .game-details {
      font-size: 10px;
      .host-name strong {
        font-weight: bold;
        color: $primary_accent_color;
      }
      .actual-host strong {
        color: $color-green;
      }
    }
  }

  .game-header {
    position: relative;
    height: 48px;
    padding: 7px 7px 0 12px;
    background-color: #fff;
    z-index: 1;
  }

  &.booked,
  &.selected {
    .host-icon-wrap {
      background-color: $secondary_accent_color;
    }
  }
}
@media (max-width: 1100px) {
  .game-card .game-card-btn {
    font-size: 12px;
  }
}
</style>
