<template>
  <v-flex d-flex class="pre-game-all-users">
    <v-layout column>
      <v-flex d-flex shrink pa-3 text-xs-center>
        <v-layout column>
          <v-flex>
            <form @submit.prevent="onQueryChange(query)">
              <input
                class="text-input"
                v-model="query"
                @change="onQueryChange"
                type="text"
                placeholder="Search"
                required
              />
            </form>
          </v-flex>
          <v-flex v-if="isHost" class="pre-game-all-users__filters" mt-2 d-flex>
            <v-layout row justify-center>
              <v-flex xs2>
                <v-checkbox
                  v-model="filtersDisabled"
                  dark
                  dense
                  label="ALL"
                  hide-details
                />
              </v-flex>
              <v-flex xs2>
                <v-checkbox
                  v-model="filters.unassigned"
                  dark
                  dense
                  label="UNASSIGNED"
                  hide-details
                />
              </v-flex>
              <v-flex xs2>
                <v-checkbox
                  v-model="filters.assigned"
                  dark
                  dense
                  label="ASSIGNED"
                  hide-details
                />
              </v-flex>
              <v-flex xs2>
                <v-checkbox
                  v-model="filters.player"
                  dark
                  label="PLAYERS"
                  dense
                  hide-details
                />
              </v-flex>
              <v-flex xs2>
                <v-checkbox
                  v-model="filters.host"
                  dark
                  dense
                  label="HOSTS"
                  hide-details
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- <v-flex shrink v-if="isHost && !!user.super">
            <v-btn small color="primary" type="button" @click="fetchFakeUsers"
              >FETCH DEBUG USERS</v-btn
            >
          </v-flex> -->
        </v-layout>
      </v-flex>
      <v-flex shrink>
        <Separator>
          <span class="n-of-players-wrap">
            <span class="n-of-players">{{ filteredUsersCount }}</span>
            <span> {{ separatorText }}</span>
            <template v-if="shouldDisplayTotalUsersCount">
              (<span class="n-of-players">{{ nOfUsers }}</span>
              <span> Total)</span>
            </template>
          </span>
        </Separator>
      </v-flex>
      <v-flex
        mt-2
        v-if="!!sortedUsers && !sortedUsers.length"
        d-flex
        justify-center
        align-center
        text-xs-center
      >
        <h1 class="no-results-text" v-if="!onlineUsersArray.length">
          LOADING...
        </h1>
        <h1 class="no-results-text" v-else>NO RESULTS</h1>
      </v-flex>
      <v-flex d-flex style="overflow: hidden" v-else mt-2>
        <UserList
          ref="list"
          :selectedUserID="selectedUserID"
          :users="sortedUsers"
          @onSelect="onSelect"
        />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import { ACTION_TYPES } from "@/store/UserSearch"

import UserList from "@/components/UserList"
import Separator from "@/components/Separator"
import _ from "lodash"
import { Role, UserFilter } from "@/helpers"

/**
 * @description Use factory function to create a fresh object every time
 */
function getInitialFiltersState() {
  return {
    unassigned: false,
    assigned: false,
    player: false,
    host: false
  }
}

export default {
  name: "UserSearch",
  components: {
    Separator,
    UserList
  },
  props: {
    selectedUserID: String
  },
  data() {
    return {
      query: "",
      debouncedQuery: null,

      /* Host realated properties */
      filters: getInitialFiltersState(),
      filtersDisabled: true
    }
  },
  watch: {
    query(value) {
      this.onQueryChange(value)
    },
    filters: {
      handler(value, previuousValue) {
        this.resetPagination()
        if (value === previuousValue) {
          this.filtersDisabled = false
        }
      },
      deep: true
    },
    filtersDisabled(value) {
      if (value === true) {
        this.filters = getInitialFiltersState()
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "role", "access", "client", "isHost"]),
    ...mapGetters("allusers", ["onlineUsersArray"]),
    anonymousUsers() {
      return this.client ? !!this.client.anonymousUsers : false
    },
    nOfUsers() {
      return this.filteredByAccess.length
    },
    filteredByAccess() {
      return this.onlineUsersArray.filter(({ role }) => {
        if (role === Role.Audit && !this.isHost) return false
        if (!this.anonymousUsers || this.isHost) {
          return true
        } else {
          return role !== Role.Host
        }
      })
    },
    filtered() {
      let result = this.filteredByAccess

      if (this.masterPredicate !== null && this.isHost === true) {
        result = result.filter(this.masterPredicate)
      }

      if (!this.debouncedQuery) return result
      const pattern = new RegExp(this.debouncedQuery, "gi")
      return result.filter(
        ({ firstname, lastname }) =>
          pattern.test(firstname) || pattern.test(lastname)
      )
    },
    filteredUsersCount() {
      return this.filtered.length
    },
    sortedUsers() {
      let array = this.filtered.map(user => user)

      array.sort((a, b) => {
        const aa = a.status || "offline"
        const bb = b.status || "offline"

        if (a.onboarding) {
          return 1
        }

        if (aa > bb) {
          return -1
        } else if (aa < bb) {
          return 1
        } else {
          if (a.gameID === this.user.gameID && b.gameID !== this.user.gameID) {
            return -1
          } else if (
            a.gameID !== this.user.gameID &&
            b.gameID === this.user.gameID
          ) {
            return 1
          } else {
            return 0
          }
        }
      })

      if (this.user && this.user.id && array.length) {
        const index = array.findIndex(({ id }) => id === this.user.id)
        if (index > -1) {
          const first = { ...array[0] }
          const current = { ...array[index] }
          array[0] = current
          array[index] = first
        }
      }

      return array
    },
    /**
     * @description Host related predicates for filters
     */
    filterPredicates() {
      /** @type Array<(arg: any) => boolean> */
      const predicates = []

      if (this.filters.unassigned === true) {
        predicates.push(UserFilter.unassignedPredicate)
      }

      if (this.filters.assigned === true) {
        predicates.push(UserFilter.assignedPredicate)
      }

      if (this.filters.player === true) {
        predicates.push(
          UserFilter.createRolePredicate([Role.Player, Role.Audit])
        )
      }

      if (this.filters.host === true) {
        predicates.push(UserFilter.hostPredicate)
      }

      return predicates
    },
    /**
     * @description Host related predicate, which reduces amount of iterations
     */
    masterPredicate() {
      if (this.filterPredicates.length === 0) {
        return null
      } else {
        return user => {
          for (const predicate of this.filterPredicates) {
            const result = predicate(user)
            if (result === false) return false
          }
          return true
        }
      }
    },
    separatorText() {
      if (this.isHost) {
        return this.filteredUsersCount === 1 ? "User" : "Users"
      } else {
        return this.filteredUsersCount === 1 ? "Player" : "Players"
      }
    },
    shouldDisplayTotalUsersCount() {
      return (
        this.filtersDisabled === false &&
        this.isHost &&
        this.filteredUsersCount !== this.nOfUsers
      )
    }
  },
  methods: {
    ...mapActions("allusers", [ACTION_TYPES.FETCH_DEBUG_USERS]),
    onQueryChange: _.debounce(function (value) {
      this.debouncedQuery = value
      this.resetPagination()
    }, 500),
    onSelect(obj) {
      this.$emit("onSelect", obj)
    },
    fetchFakeUsers() {
      this[ACTION_TYPES.FETCH_DEBUG_USERS]()
    },
    resetPagination() {
      const { list } = this.$refs
      if (list !== undefined) {
        list.resetPagination()
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
.pre-game-all-users {
  direction: ltr;
  .no-game-users {
    padding-bottom: 5px;
  }
  .user-search-user-name {
    font-size: 14px;
    color: $color-disabled;
  }
  .n-of-players {
    font-weight: bold;
  }
  .no-results-text {
    color: $color-tertiary-dark;
  }
  .text-input {
    font-size: 17px;
    padding: 0 12px;
    height: 34px;
    min-width: 200px;
    width: 100%;
    max-width: 80%;
    color: $color-secondary-dark;
    background-color: $color-grey-light2;
    border: none !important;
    &:focus {
      outline: none !important;
      border: solid 1px rgba($color-black, 0.1);
      background-color: $color-grey-light2;
    }
    &::placeholder {
      color: $color-secondary-dark;
    }
  }
  .text-input-button {
    background-color: $primary_accent_color;
    height: 34px;
    padding: 0px 10px;
    display: inline-block;
    vertical-align: middle;
  }
  .n-of-players-wrap {
    color: $color-primary-light;
  }
  &__filters {
    .v-label {
      font-size: 9px;
    }
    .v-input__slot {
      flex-direction: column;
    }
    .v-input--selection-controls {
      justify-content: center;
      margin-top: 0;
      &__input {
        margin-right: 2px;
      }
    }
  }
}
</style>
