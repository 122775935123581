<script>
import { mergeData } from "vue-functional-data-merge"
import { DEFAULT_PROFILE_IMAGE } from "@/config"

export default {
  name: "TournamentUser",
  functional: true,
  props: {
    name: {
      type: String,
      default: "PENDING..."
    },
    imageUrl: {
      type: String
    }
  },
  render(h, { props, data }) {
    return h("div", mergeData(data, { staticClass: "tournament-user" }), [
      h("img", {
        attrs: { src: props.imageUrl || DEFAULT_PROFILE_IMAGE },
        staticClass: props.imageUrl
          ? "tournament-user__img"
          : "tournament-user__img default"
      }),
      h("span", { staticClass: "tournament-user__info" }, props.name)
    ])
  }
}
</script>

<style lang="scss">
$tournament-user-width: 80px;

.tournament-user {
  width: $tournament-user-width;
  text-align: center;
  position: relative;
  display: inline-block;
  vertical-align: top;

  &__img {
    display: block;
    cursor: pointer;
    width: $tournament-user-width;
    height: $tournament-user-width;
    border: 3px solid $color_white;
    border-radius: 50%;
    overflow: hidden;
    background-color: $color-tertiary-dark;
    object-fit: cover;

    &.default {
      padding-top: 10px;
      object-fit: contain;
    }
  }

  &__info {
    position: absolute;
    padding-top: 3px;
    width: 100%;
    height: 0px;
    left: 0;
    font-size: 10px;
    color: $color_white;
    white-space: nowrap;
    pointer-events: none;
  }
}
</style>
