var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "expo-tour", class: { done: _vm.expoTourDone } },
    [
      _c("v-flex", { staticClass: "overlay" }),
      _c("v-flex", { staticClass: "frame", style: _vm.frameStyle }),
      _c(
        "v-flex",
        {
          ref: "messagebox",
          staticClass: "message-box",
          class: _vm.msgboxClass,
          style: { left: _vm.messageLeft, top: _vm.messageTop }
        },
        [
          _c("v-flex", {
            ref: "message",
            staticClass: "message",
            domProps: { innerHTML: _vm._s(_vm.message) }
          }),
          _c(
            "v-flex",
            {
              staticClass: "button",
              class: { show: _vm.showButton },
              on: {
                click: function($event) {
                  return _vm.nextStep()
                }
              }
            },
            [_vm._v("Got it!")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }