var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "tournament-standings", attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c("TournamentStandingsHeader", {
            attrs: { roundList: _vm.roundList, activeRound: _vm.activeRound },
            model: {
              value: _vm.roundID,
              callback: function($$v) {
                _vm.roundID = $$v
              },
              expression: "roundID"
            }
          }),
          _c("TournamentStandingsBody", {
            attrs: {
              round: _vm.selectedRound,
              status: _vm.getRoundStatus(_vm.selectedRound),
              final: _vm.isFinalRound
            },
            on: {
              onSelect: function(data) {
                return _vm.$emit("onSelect", data)
              },
              onGameSelect: function(data) {
                return _vm.$emit("onGameSelect", data)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }