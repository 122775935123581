<template>
  <v-flex d-flex class="pre-game-user-list">
    <v-layout column>
      <v-flex
        class="scrollable"
        ref="scrollable"
        v-on:scroll.passive="onScroll"
        v-show="!!truncatedUsers.length"
      >
        <v-layout row wrap>
          <v-flex
            shrink
            class="all-users-user"
            v-for="player in truncatedUsers"
            :key="`all-users-user-${player.id}`"
            :class="{
              online: player.status === 'online',
              selected: player.id === selectedUserID
            }"
          >
            <v-layout column>
              <PreGamePlayer
                :class="[
                  'user',
                  `user--role--${player.role}`,
                  {
                    online: player.status === 'online',
                    onboarding: player.onboarding || player.expotour === false,
                    'user-select': isHost,
                    'same-game':
                      !!player.gameID && player.gameID === user.gameID
                  }
                ]"
                :imageURL="player.image"
                :userID="player.id"
                @onMouseOver="onMouseOver"
                @mouseleave.native="onMouseLeave"
                @click.alt.native="edit(player)"
                @click.shift.native="edit(player)"
              />
              <v-flex mt-1 text-xs-center class="user-search-user-name">
                <UserName
                  v-once
                  :firstname="player.firstname"
                  :lastname="player.lastname"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import PreGamePlayer from "@/components/GroupTeams/Common/Player/PreGamePlayer"
import UserName from "./GroupTeams/Common/User/UserName"

export default {
  name: "UserList",
  components: {
    PreGamePlayer,
    UserName
  },
  props: {
    selectedUserID: String,
    users: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      step: 30,
      count: 1,
      mouseOverTimeout: null
    }
  },
  beforeDestroy() {
    clearInterval(this.mouseOverTimeout)
  },
  computed: {
    ...mapGetters("auth", ["user", "isHost"]),
    nOfUsers() {
      return this.users.length
    },
    max() {
      return this.count * this.step
    },
    truncatedUsers() {
      return this.users.length > this.max
        ? this.users.slice(0, this.max)
        : this.users
    }
  },
  methods: {
    ...mapActions("UserSettings", ["updateUser"]),
    resetPagination() {
      this.$refs.scrollable.scrollTop = 0
      this.count = 1
    },
    onMouseOver({ userID, $event }) {
      const rect = $event.target.getBoundingClientRect()
      const { x, y, height, width } = rect
      clearTimeout(this.mouseOverTimeout)
      this.mouseOverTimeout = setTimeout(() => {
        this.$emit("onSelect", { userID, x, y, height, width })
      }, 500)
    },
    onMouseLeave() {
      clearTimeout(this.mouseOverTimeout)
    },
    edit(user) {
      if (this.isHost) this.updateUser({ user })
    },
    increment() {
      this.count = this.count + 1
    },
    onScroll() {
      const scrollable = this.$refs.scrollable
      const sum = scrollable.offsetHeight + scrollable.scrollTop
      const OFFSET = 10
      const height = scrollable.scrollHeight - OFFSET
      const hasMoreUsersToShow = this.truncatedUsers.length < this.nOfUsers
      const isBottom = sum >= height
      if (isBottom && hasMoreUsersToShow) this.increment()
    }
  }
}
</script>

<style lang="scss">
.pre-game-user-list {
  .user-search-user-name {
    font-size: 12px;
    color: $color-white !important;
    max-width: 74px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .scrollable {
    overflow: auto;
    direction: rtl;
    & > div {
      position: relative;
      direction: ltr;
      padding-left: 15px;
    }
  }
  .all-users-user {
    padding: 6px;
  }
  .user {
    min-width: 74px;
    max-width: 74px;
    cursor: pointer;
    .player-details {
      display: none !important;
    }
    .video-container:before {
      content: "";
      display: block;
      z-index: 10;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 0px solid $color-white;
      opacity: 0.5;
      transition: border-width 0.5s;
    }
    &.online {
      &.onboarding {
        position: relative;
        opacity: 0.5;
        &:not(.user-select) {
          pointer-events: none;
        }
        &:after {
          align-items: center;
          justify-content: center;
          top: 0;
          left: 0;
          font-size: 8px;
          color: #fff;
          position: absolute;
          display: flex;
          width: 100%;
          height: 100%;
          content: "ONBOARDING";
        }
      }
    }
    &:hover {
      .video-container:before {
        border-width: 40px;
      }
    }
  }
  .selected {
    .video-container:before {
      border-width: 40px;
    }
  }
}
</style>
