<template>
  <v-flex d-flex>
    <v-layout column>
      <v-flex shrink d-flex class="side-bar-tabs">
        <v-layout row>
          <v-flex
            xs6
            text-xs-center
            class="tab rooms-tab"
            d-flex
            align-center
            :class="tab == 'games' ? 'selected' : null"
            @click="tab = 'games'"
          >
            <span class="tab-label">GAME ROOMS</span>
          </v-flex>
          <v-flex
            xs6
            text-xs-center
            class="tab players-tab"
            d-flex
            align-center
            :class="tab == 'players' ? 'selected' : null"
            @click="tab = 'players'"
          >
            <span class="tab-label">PLAYERS</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex d-flex class="side-bar-content-wrap">
        <template v-if="tab === 'games' && tournament">
          <v-flex
            d-flex
            class="scrollable side-bar-content rtl"
            ref="scrollable"
          >
            <GameSearch
              :games="games"
              :selectedGameID="selectedGameID"
              :isGamePlayedByUser="isGamePlayedByUser"
              @addTime="addTime"
              @removeTime="removeTime"
              @onGameCardSelectEvent="onGameCardSelectEvent"
              ref="game_search"
              style="margin-top: 100px;"
            />
          </v-flex>
          <v-flex
            d-flex
            style="position: absolute; width: 100%; height: 100px;"
            align-center
          >
            <TournamentSideBarHeader :tournament="tournament" />
          </v-flex>
        </template>
        <template v-else>
          <v-flex
            d-flex
            class="scrollable side-bar-content rtl"
            ref="scrollable"
          >
            <UserSearch
              v-if="tab === 'players'"
              :clientID="clientID"
              :selectedUserID="selectedUserID"
              @onSelect="onUserSelect"
            />
            <GameSearch
              v-else-if="tab === 'games'"
              :games="games"
              :selectedGameID="selectedGameID"
              :isGamePlayedByUser="isGamePlayedByUser"
              @addTime="addTime"
              @removeTime="removeTime"
              @onGameCardSelectEvent="onGameCardSelectEvent"
              ref="game_search"
            />
          </v-flex>
        </template>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex"
import UserSearch from "@/components/UserSearch"
import GameSearch from "@/components/GameSearch"
import TournamentSideBarHeader from "@/components/Tournament/TournamentSideBarHeader.vue"

export default {
  name: "SideBar",
  components: {
    UserSearch,
    GameSearch,
    TournamentSideBarHeader
  },
  props: {
    selectedUserID: {
      type: String
    },
    selectedGameID: {
      type: String
    },
    isGamePlayedByUser: {
      type: Function
    },
    games: {
      type: Array,
      default: () => []
    },
    tournament: {
      type: Object
    }
  },
  data() {
    return {
      tab: "players"
    }
  },
  computed: {
    ...mapGetters("auth", ["clientID"])
  },
  methods: {
    onUserSelect(params) {
      this.$emit("onUserSelect", params)
    },
    addTime(params) {
      this.$emit("addTime", params)
    },
    removeTime(params) {
      this.$emit("removeTime", params)
    },
    onGameCardSelectEvent(params) {
      this.$emit("onGameCardSelectEvent", params)
    }
  }
}
</script>

<style lang="scss">
.pre-game-all-users {
  margin-right: 10px;
  margin-left: 10px;
}
</style>
