var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "tournament-standing__header", attrs: { shrink: "" } },
    [
      _c(
        "v-layout",
        {
          staticClass: "tournament-standing__header-title",
          attrs: { row: "", wrap: "" }
        },
        [
          _c(
            "v-flex",
            { staticClass: "inner-round", attrs: { "align-self-end": "" } },
            [
              _vm.nextRound
                ? _c(
                    "v-layout",
                    {
                      staticClass: "round-wrapper",
                      staticStyle: { "margin-top": "-15px" },
                      attrs: {
                        "align-center": "",
                        "justify-end": "",
                        row: "",
                        "fill-height": ""
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "round-num text-xs-right",
                          attrs: { "rtb-d-flex": "" }
                        },
                        [
                          _vm._v(
                            " Round " +
                              _vm._s(
                                _vm.activeRound ? _vm.activeRound.num + 1 : null
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "time-remaining text-xs-right",
                          attrs: { "rtb-d-flex": "" }
                        },
                        [_vm._v(" Time remaining: ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "time-remaining text-xs-right",
                          attrs: { "rtb-d-flex": "" }
                        },
                        [
                          _c("PreGameCountdown", {
                            attrs: { "end-time": _vm.endTime }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        {
          staticClass: "tournament-standing__header-rounds",
          attrs: { row: "", wrap: "" }
        },
        _vm._l(_vm.roundList, function(round, i) {
          return _c(
            "v-flex",
            {
              key: "tournament-round-" + i,
              staticClass: "round",
              class: [
                _vm.getRoundStatus(round),
                {
                  "--selected": round.originalGameID === _vm.value,
                  "--final": _vm.roundList.length - 1 == i
                }
              ],
              staticStyle: { "margin-top": "-20px" },
              attrs: { shrink: "", "d-flex": "", "align-start": "" },
              on: {
                click: function($event) {
                  return _vm.$emit("input", round.originalGameID)
                }
              }
            },
            [
              _vm.roundList.length - 1 == i
                ? _c("svg-icon", {
                    staticClass: "round__final-round-cup",
                    attrs: { name: "cup", size: "large" }
                  })
                : _vm._e(),
              _c("TournamentTabText", {
                staticClass: "round__tab-text",
                attrs: { round: round, activeRound: _vm.activeRound }
              }),
              _c("div", { staticClass: "vertical-divider" })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }