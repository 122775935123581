<template functional>
  <div class="tournament-watch-live-small">
    <div class="tournament-watch-live-small__watch">WATCH</div>
    <div class="tournament-watch-live-small__live">LIVE</div>
  </div>
</template>

<script>
export default {
  name: "TournamentWatchLiveSmall"
}
</script>

<style lang="scss">
.tournament-watch-live-small {
  @extend .rtb-box-shadow;
  cursor: pointer;
  background-color: $color-wrong;
  border-radius: 2px;
  padding: 2px;
  height: 20px;
  border: solid 2px $color-white;
  display: inline-block;
  font-weight: bold;
  &:hover {
    filter: brightness(1.2);
  }
  &__watch {
    color: #000;
    font-size: 12px;
    line-height: 12px;
    padding: 0 2px;
    background-color: #fff;
    border-radius: 2px;
    display: inline-block;
    margin-right: 4px;
    vertical-align: top;
  }
  &__live {
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    padding-right: 2px;
    font-weight: bold;
    display: inline-block;
    vertical-align: top;
  }
}
</style>
