<template functional>
  <div class="tournament-watch-live">
    <div class="tournament-watch-live__watch">WATCH</div>
    <div class="tournament-watch-live__live">LIVE</div>
  </div>
</template>

<script>
export default {
  name: "TournamentWatchLive"
}
</script>

<style lang="scss">
.tournament-watch-live {
  @extend .rtb-box-shadow;
  cursor: pointer;
  background-color: $color-wrong;
  border-radius: 5px;
  padding: 3px 6px 5px;
  display: inline-block;
  text-align: center;
  border: solid 2px $color-white;
  &:hover {
    filter: brightness(1.2);
  }
  &__watch {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 3px;
  }
  &__live {
    color: #000;
    border-radius: 3px;
    padding: 0 8px;
    background-color: #fff;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
  }
}
</style>
