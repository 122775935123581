<template>
  <v-flex ref="root">
    <RatioContainer
      class="pre-game-player-slot"
      :class="player.optional ? 'optional' : null"
    >
      <div class="winner-outline" ref="square"></div>
      <v-flex d-flex class="player-container">
        <div class="player-name" v-if="!!firstname || !!lastname">
          <UserName :firstname="firstname" :lastname="lastname" />
        </div>
        <UserMedia
          :videoTrack="track"
          :identity="player.id"
          :imageUrl="imageURL"
          class="pre-game-player-slot__user-media"
        />
        <v-layout justify-center row class="player-details" v-if="hasDetails">
          <v-flex
            shrink
            v-if="!!gameStartTime"
            class="countdown"
            pl-2
            pr-2
            text-xs-center
          >
            <PreGameCountdown :endTime="gameStartTime" />
          </v-flex>
          <v-flex
            v-if="!!game"
            @click="onGameClick"
            class="player-game-link"
            text-xs-center
            pl-2
            pr-2
          >
            {{ gameLabel }}
          </v-flex>
          <v-flex v-else class="player-game-info" text-xs-center pl-2 pr-2>
            {{ gameLabel }}
          </v-flex>
        </v-layout>
        <v-flex
          d-flex
          align-center
          justify-center
          text-xs-center
          class="invite-text-container"
          @click="onPlayerInvite"
          v-if="player.optional"
        >
          <template v-if="canInvitePlayer">
            <span
              class="invite-text-container__text invite-text-container__text--default"
              >Invite to chat</span
            >
            <span
              class="invite-text-container__text invite-text-container__text--hover"
              >Click to chat</span
            >
          </template>
          <span
            v-if="!player.expotour"
            class="invite-text-container__text invite-text-container__text--default"
            >Onboarding</span
          >
        </v-flex>
      </v-flex>
    </RatioContainer>
  </v-flex>
</template>

<script>
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier.vue"
import UserName from "@/components/GroupTeams/Common/User/UserName"
import UserMedia from "@/components/GroupTeams/Common/User/UserMedia"
import PreGameCountdown from "@/components/PreGameCountdown"
import { mapGetters } from "vuex"
import { Role } from "@/helpers"
import { TweenMax, Power2 } from "gsap/TweenMax"

export default {
  name: "PreGamePlayerSlot",
  components: {
    RatioContainer,
    UserMedia,
    UserName,
    PreGameCountdown
  },
  data() {
    return {
      time: null
    }
  },
  props: {
    track: Object,
    imageURL: String,
    src: MediaStream,
    firstname: String,
    lastname: String,
    player: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters("allusers", ["onlineUsersArray"]),
    ...mapGetters("pregame", ["games"]),
    ...mapGetters({
      plays: "missionPlaysArray"
    }),
    gameID() {
      return this.player.gameID
    },
    hasDetails() {
      return this.gameLabel || this.gameStartTime
    },
    game() {
      return this.games && this.gameID ? this.games[this.gameID] : null
    },
    gameLabel() {
      if (this.game) return this.game.externalName || this.game.name
      if (this.queueNumber > 0) return "#" + this.queueNumber + " in queue"
      return "Not on a Game"
    },
    queueNumber() {
      const queue = this.onlineUsersArray.filter(
        ({ noGameTimestamp, role }) => noGameTimestamp && role !== Role.Host
      )
      if (!queue.length) return -1
      queue.sort((a, b) => a.noGameTimestamp - b.noGameTimestamp)
      return queue.findIndex(({ id }) => id === this.player.id) + 1
    },
    gameStartTime() {
      return this.game ? this.game.startTimestamp : 0
    },
    canInvitePlayer() {
      const { player } = this
      return !player.onboarding && player.expotour
    }
  },
  methods: {
    onGameClick() {
      this.$emit("onGameClick", { gameID: this.gameID })
    },
    onPlayerInvite() {
      if (this.canInvitePlayer) {
        this.$emit("onPlayerInvite", { userID: this.player.id })
      }
    },
    playWinningAnimation() {
      this.$nextTick(() => {
        //console.log("WINNING ANIMATION")
        const root = this.$refs.root
        if (!root) return console.log("The root node is undefined")

        root.classList.add("on-top")
        setTimeout(() => {
          root.classList.remove("on-top")
        }, 1500)

        if (this.$refs.square) {
          TweenMax.fromTo(
            this.$refs.square,
            0.25,
            { opacity: 0 },
            { opacity: 1, overwrite: false }
          )
          TweenMax.fromTo(
            this.$refs.square,
            1,
            { scale: 1 },
            { scale: 1.33, overwrite: false, ease: Power2.easeInOut }
          ).delay(0.15)
          TweenMax.to(this.$refs.square, 0.3, {
            opacity: 0,
            overwrite: false
          }).delay(0.75)
        }
      })
    }
  },
  watch: {
    plays() {
      const plays = this.plays.filter(play => play.userID === this.player.id)
      const successfulPlays = plays.filter(({ score }) => score > 0)
      if (successfulPlays.length) this.playWinningAnimation()
    }
  }
}
</script>

<style lang="scss">
.pre-game-player-slot {
  position: relative;
  user-select: none;

  &__user-media {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .winner-outline {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8%;
    left: 0;
    top: 0;
    border: 2px solid $secondary_accent_color;
    // TODO(Vitalij): adjust z-index to not block click events
    z-index: 10;
    opacity: 0;
    // TODO(Vitalij): adjust z-index to not block click events
    pointer-events: none;
  }

  &.optional {
    .invite-text-container {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 16px;
      font-size: 12px;
      background-color: rgba(0, 0, 0, 0.5);
      text-transform: uppercase;
      color: $color-white;
      cursor: pointer;

      &__text {
        &--hover {
          display: none;
        }
      }

      &:hover {
        .invite-text-container__text {
          &--default {
            display: none;
          }
          &--hover {
            display: inline;
          }
        }
      }
    }
  }

  .countdown {
    color: $color-correct;
    font-weight: bold;
    &:not(:last-child) {
      padding-right: 0 !important;
    }
  }

  .player-game-link {
    color: $color-white;
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .player-game-info {
    color: $color-white;
  }

  .video-stream-wrap {
    max-height: 100%;
    width: 100%;
  }

  .player-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 8%;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .player-name {
    color: $color-white;
    position: absolute;
    width: 100%;
    height: 16px;
    line-height: 16px;
    font-size: 11px;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }

  .player-details {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 16px;
    font-size: 11px;
    line-height: 16px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &.optional:hover {
    .player-details {
      bottom: -16px;
    }
    .player-name {
      top: -16px;
    }
  }
}
</style>
