var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { shrink: "", "d-flex": "" } },
    [
      _c(
        "button",
        {
          staticClass: "game-card-btn",
          attrs: { disabled: _vm.isButtonDisabled },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.onClick($event)
            },
            mouseenter: function($event) {
              _vm.isButtonHovered = true
            },
            mouseleave: function($event) {
              _vm.isButtonHovered = false
            }
          }
        },
        [
          _vm.loading
            ? _c("v-progress-circular", {
                staticClass: "game-card-btn__progress",
                attrs: {
                  indeterminate: "",
                  size: "25",
                  color: _vm.progressColor
                }
              })
            : [_vm._v(_vm._s(_vm.gameButtonText))]
        ],
        2
      ),
      _vm.status == "running"
        ? _c(
            "v-flex",
            { staticClass: "countdown running", on: { click: _vm.changeTime } },
            [_vm._v(" In Progress ")]
          )
        : _vm.status == "ended"
        ? _c("v-flex", { staticClass: "countdown running" }, [
            _vm._v(" Ended ")
          ])
        : _vm.status == "played"
        ? _c("v-flex", { staticClass: "countdown running" }, [
            _vm._v(" Played ")
          ])
        : _vm.status == "boarding"
        ? _c(
            "v-flex",
            { staticClass: "countdown running", on: { click: _vm.changeTime } },
            [_vm._v(" Boarding ")]
          )
        : _vm.status == "pending" && !!_vm.gameStartTime
        ? _c(
            "v-flex",
            { staticClass: "countdown", on: { click: _vm.changeTime } },
            [
              _vm._v(" Starts in "),
              _c("PreGameCountdown", { attrs: { endTime: _vm.gameStartTime } })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }