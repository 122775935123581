var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "live-chat", attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c("v-flex", { attrs: { shrink: "" } }),
          _c(
            "v-flex",
            [
              _c(
                "v-layout",
                { staticClass: "live-chat-controls", attrs: { row: "" } },
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "live-chat-status-container",
                          attrs: { shrink: "" }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "live-chat-status",
                              class: { on: _vm.isUserOnline }
                            },
                            [_vm._v("•")]
                          ),
                          _c("span", { staticClass: "live-chat-text" }, [
                            _vm._v("EXPO CHAT")
                          ])
                        ]
                      ),
                      !_vm.room
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "room-lock-link",
                              class: _vm.isUserLocked ? "locked" : null,
                              attrs: {
                                "mr-2": "",
                                shrink: "",
                                "d-flex": "",
                                "align-center": ""
                              },
                              on: { click: _vm.onToggleUserLock }
                            },
                            [
                              _vm.isUserLocked
                                ? [
                                    _c("i", { staticClass: "fas fa-lock" }),
                                    _c("span", [_vm._v("LOCKED")])
                                  ]
                                : [
                                    _c("i", {
                                      staticClass: "fas fa-lock-open"
                                    }),
                                    _c("span", [_vm._v("UNLOCKED")])
                                  ]
                            ],
                            2
                          )
                        : _vm._e(),
                      !!_vm.room
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "room-lock-link",
                              class: _vm.isRoomLocked ? "locked" : null,
                              attrs: {
                                "mr-2": "",
                                shrink: "",
                                "d-flex": "",
                                "align-center": ""
                              },
                              on: { click: _vm.onToggleRoomLock }
                            },
                            [
                              _vm.isRoomLocked
                                ? [
                                    _c("i", { staticClass: "fas fa-lock" }),
                                    _c("span", [_vm._v("LOCKED")])
                                  ]
                                : [
                                    _c("i", {
                                      staticClass: "fas fa-lock-open"
                                    }),
                                    _c("span", [_vm._v("UNLOCKED")])
                                  ]
                            ],
                            2
                          )
                        : _vm._e(),
                      !!_vm.room
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "disconnet-link",
                              attrs: {
                                shrink: "",
                                "d-flex": "",
                                "align-center": ""
                              },
                              on: { click: _vm.onDisconnect }
                            },
                            [
                              _c("i", { staticClass: "fas fa-times-circle" }),
                              _c("span", [_vm._v("LEAVE CHAT")])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  !!_vm.flashGames.length
                    ? _c(
                        "v-layout",
                        { attrs: { "justify-end": "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "icebreaker-title",
                              attrs: { shrink: "" }
                            },
                            [_vm._v("Play Ice Breaker")]
                          ),
                          _vm._l(_vm.flashGames, function(game) {
                            return _c(
                              "v-flex",
                              {
                                key: "trivia-game-" + game.id,
                                staticClass: "icebreaker-game",
                                class: _vm.isFlashGameSelected({
                                  gameID: game.id
                                })
                                  ? "selected"
                                  : null,
                                attrs: { shrink: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.onFlashGameSelect({
                                      gameID: game.id
                                    })
                                  }
                                }
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "rtb-color-secondary",
                                  attrs: {
                                    name: "missions/multiple-choice",
                                    size: "small"
                                  }
                                }),
                                _c("span", [
                                  _vm._v(_vm._s(game.externalName || game.name))
                                ])
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-layout",
                {
                  staticClass: "max-width live-chat-container",
                  attrs: { column: "", "pl-5": "", "pr-5": "" }
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticStyle: { position: "relative" },
                      attrs: { "d-flex": "" }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "live-chat-players",
                          attrs: { row: "", "justify-center": "" }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "live-chat-player-wrap",
                              attrs: { "d-flex": "", xs2: "" }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { column: "" } },
                                [
                                  !!_vm.videoTrack && !!_vm.user
                                    ? _c("PreGamePlayerSlot", {
                                        attrs: {
                                          track: _vm.videoTrack,
                                          firstname: _vm.user.firstname,
                                          player: _vm.user,
                                          lastname: _vm.user.lastname,
                                          imageURL: _vm.user.image
                                        },
                                        on: { onGameClick: _vm.onGameClick }
                                      })
                                    : _c("LiveChatDummySlot")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.users, function(player, i) {
                            return _c(
                              "v-flex",
                              {
                                key: "live-chat-slot-" + i,
                                staticClass: "live-chat-player-wrap",
                                attrs: { xs2: "", "d-flex": "" }
                              },
                              [
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "flip-transition",
                                      mode: "out-in"
                                    }
                                  },
                                  [
                                    !!player
                                      ? _c("PreGamePlayerSlot", {
                                          key:
                                            "player-slot-" +
                                            player.id +
                                            "-" +
                                            i,
                                          attrs: {
                                            player: player,
                                            firstname: player.firstname,
                                            lastname: player.lastname,
                                            imageURL: player.image,
                                            showInvite: player.optional
                                          },
                                          on: {
                                            onGameClick: _vm.onGameClick,
                                            onPlayerInvite: _vm.onPlayerInvite
                                          }
                                        })
                                      : _c("LiveChatDummySlot", {
                                          key: "player-dummy-" + i
                                        })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._l(_vm.audio, function(user) {
        return _c("UserAudio", {
          key: "user-audio-" + user.id,
          attrs: { track: user.track }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }