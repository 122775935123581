<template>
  <v-flex d-flex class="live-chat">
    <v-layout column>
      <v-flex shrink> </v-flex>
      <v-flex>
        <v-layout row class="live-chat-controls">
          <v-layout>
            <v-flex shrink class="live-chat-status-container">
              <span class="live-chat-status" :class="{ on: isUserOnline }"
                >•</span
              >
              <span class="live-chat-text">EXPO CHAT</span>
            </v-flex>
            <v-flex
              mr-2
              shrink
              d-flex
              v-if="!room"
              align-center
              @click="onToggleUserLock"
              class="room-lock-link"
              :class="isUserLocked ? 'locked' : null"
            >
              <template v-if="isUserLocked">
                <i class="fas fa-lock"></i>
                <span>LOCKED</span>
              </template>
              <template v-else>
                <i class="fas fa-lock-open"></i>
                <span>UNLOCKED</span>
              </template>
            </v-flex>
            <v-flex
              mr-2
              shrink
              d-flex
              v-if="!!room"
              align-center
              @click="onToggleRoomLock"
              class="room-lock-link"
              :class="isRoomLocked ? 'locked' : null"
            >
              <template v-if="isRoomLocked">
                <i class="fas fa-lock"></i>
                <span>LOCKED</span>
              </template>
              <template v-else>
                <i class="fas fa-lock-open"></i>
                <span>UNLOCKED</span>
              </template>
            </v-flex>
            <v-flex
              shrink
              d-flex
              align-center
              v-if="!!room"
              @click="onDisconnect"
              class="disconnet-link"
            >
              <i class="fas fa-times-circle"></i>
              <span>LEAVE CHAT</span>
            </v-flex>
          </v-layout>
          <v-layout justify-end v-if="!!flashGames.length">
            <v-flex shrink class="icebreaker-title">Play Ice Breaker</v-flex>
            <v-flex
              shrink
              class="icebreaker-game"
              :class="
                isFlashGameSelected({ gameID: game.id }) ? 'selected' : null
              "
              :key="`trivia-game-${game.id}`"
              v-for="game in flashGames"
              @click="onFlashGameSelect({ gameID: game.id })"
            >
              <svg-icon
                name="missions/multiple-choice"
                size="small"
                class="rtb-color-secondary"
              />
              <span>{{ game.externalName || game.name }}</span>
            </v-flex>
          </v-layout>
        </v-layout>
        <v-layout column class="max-width live-chat-container" pl-5 pr-5>
          <v-flex d-flex style="position: relative">
            <v-layout row justify-center class="live-chat-players">
              <v-flex d-flex xs2 class="live-chat-player-wrap">
                <v-layout column>
                  <PreGamePlayerSlot
                    v-if="!!videoTrack && !!user"
                    :track="videoTrack"
                    :firstname="user.firstname"
                    :player="user"
                    :lastname="user.lastname"
                    :imageURL="user.image"
                    @onGameClick="onGameClick"
                  />
                  <LiveChatDummySlot v-else />
                </v-layout>
              </v-flex>
              <v-flex
                xs2
                d-flex
                v-for="(player, i) in users"
                :key="`live-chat-slot-${i}`"
                class="live-chat-player-wrap"
              >
                <transition name="flip-transition" mode="out-in">
                  <PreGamePlayerSlot
                    v-if="!!player"
                    :key="`player-slot-${player.id}-${i}`"
                    :player="player"
                    :firstname="player.firstname"
                    :lastname="player.lastname"
                    :imageURL="player.image"
                    :showInvite="player.optional"
                    @onGameClick="onGameClick"
                    @onPlayerInvite="onPlayerInvite"
                  />
                  <LiveChatDummySlot v-else :key="`player-dummy-${i}`" />
                </transition>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <UserAudio
      v-for="user in audio"
      :key="`user-audio-${user.id}`"
      :track="user.track"
    />
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import { ActionTypes as TwilioModuleActionTypes } from "../store/TwilioModule"

import LiveChatDummySlot from "@/components/LiveChatDummySlot"
import PreGamePlayerSlot from "@/components/GroupTeams/Common/Player/PreGamePlayerSlot"
import SvgIcon from "@/components/base/SvgIcon.vue"

import { Math as Numbers, Role } from "../helpers"
import UserAudio from "@/components/GroupTeams/Common/User/UserAudio"

// assets
import ArriveSound from "@/assets/sounds/arrive.mp3"
import LeaveSound from "@/assets/sounds/leave.mp3"

export default {
  name: "LiveChat",
  components: {
    LiveChatDummySlot,
    PreGamePlayerSlot,
    SvgIcon,
    UserAudio
  },
  props: {
    flashGames: {
      type: Array,
      default: () => []
    },
    selectedGameID: {
      type: String
    }
  },
  data() {
    return {
      videoTrack: null,
      optionalUsersIDs: [],
      timer: null,
      isWorking: false
    }
  },
  async created() {
    await this.startVideo()
  },
  async beforeDestroy() {
    this.stopVideo()
    clearInterval(this.timer)
    // Remove all stored subscriptions
    await this.removeAllSubscriptions()
  },
  watch: {
    isUserOnline: {
      handler(value) {
        if (value) {
          clearTimeout(this.timer)
          this.optionalUsersIDs = []
        } else {
          this.isWorking = false
          this.timer = setInterval(() => {
            this.checkUsersGame()
            this.addRandomUser()
          }, 15000)
        }
      },
      immediate: true
    },
    optionalUsersIDs(ids) {
      if (ids) {
        this.refreshSubscriptions({
          usersIDs: ids,
          /**
           * This function will be called when one of random users joins to another room
           * @param roomID
           * @param userID
           */
          callback: ({ roomID, userID }) => {
            if (roomID !== this.roomID) {
              const index = this.optionalUsersIDs.indexOf(userID)
              if (index >= 0) {
                this.optionalUsersIDs.splice(index, 1)
              }
            }
          }
        })
      }
    },
    /**
     * @param {Array} newValue
     * @param {Array} previousValue
     */
    roomUsers(newValue, previousValue) {
      const { length: currentUssersCount } = newValue
      const { length: previousUsersCount } = previousValue
      if (currentUssersCount > previousUsersCount) {
        this.onParticipantConnected()
      } else if (currentUssersCount < previousUsersCount) {
        this.onParticipantDisconnedcted()
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "access", "client", "isHost"]),
    ...mapGetters("twilio", { twilioUsers: "users" }),
    ...mapGetters("twilio", ["isConnected"]),
    ...mapGetters("allusers", [
      "onlineUsersArray",
      "onlineUsersGroupedByGameID"
    ]),
    ...mapGetters("livechat", ["max", "room", "roomID", "isUserLocked"]),
    ...mapGetters("pregame", ["games", "gamesArray"]),
    roomUsers() {
      return this.roomUsersIDs
        .map(userID => this.onlineUsersArray.find(user => user.id === userID))
        .filter(user => user)
    },
    optionalUsers() {
      return this.optionalUsersIDs
        .map(userID => this.onlineUsersArray.find(user => user.id === userID))
        .filter(user => user)
    },
    gameName() {
      return this.game.externalName || this.game.name
    },
    roomUsersIDs() {
      if (!this.room) return []
      if (!this.room.users) return []
      return Object.values(this.room.users)
        .filter(user => user)
        .filter(({ status, userID }) => {
          return status === "active" && userID !== this.user.id && userID
        })
        .map(({ userID }) => userID)
    },
    isRoomLocked() {
      return this.room ? !!this.room.locked : false
    },
    audio() {
      return this.roomUsersIDs
        .map(userID => ({
          id: userID,
          track: this.twilioUsers?.[userID]?.audioTrack
        }))
        .filter(user => user.track)
    },
    users() {
      const output = []
      for (let i = 0; i < this.max - 1; i++) {
        if (
          !this.isUserOnline &&
          this.optionalUsers.length &&
          !this.isWorking
        ) {
          if (this.optionalUsers[i]) {
            output.push({ ...this.optionalUsers[i], optional: true })
          } else {
            output.push(null)
          }
        } else {
          output.push(this.roomUsers[i])
        }
      }
      return output
    },
    isUserOnline() {
      return this.twilioUsers
        ? !!this.twilioUsers[this.user.id] || this.room
        : false
    }
  },
  methods: {
    ...mapActions("twilio", [TwilioModuleActionTypes.CREATE_LOCAL_VIDEO_TRACK]),
    ...mapActions("test", ["getCameraErrorMessage"]),
    ...mapActions("livechat", [
      "toggleRoomLock",
      "isUserBusy",
      "toggleUserLock",
      "refreshSubscriptions",
      "removeAllSubscriptions"
    ]),
    isFlashGameSelected({ gameID }) {
      return gameID && gameID === this.selectedGameID
    },
    onFlashGameSelect({ gameID }) {
      this.$emit("onFlashGameSelect", { gameID })
    },
    getPendingGamesIDs() {
      return this.gamesArray
        .filter(({ started }) => !started)
        .map(({ id }) => id)
    },
    anonymousUsers() {
      return this.client ? !!this.client.anonymousUsers : false
    },
    filterByAccess(value) {
      const array = Array.isArray(value) ? value : []
      return array.filter(({ role }) => {
        if (!this.anonymousUsers || this.isHost) {
          return true
        } else {
          return role !== Role.Host
        }
      })
    },
    getRandomUsersPool() {
      const keys = Object.keys(this.onlineUsersGroupedByGameID)
      const pendingGamesIDs = this.getPendingGamesIDs()
      const pendingKeys = keys.filter(key => pendingGamesIDs.includes(key))
      const assigned = pendingKeys.reduce(
        (acc, val) =>
          acc.concat(this.filterByAccess(this.onlineUsersGroupedByGameID[val])),
        []
      )
      const unassigned = this.filterByAccess(
        this.onlineUsersGroupedByGameID["unassigned"]
      )

      let all = []

      if (assigned && assigned.length) {
        all = all.concat(assigned)
      }

      if (unassigned && unassigned.length) {
        all = all.concat(unassigned)
      }

      return all.filter(
        ({ id, onboarding }) =>
          !this.optionalUsersIDs.some(userID => userID === id) &&
          id !== this.user.id &&
          !onboarding
      )
    },
    async addRandomUser() {
      const user = await this.findUser()
      if (user) {
        const { id: userID } = user
        this.optionalUsersIDs = [
          userID,
          ...this.optionalUsersIDs.slice(0, this.max)
        ]
      } else {
        console.log("No available players found")
      }
    },
    async findUser() {
      const MAX = 10
      let found = null
      let count = 0
      const array = this.getRandomUsersPool()

      // console.log("array", array)

      while (!found && array.length && ++count < MAX && !this.isUserOnline) {
        // console.log(`${count} try`)
        const index = Numbers.getRandomInt(0, array.length - 1)
        const user = array[index]
        if (!user) throw new Error("Undefined user")
        if (!user.id) throw new Error("User doesn't have an ID")
        const isUserBusy = await this.isUserBusy({ userID: user.id })
        if (!isUserBusy) found = user
      }

      return found
    },
    async onToggleRoomLock() {
      await this.toggleRoomLock()
    },
    async onToggleUserLock() {
      await this.toggleUserLock()
    },
    onGameClick({ gameID }) {
      this.$emit("onGameClick", { gameID, scroll: true })
    },
    onPlayerInvite({ userID }) {
      this.isWorking = true
      this.$emit("onPlayerInvite", { userID })
    },
    async startVideo() {
      this.videoTrack = null
      this.stopVideo()
      try {
        this.videoTrack = await this[
          TwilioModuleActionTypes.CREATE_LOCAL_VIDEO_TRACK
        ]()
      } catch (e) {
        const message = await this.getCameraErrorMessage({ error: e })
        return alert(message)
      }
    },
    stopVideo() {
      if (this.videoTrack && this.videoTrack.stop) this.videoTrack.stop()
    },
    onDisconnect() {
      this.$emit("onDisconnect")
    },
    checkUsersGame() {
      this.optionalUsers.forEach(user => {
        try {
          if (this.games[user.gameID].started) {
            const index = this.optionalUsersIDs.indexOf(user.id)
            this.optionalUsersIDs.splice(index, 1)
          }
        } catch (e) {}
      })
    },
    playSound(sound) {
      const audio = new Audio(sound)
      audio.play().catch(e => {
        console.error(e)
      })
    },
    onParticipantConnected() {
      this.playSound(ArriveSound)
    },
    onParticipantDisconnedcted() {
      this.playSound(LeaveSound)
    }
  }
}
</script>

<style lang="scss">
.live-chat {
  position: relative;
  .flip-transition-enter-active,
  .flip-transition-leave-active {
    transition: opacity ease 0.3s;
    opacity: 1;
    // transform: rotateX(0deg);
  }
  .flip-transition-enter {
    opacity: 0;
    // transform: rotateY(90deg);
  }
  .flip-transition-leave-to {
    opacity: 0;
    // transform: rotateY(-90deg);
  }
  .room-lock-link,
  .disconnet-link {
    position: relative;
    z-index: 10;
  }
  .live-chat-container {
    padding-bottom: 15px;
  }
  .live-chat-controls {
    font-size: 10px;
    font-weight: 500;
    margin: 8px;
    .underline {
      text-decoration: underline;
    }
    i {
      margin-right: 3px;
    }
    .disconnet-link {
      cursor: pointer;
      color: $color-wrong;
      i {
        color: $color-wrong;
      }
    }
    .room-lock-link {
      cursor: pointer;
      color: $color-grey-light4;
      i {
        color: $color-grey-light4;
      }
      &:hover {
        color: $color-correct;
        i {
          color: $color-correct;
        }
      }
      &.locked {
        color: $color-correct;
        i {
          color: $color-correct;
        }
        &:hover {
          color: $color-grey-light4;
          i {
            color: $color-grey-light4;
          }
        }
      }
    }
  }
  .icebreaker-title {
    color: $primary_accent_color;
    padding-right: 8px;
    margin-right: 8px;
    font-weight: 800;
    font-size: 14px;
    line-height: 13px;
    height: 12px;
    margin-top: 2px;
    text-transform: uppercase;
    white-space: nowrap;
    border-right: solid 1px $color-grey;
  }
  .icebreaker-game {
    user-select: none;
    white-space: nowrap;
    margin-right: 10px;
    cursor: pointer;
    span {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      display: inline-block;
      vertical-align: top;
      line-height: 16px;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      padding-left: 5px;
      transition: all 0.1s ease-out;
      &:hover {
        color: $primary_accent_color;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &.selected {
      color: $primary_accent_color;
    }
  }
  .live-chat-player-wrap {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
  .live-chat-text {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    color: $color-grey2;
    font-weight: 400;
    margin-right: 10px;
  }
  .live-chat-status-container {
    white-space: nowrap;
  }
  .live-chat-status {
    display: inline-block;
    vertical-align: middle;
    color: $color-wrong;
    font-size: 16px;
    line-height: 22px;
    margin-right: 4px;
    margin-top: -2px;
    margin-bottom: -2px;
    // margin-left: -5px;
    &.on {
      color: $color-correct;
    }
  }
  .audio-element {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    right: 0;
  }

  .svg-icon {
    display: inline-block;
    vertical-align: top;
    width: 14px;
    height: 17px;
    color: $color_secondary_accent;
  }
}

@media (max-width: 1300px) {
  .live-chat {
    .live-chat-container {
      padding-bottom: 10px;
    }
    .live-chat-player-wrap {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}
</style>
