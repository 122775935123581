<template>
  <v-flex class="tournament-standing__header" shrink>
    <v-layout row wrap class="tournament-standing__header-title">
      <v-flex align-self-end class="inner-round">
        <v-layout
          class="round-wrapper"
          align-center
          justify-end
          row
          fill-height
          v-if="nextRound"
          style="margin-top: -15px"
        >
          <div rtb-d-flex class="round-num text-xs-right">
            Round
            {{ activeRound ? activeRound.num + 1 : null }}
          </div>
          <div rtb-d-flex class="time-remaining text-xs-right">
            Time remaining:
          </div>
          <div rtb-d-flex class="time-remaining text-xs-right">
            <PreGameCountdown :end-time="endTime" />
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="tournament-standing__header-rounds">
      <v-flex
        style="margin-top: -20px"
        shrink
        class="round"
        d-flex
        align-start
        v-for="(round, i) in roundList"
        :key="`tournament-round-${i}`"
        @click="$emit('input', round.originalGameID)"
        :class="[
          getRoundStatus(round),
          {
            '--selected': round.originalGameID === value,
            '--final': roundList.length - 1 == i
          }
        ]"
      >
        <svg-icon
          v-if="roundList.length - 1 == i"
          class="round__final-round-cup"
          name="cup"
          size="large"
        />
        <TournamentTabText
          class="round__tab-text"
          :round="round"
          :activeRound="activeRound"
        />
        <div class="vertical-divider"></div>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { TournamentService } from "@/services/tournament/tournament.service"
import { MomentFilter } from "@/mixins/filters"
import TournamentTabText from "@/components/Tournament/TournamentTabText"
import PreGameCountdown from "@/components/PreGameCountdown"

export default {
  name: "TournamentStandingsHeader",
  components: {
    PreGameCountdown,
    TournamentTabText
  },
  props: {
    value: {
      type: String,
      required: true
    },
    roundList: {
      type: Array,
      required: true
    },
    activeRound: {
      type: Object,
      required: true
    }
  },
  mixins: [MomentFilter],
  methods: {
    getRoundStatus(round) {
      return TournamentService.getRoundStatus(round, this.activeRound)
    }
  },
  computed: {
    name() {
      return this.$store.state.auth.client.name
    },
    nextRound() {
      try {
        return this.roundList[this.activeRound.num + 1]
      } catch (e) {
        return null
      }
    },
    endTime() {
      return (
        this.activeRound?.round.expectedEndTime ||
        this.nextRound?.round.timestamp
      )
    }
  }
}
</script>
<style lang="scss">
@import "@/scss/variables";
.tournament-standing__header {
  &__details {
    display: inline-block;
    vertical-align: middle;
  }
  &__calendar-icon {
    display: inline-block;
    vertical-align: middle;
    color: $primary_accent_color !important;
    display: inline-block;
    margin-left: 6px;
    &:hover {
      filter: brightness(1.2);
    }
  }
  &-title {
    color: $color-white;
    vertical-align: middle;
    line-height: 24px;

    .inner-title {
      font-size: 26px;
      line-height: 24px;
      font-family: "Avenir Next";
      color: $color-white;
      font-weight: bold;
      font-style: italic;
      text-transform: uppercase;
      vertical-align: top;

      .svg-icon {
        vertical-align: top;
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }
    }
    .inner-round {
      height: 24px;
      .round-num {
        font-size: 14px;
        line-height: 18px;
        font-family: "Avenir Next";
        color: $color-black;
        background: $color-white;
        border-radius: 3px;
        padding: 0 6px;
        font-style: italic;
        font-weight: bold;
        text-align: left;
        text-transform: uppercase;
        margin-right: 5px;
      }
      .time-remaining {
        font-size: 14px;
        font-family: "Avenir Next";
        color: $color-white;
        font-weight: 500;
        text-align: left;
        font-style: italic;
        text-transform: uppercase;
        span {
          margin-left: 5px;
          color: $color-green2;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
        }
      }
    }
  }
  &-rounds {
    color: $color-white;
    // margin-top: 10px;

    .round {
      position: relative;
      line-height: 36px;
      height: 36px;
      font-weight: 700;
      padding: 0 15px;
      cursor: pointer;
      margin-right: -1px;
      text-align: center;
      text-transform: uppercase;
      z-index: 3;
      &.active,
      &.--selected {
        color: $color-primary-accent;
      }
      &.--selected {
        background-color: $color-white;
        box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.33);
        .vertical-divider {
          display: none;
        }
      }

      &__final-round-cup {
        align-self: center;
        margin-right: 6px;
      }

      &.ended {
        color: rgb(185, 183, 182);
      }

      .vertical-divider {
        border-right: solid white 1px;
        height: 24px;
        position: absolute;
        right: 0;
        top: 5px;
      }

      &:last-child {
        .vertical-divider {
          display: none;
        }
      }
    }
  }
}
</style>
