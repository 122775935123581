var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { ref: "root" },
    [
      _c(
        "RatioContainer",
        {
          staticClass: "pre-game-player-slot",
          class: _vm.player.optional ? "optional" : null
        },
        [
          _c("div", { ref: "square", staticClass: "winner-outline" }),
          _c(
            "v-flex",
            { staticClass: "player-container", attrs: { "d-flex": "" } },
            [
              !!_vm.firstname || !!_vm.lastname
                ? _c(
                    "div",
                    { staticClass: "player-name" },
                    [
                      _c("UserName", {
                        attrs: {
                          firstname: _vm.firstname,
                          lastname: _vm.lastname
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("UserMedia", {
                staticClass: "pre-game-player-slot__user-media",
                attrs: {
                  videoTrack: _vm.track,
                  identity: _vm.player.id,
                  imageUrl: _vm.imageURL
                }
              }),
              _vm.hasDetails
                ? _c(
                    "v-layout",
                    {
                      staticClass: "player-details",
                      attrs: { "justify-center": "", row: "" }
                    },
                    [
                      !!_vm.gameStartTime
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "countdown",
                              attrs: {
                                shrink: "",
                                "pl-2": "",
                                "pr-2": "",
                                "text-xs-center": ""
                              }
                            },
                            [
                              _c("PreGameCountdown", {
                                attrs: { endTime: _vm.gameStartTime }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !!_vm.game
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "player-game-link",
                              attrs: {
                                "text-xs-center": "",
                                "pl-2": "",
                                "pr-2": ""
                              },
                              on: { click: _vm.onGameClick }
                            },
                            [_vm._v(" " + _vm._s(_vm.gameLabel) + " ")]
                          )
                        : _c(
                            "v-flex",
                            {
                              staticClass: "player-game-info",
                              attrs: {
                                "text-xs-center": "",
                                "pl-2": "",
                                "pr-2": ""
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.gameLabel) + " ")]
                          )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.player.optional
                ? _c(
                    "v-flex",
                    {
                      staticClass: "invite-text-container",
                      attrs: {
                        "d-flex": "",
                        "align-center": "",
                        "justify-center": "",
                        "text-xs-center": ""
                      },
                      on: { click: _vm.onPlayerInvite }
                    },
                    [
                      _vm.canInvitePlayer
                        ? [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "invite-text-container__text invite-text-container__text--default"
                              },
                              [_vm._v("Invite to chat")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "invite-text-container__text invite-text-container__text--hover"
                              },
                              [_vm._v("Click to chat")]
                            )
                          ]
                        : _vm._e(),
                      !_vm.player.expotour
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "invite-text-container__text invite-text-container__text--default"
                            },
                            [_vm._v("Onboarding")]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }