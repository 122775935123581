var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "game-info-youtube", attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "game-card-header", attrs: { shrink: "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "game-title", attrs: { "d-flex": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "game-title-name",
                              attrs: { "d-flex": "" }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", "justify-center": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      _vm._m(0),
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "actual-start-time",
                                          attrs: { row: "", shrink: "" }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass: "start-time",
                                                      attrs: {
                                                        "d-flex": "",
                                                        "align-center": ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Viewable Anytime "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-flex", {
            staticClass: "game-card-green",
            style: _vm.cardBodyStyles,
            attrs: { "d-flex": "" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("v-flex", { staticClass: "game-name", attrs: { shrink: "" } }, [
      _c("h1", [_vm._v(_vm._s(_vm.name))])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }