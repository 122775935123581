<template>
  <v-flex d-flex class="pre-game-profile">
    <v-layout row v-if="!!profileUser && !!profileUser.id">
      <v-flex
        xs4
        style="position: relative"
        mt-3
        ml-3
        mb-3
        mr-2
        d-flex
        align-center
        justify-center
      >
        <PreGamePlayer
          class="player"
          :key="profileUser.id"
          :imageURL="profileUser.image"
        />
        <!-- TODO: move to the SvgIcon component -->
        <svg
          v-if="isSpectator"
          viewBox="0 0 487 487"
          class="pre-game-profile__media-icon"
        >
          <path
            d="M379 230c-26 0-46 20-46 46 0 6 5 11 11 11s11-5 11-11c0-13 11-24 24-24 6 0 11-5 11-11s-5-11-11-11z"
          />
          <path
            d="M455 198l-9-8-41-35c-16-11-35-18-55-19l2 2-25-25a39 39 0 00-63 17c-5-3-11-5-18-6h-3c-7 0-13 2-19 6a39 39 0 00-63-17h-1l-24 25 2-2c-21 1-40 8-55 20l-41 34-9 8a109 109 0 10184 92 54 54 0 0054-1 109 109 0 10184-91zM109 350a74 74 0 110-148 74 74 0 010 148zm135-84a24 24 0 110-48 24 24 0 010 48zm135 84a74 74 0 110-148 74 74 0 010 148z"
          />
          <path
            d="M109 230c-26 0-46 20-46 46 0 6 5 11 11 11s11-5 11-11c0-13 11-24 24-24 6 0 11-5 11-11s-5-11-11-11z"
          />
        </svg>
      </v-flex>
      <v-flex xs8 d-flex mt-3 mr-3 mb-3>
        <v-layout column>
          <v-flex shrink>
            <v-layout row align-center wrap>
              <v-flex shrink mr-2>
                <h1 class="profile-user-name rtb-truncate">
                  <UserName
                    :firstname="profileUser.firstname"
                    :lastname="profileUser.lastname"
                  />
                </h1>
              </v-flex>
              <v-flex shrink v-if="userID !== user.id">
                <div class="online-status" v-if="userStatus === 'online'">
                  ONLINE
                </div>
                <div class="online-status offline" v-else>OFFLINE</div>
              </v-flex>
              <v-flex
                shrink
                ml-2
                v-if="!!profileUser.speaker"
                class="profile-speaker-icon"
              >
                <i class="fas fa-bullhorn"></i>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex d-flex align-center class="game-details" v-if="!!profileGame">
            <v-layout row>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-flex
                    xs7
                    class="rtb-line-clamp rtb-line-clamp--3"
                    v-on="on"
                  >
                    <span class="link game-name" @click="onGameClick">
                      {{ profileGame.externalName || profileGame.name }}
                    </span>
                  </v-flex>
                </template>
                <span>{{ profileGame.externalName || profileGame.name }}</span>
              </v-tooltip>
              <v-flex xs5 v-if="!!gameStartTime" class="countdown-wrap">
                <span class="game-starts-in"
                  ><PreGameCountdown :endTime="gameStartTime" class="rtb-block"
                /></span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            d-flex
            align-center
            class="game-details"
            v-else-if="queueNumber > 0"
          >
            <span class="queue"> #{{ queueNumber }} in queue </span>
          </v-flex>
          <v-flex d-flex align-center>
            <v-layout row>
              <v-flex d-flex align-center xs7 class="rtb-self-end">
                <RoomInfo :users="currentRoomUsers" :nOfSlots="max - 1" />
              </v-flex>
              <v-flex d-flex xs5 class="buttons ml-2 rtb-flex-col">
                <div
                  class="move-to-game"
                  v-if="
                    isHost && (!isHostRole(profileUser.role) || !!user.super)
                  "
                >
                  <button>Move to Game</button>
                  <v-select
                    :items="filteredGamesArray"
                    v-model="assignedToGame"
                    label="Play Type"
                    required
                  >
                    <template slot="item" slot-scope="data">
                      <div>
                        {{ data.item.externalName || data.item.name }}
                      </div>
                    </template>
                  </v-select>
                </div>
                <button
                  d-flex
                  align-center
                  v-if="requestButtonStatus === 'onboarding'"
                  disabled
                  class="invite-button disabled"
                >
                  ONBOARDING
                </button>
                <button
                  d-flex
                  align-center
                  v-else-if="requestButtonStatus === 'full'"
                  disabled
                  class="invite-button disabled"
                >
                  CHAT FULL
                </button>
                <button
                  d-flex
                  align-center
                  v-else-if="requestButtonStatus === 'playing'"
                  disabled
                  class="invite-button disabled"
                >
                  IN GAME
                </button>
                <button
                  d-flex
                  align-center
                  v-else-if="requestButtonStatus === 'show'"
                  @click.stop="onInvite"
                  class="invite-button"
                >
                  CHAT
                </button>
                <button
                  d-flex
                  align-center
                  v-else-if="requestButtonStatus === 'loading'"
                  class="invite-button disabled"
                >
                  <v-progress-circular indeterminate class="spinner" />
                </button>
                <button
                  d-flex
                  align-center
                  @click.stop="onJoin"
                  v-else-if="requestButtonStatus === 'join'"
                  class="invite-button join"
                >
                  CHAT
                </button>
                <button
                  d-flex
                  align-center
                  v-else-if="requestButtonStatus === 'locked'"
                  class="invite-button disabled locked"
                >
                  <i class="fas fa-lock locked-icon"></i>
                  <span class="locked-text"> LOCKED </span>
                </button>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex shrink v-if="!!currentRoomUsers.length">
            <span
              class="room-user-name"
              v-for="user in currentRoomUsers"
              :key="`room-info-user-names-${user.id}`"
            >
              <UserName :firstname="user.firstname" :lastname="user.lastname" />
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import PreGamePlayer from "@/components/GroupTeams/Common/Player/PreGamePlayer"
import UserName from "@/components/GroupTeams/Common/User/UserName"
import RoomInfo from "@/components/RoomInfo"
import PreGameCountdown from "@/components/PreGameCountdown"
import { Role } from "@/helpers"

const TIMEOUT_DELAY = 15000
export default {
  name: "Profile",
  props: {
    userID: {
      type: String,
      required: true
    },
    clientID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      time: 0,
      loading: false,
      assignedToGame: false,
      subscribedToRoomID: false,
      subscribedToRoom: false
    }
  },
  async created() {
    await Promise.all([
      this.subscribeToUser({ userID: this.userID }),
      this.subscribeToUserRoomID({ userID: this.userID })
    ])
    this.subscribedToRoomID = true
  },
  async beforeDestroy() {},
  components: {
    PreGamePlayer,
    RoomInfo,
    UserName,
    PreGameCountdown
  },
  watch: {
    profileRoomID: {
      async handler(value) {
        if (value) {
          this.subscribedToRoom = false
          await this.subscribeToUserRoom({ roomID: value })
          this.subscribedToRoom = true
        } else {
          await this.purgeRoomData()
          this.subscribedToRoom = false
        }
      },
      immediate: true
    },
    async profileGameID(gameID) {
      if (gameID) {
        await this.fetchGame({ gameID, orgID: this.profileOrgID })
        this.time = Date.now()
      }
    },
    async assignedToGame(game) {
      await this.pushUsersToGame({ users: [this.userID], gameID: game.id })
    }
  },
  computed: {
    ...mapGetters("pregame", ["gamesArray"]),
    ...mapGetters("allusers", ["users", "onlineUsersArray"]),
    ...mapGetters("auth", ["user", "isHost"]),
    ...mapGetters("profile", {
      profileGame: "game",
      profileUser: "user",
      profileRoom: "room",
      profileRoomID: "roomID"
    }),
    ...mapGetters("livechat", ["roomID", "max"]),
    ...mapGetters("twilio", { isTwilioConnected: "isConnected" }),
    profileGameID() {
      return this.profileUser ? this.profileUser.gameID : null
    },
    profileOrgID() {
      return this.profileUser ? this.profileUser.orgID : null
    },
    gameStartTime() {
      return this.profileGame && this.profileGame.startTimestamp
        ? this.profileGame.startTimestamp
        : 0
    },
    isProfileGameTime() {
      return this.gameStartTime < this.time
    },
    isProfileGameStarted() {
      return this.game ? !!this.game.started : false
    },
    isProfileUserBusy() {
      return this.isProfileGameTime || this.isProfileGameStarted
    },
    currentRoomUserIDs() {
      if (!this.profileRoom) return []
      return Object.values(this.profileRoom.users)
        .filter(user => user)
        .filter(({ status, userID }) => {
          return status === "active" && userID !== this.userID && userID
        })
        .map(({ userID }) => userID)
    },
    isProfileRoomFull() {
      return this.currentRoomUserIDs.length + 1 > this.max - 1
    },
    isAloneInRoom() {
      return !this.currentRoomUserIDs.length
    },
    currentRoomUsers() {
      if (!this.users) return []
      return this.currentRoomUserIDs
        .map(userID => this.users[userID])
        .filter(user => user)
    },
    request() {
      if (this.profileRoom && this.isCurrentRoom) {
        return this.profileRoom.users[this.userID]
      } else {
        return null
      }
    },
    isCurrentRoom() {
      return this.profileRoomID === this.roomID && !!this.roomID
    },
    requestStatus() {
      return this.request ? this.request.status : null
    },
    isConnecting() {
      return !this.isTwilioConnected && !!this.roomID
    },
    isWaiting() {
      return !this.requestStatus && this.loading
    },
    isLoading() {
      return this.isWaiting || this.isConnecting
    },
    isCurrentUser() {
      return this.user ? this.user.id === this.userID : false
    },
    isProfileRoomSet() {
      return !!this.profileRoomID && !!this.profileRoom
    },
    isProfileRoomLocked() {
      return this.profileRoom ? !!this.profileRoom.locked : false
    },
    isProfileUserOnboarding() {
      return this.profileUser && !!this.profileUser.onboarding
    },
    isProfileUserBusyWihtTour() {
      return this.profileUser && !!this.profileUser.expotour
    },
    requestButtonStatus() {
      if (
        !this.subscribedToRoomID ||
        (this.profileRoomID && !this.subscribedToRoom) ||
        this.isCurrentUser ||
        this.userStatus !== "online" ||
        this.requestStatus === "active" ||
        this.isCurrentRoom
      ) {
        return "hidden"
      } else if (
        this.isProfileUserOnboarding ||
        !this.isProfileUserBusyWihtTour
      ) {
        return "onboarding"
      } else if (this.isProfileRoomFull) {
        return "full"
      } else if (this.isProfileUserBusy) {
        return "playing"
      } else if (this.isLoading) {
        return "loading"
      } else if (this.isProfileRoomSet && this.isProfileRoomLocked) {
        return "locked"
      } else if (this.isProfileRoomSet && !this.isAloneInRoom) {
        return "join"
      } else {
        return "show"
      }
    },
    userStatus() {
      return this.profileUser ? this.profileUser.status : "offline"
    },
    filteredGamesArray() {
      const games = this.gamesArray.filter(
        obj =>
          obj.id !== this.profileUser.gameID &&
          !obj.deactivate &&
          (!obj.gameType ||
            obj.gameType === "Standard" ||
            obj.gameType === "Broadcast" ||
            obj.gameType === "Green Room") &&
          !obj.endTimestamp &&
          !obj.deletedTimestamp
      )
      if (this.profileUser.gameID) games.push({ id: 0, name: "Unassign" })
      return games
    },
    queueNumber() {
      const queue = this.onlineUsersArray.filter(
        ({ noGameTimestamp, role }) => noGameTimestamp && role !== Role.Host
      )
      if (!queue.length) return -1
      queue.sort((a, b) => a.noGameTimestamp - b.noGameTimestamp)
      return queue.findIndex(({ id }) => id === this.profileUser.id) + 1
    },
    isSpectator() {
      return (
        this.profileUser.role === Role.Audit ||
        this.profileUser.role === Role.Spectator
      )
    }
  },
  methods: {
    ...mapActions("profile", [
      "subscribeToUser",
      "fetchGame",
      "subscribeToUserRoom",
      "subscribeToUserRoomID",
      "purgeRoomData"
    ]),
    ...mapActions("GameUsers", ["pushUsersToGame"]),
    isHostRole(role) {
      return role === Role.Host
    },
    onGameClick() {
      this.$emit("onGameClick", {
        gameID: this.profileUser.gameID,
        scroll: true
      })
    },
    onJoin() {
      this.$emit("onJoin", { roomID: this.profileRoomID })
    },
    onInvite() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, TIMEOUT_DELAY)
      this.$emit("onInvite", { userID: this.userID })
    }
  }
}
</script>

<style lang="scss">
.pre-game-profile {
  line-height: 1;
  user-select: none;
  .profile-speaker-icon {
    color: #fff;
    font-size: 16px;
  }
  .game-starts-in {
    color: $color-white;
    font-size: 12px;
  }
  .room-user-name {
    color: $color-white;
    font-size: 9px;
  }
  .countdown-wrap {
    padding-left: 8px;
  }
  .rtb-box-shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  }
  .profile-user-name {
    font-size: 24px;
    text-transform: uppercase;
    color: $color-primary-light;
  }
  .game-details {
    font-size: 16px;
    .link {
      color: $primary_accent_color;
      text-decoration: underline;
      font-weight: bold;
      cursor: pointer;
      min-height: 30px;
      display: block;
    }
    .queue {
      color: $color-white;
    }
  }
  .online-status {
    @extend .rtb-box-shadow;
    background-color: $color-white;
    color: $color-correct;
    font-size: 11px;
    width: 56px;
    text-align: center;
    font-weight: bold;
    padding: 3px 6px;
    border-radius: 3px;
    &.offline {
      color: $color-wrong;
    }
  }
  .player {
    .outline {
      border-width: 4px;
    }
    .player-details {
      display: none !important;
    }
  }
  .invite-button {
    // line-height: 24px;
    height: 22px;
    background-color: $color-white;
    border-radius: 4px;
    font-weight: bold;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 10px;
    z-index: 50;
    // display: flex;
    // justify-content: center;
    color: $primary_accent_color;
    &.disabled {
      color: $color-disabled;
      cursor: initial;
      pointer-events: none;
      background-color: $color-primary-dark;
    }
    &.locked {
      color: $color-disabled;
      align-content: center;
      .locked-icon {
        display: inline-block;
        margin-right: 3px;
      }
      .locked-text {
        display: inline-block;
        margin-top: 1px;
      }
    }
    .spinner {
      height: 16px !important;
      width: 16px !important;
    }
  }
  .buttons {
    position: relative;
  }
  .move-to-game {
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0px;
    }
    button {
      position: relative;
      width: 100%;
      height: 22px;
      padding: 5px 0;
      background: $primary_accent_color;
      text-transform: uppercase;
      color: $color-white;
      font-weight: bold;
      font-size: 10px;
      border-radius: 4px;
    }
    .v-select {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      padding: 0;
      overflow: hidden;
    }
  }

  &__media-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 4px;
    width: 36px;
    height: 36px;
    fill: #fff;
  }
}
</style>
