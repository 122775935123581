<template>
  <v-flex d-flex class="game-info" ref="gameinfo" :class="status">
    <v-layout column>
      <v-flex class="game-card-header" shrink>
        <v-layout column>
          <v-flex d-flex class="game-title">
            <v-layout row>
              <v-flex d-flex class="join-game" v-if="canShowBigButton">
                <template v-if="status === 'available'">
                  <button
                    @click.stop="onJoinGame"
                    :class="loading ? 'loading' : ''"
                  >
                    <template v-if="loading">
                      <v-progress-circular indeterminate class="spinner" />
                    </template>
                    <template v-else> JOIN<br />GAME </template>
                  </button>
                </template>

                <template
                  v-if="!isHost && !isGameCompletelyFull && isGameSpectable"
                >
                  <button
                    @click.stop="onJoinGame({ audit: true })"
                    class="spectate-button"
                    :class="loading ? 'loading' : ''"
                  >
                    <template v-if="loading">
                      <v-progress-circular indeterminate class="spinner" />
                    </template>
                    <template v-else>
                      SPECTATE<br />
                      GAME
                    </template>
                  </button>
                </template>

                <template v-else-if="status === 'full'">
                  <button class="disabled full">GAME IS<br />FULL</button>
                </template>
              </v-flex>
              <v-flex shrink class="host-icon-wrap">
                <div
                  class="host-icon-big"
                  :class="{ disabled: isDefaultHostImage }"
                >
                  <div v-if="!!host" class="text-xs-center host-name">
                    {{ host.firstname }}
                  </div>
                  <video
                    :src="announcementVideo"
                    @ended="videoEnded"
                    ref="video"
                    class="reception-client-image video-an"
                    :class="{
                      flipped: announcementVideo && announcementVideo.flipped
                    }"
                  />
                  <transition name="fade-in-transition-yes" mode="out-in">
                    <div
                      class="pending-host-image"
                      v-if="isDefaultHostImage"
                      v-show="!playAnnouncement"
                      :style="{
                        'background-image': `url(${require('../assets/game-card-profile-icon.png')})`
                      }"
                    />
                    <div
                      v-else
                      v-show="!playAnnouncement"
                      class="host-image"
                      :style="{ 'background-image': `url(${host.image})` }"
                    />
                  </transition>
                </div>
                <a
                  v-if="announcementVideo || isHost"
                  href="#"
                  class="btn-game-intro"
                  @click="onGameIntro"
                >
                  <svg-icon name="button-play" class="rtb-color-secondary" />
                  Game Intro
                </a>
              </v-flex>
              <v-flex d-flex class="game-title-name">
                <v-layout row justify-center>
                  <v-flex>
                    <v-flex v-once shrink class="game-name" :class="{}">
                      <h1>{{ gameName }}</h1>
                      <div
                        class="cancel-game"
                        v-if="isHost"
                        @mouseleave="isOverCancel = false"
                        @mouseover="isOverCancel = true"
                      >
                        <button
                          class="cancel-button"
                          @click="toggleCancelOptions"
                        >
                          cancel game
                        </button>
                        <div class="cancel-options" ref="cancel">
                          <div
                            class="cancel-option"
                            @click.self="deleteGameAttempt"
                          >
                            Delete
                          </div>
                          <div
                            class="cancel-option"
                            @click.self="deactivateGame"
                          >
                            Deactivate
                          </div>
                          <div class="cancel-option">
                            Merge Into
                            <v-select
                              :items="filteredGamesArray"
                              v-model="mergeToGameSelection"
                              label="Play Type"
                              required
                            >
                              <template slot="item" slot-scope="data">
                                <div>
                                  {{ data.item.externalName || data.item.name }}
                                  <b
                                    >{{ data.item.total }}/{{
                                      data.item.max
                                    }}</b
                                  >
                                </div>
                              </template>
                            </v-select>
                          </div>
                        </div>
                      </div>
                    </v-flex>
                    <v-layout row shrink class="actual-start-time">
                      <v-flex>
                        <v-layout row>
                          <v-flex
                            d-flex
                            align-center
                            v-if="!!startTime"
                            class="start-time"
                          >
                            {{ startTime }}
                          </v-flex>
                          <v-flex
                            d-flex
                            class="leave-game"
                            v-if="status === 'current' || status === 'booked'"
                          >
                            <v-layout row>
                              <v-flex
                                d-flex
                                xs5
                                lg3
                                v-if="canShowEnterGameButton"
                              >
                                <button
                                  @click.stop="onEnterGame"
                                  :class="loading ? 'loading' : ''"
                                >
                                  <template v-if="loading">
                                    <v-progress-circular
                                      indeterminate
                                      class="spinner"
                                    />
                                  </template>
                                  <template v-else> ENTER GAME </template>
                                </button>
                              </v-flex>
                              <v-flex d-flex xs5 lg3>
                                <button
                                  v-if="canLeaveGame"
                                  @click.stop="onLeaveGame"
                                  :class="loading ? 'loading' : ''"
                                  class="leave"
                                >
                                  <template v-if="loading">
                                    <v-progress-circular
                                      indeterminate
                                      class="spinner"
                                    />
                                  </template>
                                  <template v-else> LEAVE THIS GAME </template>
                                </button>
                                <button
                                  v-else
                                  :class="loading ? 'loading' : ''"
                                  class="leave"
                                  @click.stop="onLeaveGame"
                                >
                                  <template v-if="loading">
                                    <v-progress-circular
                                      indeterminate
                                      class="spinner"
                                    />
                                  </template>
                                  <template v-else>
                                    <span v-if="isAudit || isSpectator">
                                      STOP SPECTATING THIS GAME
                                    </span>
                                    <span v-else> YOU'RE ON THIS GAME </span>
                                  </template>
                                </button>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex d-flex shrink class="timer">
                        <v-flex
                          d-flex
                          align-center
                          v-if="!!isGameEnded"
                          class="label ended"
                          >Game Ended</v-flex
                        >
                        <v-flex
                          d-flex
                          align-center
                          v-else-if="isBoarding"
                          class="label started"
                          >Boarding</v-flex
                        >
                        <v-flex
                          d-flex
                          align-center
                          v-else-if="!!isGameTime"
                          class="label started"
                          >In Progress</v-flex
                        >
                        <v-layout v-else>
                          <v-flex
                            d-flex
                            shrink
                            align-center
                            text-xs-center
                            class="label"
                            >Starts in</v-flex
                          >
                          <v-flex
                            d-flex
                            align-center
                            shrink
                            text-xs-center
                            class="value"
                          >
                            <PreGameCountdown :endTime="gameStartTime" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex d-flex class="game-card-body" :style="cardBodyStyles">
        <v-layout column class="game-card-layout">
          <v-flex shrink>
            <v-layout row justify-space-between>
              <v-flex shrink class="content">
                <v-layout column>
                  <v-flex
                    shrink
                    v-if="!!game.description"
                    class="game-card-body-row description border"
                  >
                    {{ game.description }}
                  </v-flex>
                  <mission-types
                    v-if="missionTypes.length !== 0"
                    :items="missionTypes"
                    class="game-info__mission-types"
                  ></mission-types>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-layout class="game-card-body-row players-title-row">
            <v-flex shrink>
              <div style="display: inline-block" class="game-detail">
                Players
                <span class="active-players">{{ nOfPlayersOnline }}</span> of
                <span class="max-players">{{ nOfPlayersMax }}</span>
              </div>
            </v-flex>
            <v-flex
              v-if="
                !isAudit &&
                !isHost &&
                isGameSpectable &&
                isGameBooked &&
                !isGameTime &&
                (!game.tournamentID || game.assignable)
              "
              shrink
              d-flex
              align-center
              class="custom-switch"
            >
              <label :class="{ 'active-control': !isSpectator }"> PLAY </label>
              <v-switch v-model="isSpectator" />
              <label :class="{ 'active-control': isSpectator }">
                SPECTATE
              </label>
            </v-flex>
          </v-layout>
          <v-flex d-flex class="game-info-user-list" style="position: relative">
            <UserList
              :users="usersOnlineToDisplay"
              :selectedUserID="selectedUserID"
              @onSelect="onSelectUser"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { Role, Time, UserSorter } from "@/helpers"
import { fetchUser } from "@/services/user.service"
import UserList from "@/components/UserList"
import MissionTypes from "@/components/MissionTypes.vue"
import PreGameCountdown from "@/components/PreGameCountdown"
import SvgIcon from "@/components/base/SvgIcon.vue"
import { canUserJoinGame, fetchGameObject } from "@/services/game.service"
import { MAX_PEOPLE_IN_GAME } from "@/config"

export default {
  name: "GameInfo",
  components: {
    PreGameCountdown,
    UserList,
    MissionTypes,
    SvgIcon
  },
  props: {
    game: {
      type: Object,
      required: true
    },
    isPlayedByUser: Boolean,
    announcementVideo: String,
    selectedUserID: String,
    isTournamentWinner: Boolean
  },
  data() {
    return {
      timeout: null,
      playAnnouncement: false,
      assignedHost: null,
      loading: false,
      time: 0,
      startTime: null,
      activeTab: null,
      isGameTime: false,
      mergeToGameSelection: false,
      isOverCancel: false,
      gameObject: null
    }
  },
  created() {
    // cache to not trigger recalcutaion every time `globalTime` changes
    this.isGameTime =
      !!this.gameStartTime && this.$store.getters.time > this.gameStartTime
    if (this.gameStartTime) {
      const { startTime } = Time.getCountdownTimes(
        this.gameStartTime,
        Date.now()
      )
      this.startTime = startTime
    }

    if (this.game.hostUserID) this.fetchGameHost()
    this.fetchGameObject()

    // throw and Error when game.name is empty
    if (typeof this.game.name !== "string" || this.game.name === "") {
      throw new Error(
        "game.name is not typeof 'string' or equeals to empty string"
      )
    }
  },
  mounted() {
    document.addEventListener("click", this.globalClick)
  },
  beforeDestroy() {
    document.removeEventListener("click", this.globalClick)
  },
  methods: {
    ...mapActions("GameUsers", ["pushUsersToGame"]),
    ...mapActions("Games", ["updateGameCapacity"]),
    async fetchGameObject() {
      this.gameObject = await fetchGameObject({
        gameID: this.game.theKey,
        clientID: this.clientID,
        orgID: this.orgID
      })
    },
    async fetchGameHost() {
      this.assignedHost = await fetchUser({ userID: this.game.hostUserID })
    },
    onGameIntro() {
      if (this.isHost) {
        this.$emit("onGameIntro", this.game.id)
      } else {
        this.playVideo()
      }
    },
    playVideo() {
      if (this.announcementVideo) {
        this.playAnnouncement = true
        this.$refs.video.currentTime = 0
        this.$refs.video.play()
      }
    },
    videoEnded() {
      this.playAnnouncement = false
    },
    onUserSelect({ userID }) {
      this.$emit("onUserSelect", { userID })
    },
    async updateUsers({ clientID }) {
      await this.$store.dispatch("allusers/fetchUsers", { clientID })
    },
    async onJoinGame({ audit }) {
      if (this.loading) return
      this.loading = true
      if (audit && this.isPlayer) {
        await this.$store.dispatch("updateUser", {
          userID: this.user.id,
          obj: { role: Role.Spectator, teamID: 0 }
        })
      } else if (this.isSpectator) {
        const { id: userID, role } = this.user
        const gameID = this.game.id
        const orgID = this.orgID
        const message = await canUserJoinGame({ role, userID, gameID, orgID })
        if (message) throw new Error(message)
        await this.$store.dispatch("updateUser", {
          userID: this.user.id,
          obj: { role: Role.Player }
        })
      }
      if (this.isGameTime) {
        this.$emit("onJoinGame", { gameID: this.game.id, _this: this })
      } else {
        this.$emit("onBookGame", { gameID: this.game.id, _this: this })
      }
    },
    async mergeToGame(game) {
      this.closeCancelOptions()
      await this.updateUsers({ clientID: game.clientID })
      const gameUsers = this.onlineUsersArray.filter(
        ({ gameID }) => gameID === this.game.id
      )
      const targetGamePlayers = this.onlineUsersArray.filter(
        ({ gameID, role }) => gameID === game.id && role === Role.Player
      )
      const targetGameCapacity = parseInt(game.players) || 999999
      const capacityRequired = targetGamePlayers.length + gameUsers.length
      if (targetGameCapacity < capacityRequired) {
        await this.updateGameCapacity({
          gameID: game.id,
          capacity: capacityRequired
        })
      }
      await this.pushUsersToGame({
        users: gameUsers.map(({ id }) => id),
        gameID: game.id
      })
      await this.$store.dispatch("Games/updateGame", {
        ...this.game,
        theKey: this.game.id,
        deactivate: true
      })
    },
    onLeaveGame() {
      if (this.loading) return
      this.loading = true
      this.$emit("onLeaveGame", { _this: this })
    },
    onEnterGame() {
      if (this.loading) return
      this.loading = true
      this.$emit("onEnterGame", { gameID: this.game.id, _this: this })
    },
    onSelectUser(obj) {
      this.$emit("onSelect", obj)
    },
    deleteGameAttempt() {
      this.closeCancelOptions()
      if (!confirm("Are you sure you want to delete this game?")) return
      this.deleteGame()
    },
    async deactivateGame() {
      this.closeCancelOptions()
      const gameUsers = this.onlineUsersArray.filter(
        ({ gameID, role }) => gameID === this.game.id && role !== Role.Host
      )
      await this.pushUsersToGame({
        users: gameUsers.map(({ id }) => id),
        gameID: 0
      })
      await this.$store.dispatch("Games/updateGame", {
        ...this.game,
        theKey: this.game.id,
        deactivate: true
      })
    },
    async deleteGame() {
      const gameUsers = this.onlineUsersArray.filter(
        ({ gameID, role }) => gameID === this.game.id && role !== Role.Host
      )
      await this.pushUsersToGame({
        users: gameUsers.map(({ id }) => id),
        gameID: 0
      })
      await this.$store.dispatch("Games/updateGame", {
        ...this.game,
        theKey: this.game.id,
        deletedTimestamp: Date.now()
      })
    },
    toggleCancelOptions() {
      if (this.$refs.cancel) this.$refs.cancel.classList.toggle("show")
    },
    globalClick() {
      if (!this.isOverCancel) this.closeCancelOptions()
    },
    closeCancelOptions() {
      if (this.$refs.cancel) this.$refs.cancel.classList.remove("show")
    }
  },
  watch: {
    gameStartTime(value) {
      this.isGameTime = this.$store.getters.time > value && value
    },
    globalTime(val) {
      const value = !!this.gameStartTime && val > this.gameStartTime
      if (this.isGameTime != value) this.isGameTime = value
    },
    mergeToGameSelection(game) {
      if (!game) return console.warn("Undefined game")
      this.mergeToGame(game)
      this.mergeToGameSelection = false
    }
  },
  computed: {
    ...mapGetters({ globalTime: "time", orgID: "orgID" }),
    ...mapGetters("auth", [
      "clientID",
      "user",
      "isHost",
      "isAudit",
      "isPlayer"
    ]),
    ...mapGetters("pregame", ["access", "gamesArray"]),
    ...mapGetters("allusers", [
      "usersArray",
      "onlineUsersArray",
      "onlineUsersGroupedByGameID"
    ]),
    canLeaveGame() {
      if (!this.isHost && this.game.tournamentID) return false
      return (
        this.access === 0 ||
        ((this.isAudit || this.isSpectator) && !!this.isGameTime)
      )
    },
    canShowEnterGameButton() {
      return (
        this.isHost ||
        (this.access === 0 && !!this.isGameTime) ||
        ((this.isAudit || this.isSpectator) && !!this.isGameTime)
      )
    },
    canShowBigButton() {
      return (
        (this.status === "available" ||
          this.status === "spectable" ||
          this.status === "full") &&
        this.access < 2
      )
    },
    gameName() {
      return this.game.externalName || this.game.name
    },
    isBoarding() {
      return this.isGameTime && !this.game.started
    },
    isGameBooked() {
      return this.user.gameID === this.game.id
    },
    gameEndTime() {
      return this.game.endTimestamp || 0
    },
    gameStartTime() {
      return this.game.startTimestamp || 0
    },
    isGameSpectable() {
      return !!this.game.auditable
    },
    isGameFull() {
      return this.nOfPlayersOnline >= this.nOfPlayersMax && this.nOfPlayersMax
    },
    nOfGameUsers() {
      return this.nOfPlayersOnline /* + this.nOfSpectatorsOnline*/
    },
    isGameCompletelyFull() {
      return this.nOfUsersMax <= this.nOfGameUsers
    },
    isGameEnded() {
      return !!this.gameEndTime
    },
    isGameStarted() {
      return !!this.gameStartTime
    },
    status() {
      if (this.isHost) {
        if (this.isGameBooked) {
          return "current"
        } else {
          return "available"
        }
      } else if (!this.isGameEnded) {
        if (this.isGameCompletelyFull && !this.isGameBooked) {
          return "full"
        } else if (
          this.isGameFull &&
          !this.isGameBooked &&
          this.isGameSpectable &&
          !this.isTournamentWinner
        ) {
          return "spectable"
        } else if (this.isGameBooked && !this.isGameTime) {
          return "booked"
        } else if (this.isGameBooked) {
          return "current"
        } else if (
          !this.isPlayedByUser &&
          !this.isTournamentWinner &&
          (!this.game.tournamentID || this.game.assignable)
        ) {
          return "available"
        } else if (
          !this.isGameCompletelyFull &&
          this.isGameSpectable &&
          !this.isTournamentWinner
        ) {
          return "spectable"
        } else {
          return null
        }
      } else {
        return null
      }
    },
    missionTypes() {
      if (!this.gameObject) return []
      const missions = this.gameObject.missions
      if (!missions) return []
      const map = {}
      for (let i in missions) {
        const behavior = missions[i].behavior
        if (behavior == "Draw: Pictionary") {
          map["Pictionary"] = true
        } else if (behavior == "Lipdub") {
          map["Lipdub"] = true
        } else if (behavior === "Multiple Choice") {
          map["Trivia"] = true
        } else if (behavior == "Player Rating") {
          map["Face Off"] = true
        } else if (behavior == "Draw: Each Team") {
          map["Creative Drawing"] = true
        } else if (behavior == "Categories") {
          map["Categories"] = true
        } else if (behavior == "Fact Match") {
          map["Fun Fact Match"] = true
        }
      }
      const array = Object.keys(map)
      return array.lenght > 6 ? array.splice(0, 6) : array
    },
    isDefaultHostImage() {
      return !(this.host && this.host.image)
    },
    usersOnline() {
      const array = this.onlineUsersGroupedByGameID[this.game.id]
      return Array.isArray(array) ? array : []
    },
    usersOnlineToDisplay() {
      return this.playersOnline
        .concat(this.spectatorsOnline)
        .sort(UserSorter.watchersLast)
    },
    playersOnline() {
      return this.usersOnline.filter(({ role }) => role === Role.Player)
    },
    spectatorsOnline() {
      return this.usersOnline.filter(({ role }) => role === Role.Spectator)
    },
    nOfPlayersOnline() {
      return this.playersOnline.length
    },
    nOfSpectatorsOnline() {
      return this.spectatorsOnline.length
    },
    actualHost() {
      return this.usersOnline.find(({ role }) => role === Role.Host)
    },
    host() {
      return this.actualHost || this.assignedHost
    },
    cardBodyStyles() {
      return this.game.image
        ? { "background-image": `url("${this.game.image}")` }
        : {}
    },
    nOfUsersMax() {
      return this.game.auditable
        ? this.nOfPlayersMax + this.nOfSpectatorsMax
        : this.nOfPlayersMax
    },
    nOfPlayersMax() {
      return parseInt(this.game.players) || 0
    },
    nOfSpectatorsMax() {
      return MAX_PEOPLE_IN_GAME - this.nOfPlayersMax
    },
    isSpectator: {
      get() {
        return this.user.role === Role.Spectator
      },
      async set(value) {
        await this.$store.dispatch("updateUser", {
          userID: this.user.id,
          obj: { role: value ? Role.Spectator : Role.Player }
        })
        await this.updateUsers({ clientID: this.game.clientID })
      }
    },
    filteredGamesArray() {
      return this.gamesArray
        .filter(
          ({
            id: gameID,
            runStatus,
            deactivate,
            gameType,
            ondeck,
            endTimestamp
          }) =>
            gameID !== this.game.id &&
            runStatus != "Deleted" &&
            !endTimestamp &&
            !ondeck &&
            !deactivate &&
            (!gameType || gameType === "Standard")
        )
        .map(game => {
          const users = this.onlineUsersGroupedByGameID[game.id] || []
          const players = users.filter(({ role }) => role === Role.Player)
          return {
            ...game,
            total: players.length,
            max: game.players || 0
          }
        })
    }
  }
}
</script>

<style lang="scss">
.game-info {
  line-height: 1;
  background-color: $color-white;
  box-shadow: 0px 0px 8px 0px rgba($color-black, 0.5);
  .game-detail {
    font-size: 13px;
    line-height: 1;
    padding: 5px 10px;
    background-color: #fff;
    color: #000 !important;
    .active-players {
      color: $primary_accent_color;
    }
    .active-players,
    .max-players {
      font-weight: bold;
    }
  }
  .pre-game-user-list {
    margin-left: -6px;
    margin-right: -6px;
    max-width: unset;
    .user .outline {
      border-color: $color-white !important;
      &:before {
        border-color: $color-white !important;
      }
    }
  }

  .pre-game-user-list .user--role--audit,
  .pre-game-user-list .user--role--spectator {
    .video-container.outline {
      border-color: $color-grey !important;
      &:before {
        border-color: $color-grey !important;
      }
    }
  }

  .joined {
    position: relative;
    flex: none;
    width: 60px;
    text-align: center;
    background: $color-grey;
    label {
      position: absolute;
      left: 0;
      top: 0;
      line-height: 60px;
      width: 100%;
      height: 100%;
      color: $color-white;
      background-color: rgba($color-black, 0.5);
      font-size: 13px;
      z-index: 1;
    }
    div {
      height: 100%;
      background-size: cover;
    }
  }
  .game-info-user-list {
    margin: 0px 20px;
  }
  .pre-game-user-list .all-users-user {
    box-sizing: border-box;
    width: 10%;
    .user {
      min-width: unset;
      width: 100%;
    }
  }
  .game-card-body {
    /*color: #fff;*/
    // font-family: "Helvetica Neue";
    position: relative;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    background-color: $color-grey;
    background-size: cover;
    background-position: center;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($color-black, 0.5);
    }
    .game-card-layout {
      position: relative;
    }
  }
  .host-image {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .game-in-progress-text,
  .get-ready-text {
    font-size: 18px;
    padding: 4px 8px;
    color: $color-correct;
    font-weight: bold;
  }
  .game-in-progress-text {
    color: $primary_accent_color;
  }
  .game-locked-tag > div {
    padding: 3px 5px;
    padding-bottom: 1px;
    background-color: $color-correct;
    color: #000;
    border-radius: 4px 0 0 0;
  }
  .game-card-btn {
    line-height: 1;
    background-color: $primary_accent_color;
    border-radius: 4px;
    padding: 0 5px;
    height: 30px;
    width: 200px;
    margin-top: 10px;
    margin-bottom: 18px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: $color-white;
    &:not(:first-child) {
      margin-left: 3px;
    }
    &.loading {
      pointer-events: none;
      cursor: initial;
    }
    &.disabled {
      pointer-events: none;
      background-color: $color-secondary-dark;
      color: $color-disabled;
      cursor: initial;
      box-shadow: none;
    }
  }
  .game-card-user-link {
    text-decoration: underline;
    cursor: pointer;
    margin-right: 2px;
  }
  .host-icon-wrap {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 15px;
    .host-name {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      font-size: 10px;
      color: white;
      background: rgba($color-black, 0.5);
      min-height: 15px;
      line-height: 15px;
      z-index: 2;
    }
    a.btn-game-intro {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      z-index: 500;
      height: 34px;
      line-height: 34px;
      color: $color-white;
      text-decoration: none;
      transition: all 0.1s ease-out;
      &:hover {
        color: $secondary_accent_color;
        filter: brightness(1.1);
      }
      svg {
        width: 22px;
        height: 22px;
        margin-top: -2px;
        margin-right: 2px;
      }
    }
  }
  .host-icon-big {
    @extend .rtb-box-shadow;
    display: flex;
    overflow: hidden;
    width: 120px !important;
    height: 120px !important;
    background-color: $color-disabled;
    border-radius: 5px;
    position: relative;
    video {
      height: 100%;
      width: 100%;
    }
  }
  .pending-host-image {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: $color-grey;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .game-card-countdown {
    font-weight: bold;
  }
  .game-card-body-row {
    color: $color-white;
    margin: 15px 20px;
    &.players-title-row {
      max-height: 27px;
      border-bottom: solid 1px $color-white;
    }
    &.description {
      padding-bottom: 10px;
      font-size: 14px;
      line-height: 16px;
      margin: 15px 160px 0 20px;
    }
    &.border {
      border-bottom: 1px solid $color-disabled;
    }
  }
  .game-card-header {
    position: relative;
    min-height: 40px;
    background-color: $color-white;
    & > .column {
      position: relative;
      z-index: 1;
    }
    .game-hint {
      position: relative;
      background-color: $color-yellow2;
      font-size: 18px;
    }
    .game-title {
      height: 60px;
      background-color: $color-white;
      .join-game {
        height: 100%;
        flex: none;
        min-width: 80px;
        button {
          color: $color-white;
          background-color: $color-correct;
          font-size: 19px;
          line-height: 20px;
          outline: none;
          padding: 0 10px;
          margin-right: 5px;
          font-weight: bold;
          &:hover {
            filter: brightness(1.1);
          }
          &.disabled {
            background-color: $color-grey;
            cursor: default;
            &:hover {
              background-color: $color-grey;
            }
          }
          &.full {
            font-size: 15px;
            line-height: 16px;
          }
        }
      }
      .leave-game {
        button {
          line-height: 20px;
          color: $color-white;
          background-color: $color-correct;
          font-size: 13px;
          height: 20px;
          outline: none;
          padding: 0 10px;
          margin-right: 5px;
          border-radius: 2px;
          white-space: nowrap;
          font-weight: normal;
          &:hover {
            filter: brightness(1.1);
          }
          &.leave {
            background-color: $primary_accent_color;
            &:hover {
              background-color: $primary_accent_color;
            }
          }
        }
      }
      .game-title-name {
        position: relative;
        display: block;
        background-color: $color-white;
        padding: 10px 12px;
        padding-right: 145px;
        overflow: visible;
        .actual-start-time {
          font-size: 14px;
          font-weight: bold;
          padding-top: 2px;
          .start-time {
            flex: none;
            padding-right: 12px;
          }
        }
        .game-name {
          display: flex;
          position: relative;
          h1 {
            font-size: 20px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            padding-bottom: 3px;
          }
          .cancel-game {
            position: relative;
            margin-left: auto;
            flex-shrink: 0;
            button {
              position: relative;
              width: 100%;
              padding: 0 10px;
              background: $color-black;
              text-transform: uppercase;
              color: $color-white;
              font-weight: bold;
              font-size: 10px;
              border-radius: 2px;
              line-height: 20px;
              z-index: 1;
              transition: all 0.1s ease-out;
              outline: none;
            }
            .cancel-options {
              display: none;
              position: absolute;
              left: 0;
              top: 100%;
              width: 100%;
              background: $color-white;
              border: solid 1px $color-grey;
              &.show {
                display: block;
              }
              .cancel-option {
                position: relative;
                padding: 0 10px;
                font-weight: bold;
                font-size: 10px;
                line-height: 20px;
                text-transform: uppercase;
                background: $color-grey-light;
                cursor: pointer;
                &:hover {
                  color: $primary_accent_color;
                }
              }
            }
            .v-select {
              position: absolute;
              left: 0;
              top: 0;
              margin: 0;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              padding: 0;
              overflow: hidden;
            }
          }
        }
        .timer {
          padding-left: 10px;
          .label {
            font-size: 14px;
            color: $color-black;
            padding-right: 5px;
            &.ended {
              color: $primary_accent_color;
            }
            &.started {
              color: $color-correct;
            }
          }
          .value {
            font-size: 20px;
            line-height: 14px;
            font-weight: bold;
            color: $secondary_accent_color;
          }
        }
      }
    }
  }
  .spinner {
    margin-top: -4px;
    margin-bottom: -4px;
    height: 14px !important;
    width: 14px !important;
  }
  .custom-switch {
    padding-left: 10px;
    .v-input--switch {
      margin: 0 3px;
      padding: 0;
      .v-messages {
        display: none;
      }
      .v-input__slot {
        margin-bottom: 0;
      }
      .v-input--selection-controls__input {
        width: 28px;
        margin-right: 0;
      }
      .v-input--selection-controls__ripple {
        color: $color-yellow-dark !important;
        caret-color: $color-yellow-dark !important;
      }
      .v-input--switch__track {
        height: 10px;
        top: calc(50% - 5px);
        color: $color-yellow-dark !important;
        overflow: hidden;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba($color-black, 0.33);
        }
      }
      .v-input--switch__thumb {
        height: 16px;
        width: 16px;
        top: calc(50% - 8px);
        color: $color-yellow-dark !important;
        caret-color: $color-yellow-dark !important;
        box-shadow: none !important;
      }
      &.v-input--is-dirty .v-input--switch__thumb {
        transform: translate(12px, 0);
      }
    }
    label {
      /*font-family: "ITCAvantGardeStd";*/
      color: $color-white;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: bold;
    }
    label.active-control {
      color: $color-orange;
    }
  }
  .user-icon-wrap {
    position: absolute;
    width: 58px;
  }
  .spectate-button {
    background-color: $secondary_accent_color !important;
  }
  .game-info-username {
    margin-left: 46px;
    .username {
      text-transform: uppercase;
      color: $color-orange;
      white-space: nowrap;
      font-size: 16px;
      font-weight: bold;
      margin-right: 10px;
    }
    .hint {
      font-style: italic;
      color: #2d2b2a;
      padding: 9px 0;
      font-size: 15px;
      line-height: 16px;
    }
  }
  .user-search-user-name {
    line-height: 1;
  }
  .video-an {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
  .fade-in-transition-yes-enter-active,
  .fade-in-transition-yes-leave-active {
    transition: opacity ease 0.3s;
    opacity: 1;
  }
  .fade-in-transition-yes-enter {
    opacity: 0;
  }
  .fade-in-transition-yes-leave-to {
    opacity: 0;
  }

  &__mission-types {
    height: 24px;
    margin: 16px 160px 0 20px;
    overflow: hidden;
  }
}
</style>
