<template>
  <section class="tournament-team" :class="team.winner ? 'winner' : null">
    <header class="tournament-team__header">
      <svg-icon v-if="!!team.winner" name="crown" class="crown" />
      <v-icon class="tournament-team__icon">
        {{ team.icon || "radio_button_unchecked" }}
      </v-icon>
      <span class="tournament-team__name">{{ team.name }}</span>
      <span class="mx-2 tournament-team__divider"></span>
      <span class="tournament-team__pts"
        >{{ team.score | round }}
        <span class="tournament-team__pts-text">PTS</span></span
      >
    </header>
    <div class="tournament-team__body">
      <ul class="tournament-team__users flex d-flex align-center">
        <li
          v-for="user in users"
          :key="`tournament-user-standing-${user.id}`"
          class="tournament-team__user"
        >
          <TournamentUser
            :imageUrl="user.imageUrl"
            :name="user.name"
            @mouseleave="onMouseLeave"
            @mouseover="$event => onMouseOver({ $event, userID: user.id })"
          />
        </li>
      </ul>
      <v-layout class="tournament-team__dots">
        <v-flex class="dot"></v-flex>
        <v-flex class="dot"></v-flex>
        <v-flex class="dot"></v-flex>
        <v-flex class="dot"></v-flex>
        <v-flex class="dot"></v-flex>
        <v-flex class="dot"></v-flex>
      </v-layout>
    </div>
  </section>
</template>

<script>
import Vue from "vue"
import TournamentUser from "./TournamentUser.vue"

import { Role } from "@/helpers"

export default Vue.extend({
  name: "TournamentTeam",
  components: {
    TournamentUser
  },
  props: {
    team: {
      /** @type { import("vue").PropType<Team> } */
      type: Object,
      required: true
    }
  },
  filters: {
    round(value) {
      return Math.round(value)
    }
  },
  computed: {
    users() {
      return Object.entries(this.team.users || {})
        .filter(entry => entry[1].role !== Role.Host)
        .map(([userID, user]) => ({ ...user, id: userID }))
    }
  },
  methods: {
    onMouseLeave() {
      this.$emit("onMouseLeave")
    },
    onMouseOver({ $event, userID }) {
      if (userID) this.$emit("onMouseOver", { $event, userID })
    }
  }
})
</script>

<style lang="scss">
.tournament-team {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 615px;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:after {
    content: "";
    display: block;
    width: calc(100% - 50px);
    height: 3px;
    position: absolute;
    top: 50%;
    left: 0;
    background-color: #fff;
    transform: translateY(-50%);
  }
  &:before {
    content: "";
    display: block;
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
  }
  &:first-child:before {
    content: "";
    display: block;
    width: 3px;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    background-color: #fff;
  }
  &:last-child:before {
    content: "";
    display: block;
    width: 3px;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
  }

  &__icon {
    color: $primary_accent_color !important;
    background-color: #fff;
    overflow: hidden;
    border-radius: 50%;
    padding: 3px;
    font-size: 14px;
    margin-right: 6px;
  }

  &__divider {
    width: 1px;
    height: 11px;
    background-color: #a29d9b;
  }

  &__header {
    position: absolute;
    display: flex;
    top: -5px;
    left: 15px;
    align-items: center;
    height: 30px;

    .crown {
      position: absolute;
      width: 21px;
      height: 22px;
      top: -10px;
      left: -5px;
      fill: #f2d222;
      transform: rotate(-18deg);
    }
  }

  &__name {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: $color-white;
  }

  &__pts {
    font: {
      size: 16px;
      weight: 700;
      style: italic;
    }
    color: $primary_accent_color;
  }

  &__pts-text {
    font: {
      size: 12px;
      weight: 700;
      style: italic;
    }
    color: $color-white;
  }

  &__body {
    position: relative;
  }

  &__users {
    padding: 0;
    position: relative;
    display: flex;
    z-index: 3;
    height: 112px;
    overflow: hidden;
    list-style: none;
  }

  &__user {
    max-width: 100px;
    min-width: 100px;
    padding-top: 18px;
    padding-bottom: 18px;
    flex-shrink: 0;
    text-align: center;
  }

  &__dots {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100%;
    height: 100%;

    .dot {
      position: relative;
      height: 100%;
      width: 100px;
      max-width: 100px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $color-white;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
