<template>
  <v-flex d-flex class="game-search">
    <v-layout
      column
      class="scrollable"
      ref="scrollable"
      v-on:scroll.passive="onScroll"
      v-show="!!truncatedGames.length"
    >
      <v-flex
        d-flex
        shrink
        v-for="game in truncatedGames"
        :key="`game-card-${game.id}-${game.startTimestamp}`"
      >
        <GameCard
          :ref="`game_card_${game.id}`"
          class="pre-game-card"
          :game="game"
          :selected="selectedGameID"
          :isPlayedByUser="isGamePlayedByUser(game)"
          @addTimeToGame="addTime"
          @removeTimeToGame="removeTime"
          @onSelect="onGameCardSelectEvent"
        />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import GameCard from "@/components/GameCard"

export default {
  name: "GameSearch",
  components: {
    GameCard
  },
  props: {
    games: {
      type: Array,
      required: true
    },
    isGamePlayedByUser: {
      type: Function
    },
    selectedGameID: {
      type: String
    }
  },
  data() {
    return {
      step: 6,
      count: 1
    }
  },
  beforeDestroy() {
    clearInterval(this.mouseOverTimeout)
  },
  computed: {
    max() {
      return this.count * this.step
    },
    truncatedGames() {
      return this.games.length > this.max
        ? this.games.slice(0, this.max)
        : this.games
    }
  },
  methods: {
    increment() {
      this.count = this.count + 1
    },
    onScroll() {
      const scrollable = this.$refs.scrollable
      const sum = scrollable.offsetHeight + scrollable.scrollTop
      const OFFSET = 10
      const height = scrollable.scrollHeight - OFFSET
      const hasMoreUsersToShow = this.truncatedGames.length < this.games.length
      const isBottom = sum >= height
      if (isBottom && hasMoreUsersToShow) this.increment()
    },
    addTime(params) {
      this.$emit("addTime", params)
    },
    removeTime(params) {
      this.$emit("removeTime", params)
    },
    onGameCardSelectEvent(params) {
      this.$emit("onGameCardSelectEvent", params)
    }
  }
}
</script>

<style lang="scss">
.game-search {
  .scrollable {
    direction: rtl;
    overflow: auto;
    padding-top: 10px;
    margin-right: 10px;
    padding-left: 10px;
    & > div {
      position: relative;
      direction: ltr;
    }
  }
}
</style>
