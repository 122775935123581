var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "flash-game-info", attrs: { "d-flex": "" } },
    [
      !!_vm.roomCurrentMission && !!_vm.roomID
        ? _c(
            "v-flex",
            { attrs: { "d-flex": "", "text-xs-center": "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { "d-flex": "" } },
                    [_vm.currentMission ? _c("PlayMode") : _vm._e()],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { "d-flex": "", shrink: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        "d-flex": "",
                                        "align-center": "",
                                        "mr-2": ""
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "ice-icon",
                                        attrs: {
                                          src: require("../assets/ice-icon.svg")
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "bottom-trivia-text",
                                      attrs: {
                                        "d-flex": "",
                                        "align-center": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass:
                                            "ice-breakers-trivia-text"
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "ice-breakers-text",
                                              attrs: {
                                                "d-flex": "",
                                                "align-end": ""
                                              }
                                            },
                                            [_vm._v(" ICE BREAKERS ")]
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "ice-breakers-separator",
                                              attrs: {
                                                "d-flex": "",
                                                "align-end": "",
                                                "ml-2": "",
                                                "mr-2": ""
                                              }
                                            },
                                            [_vm._v("|")]
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "trivia-text",
                                              attrs: {
                                                "d-flex": "",
                                                "align-end": ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.game.name) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-flex"),
                          _c(
                            "v-flex",
                            {
                              staticClass: "flash-card-nav-bar",
                              attrs: { shrink: "", "d-flex": "" }
                            },
                            [
                              _c(
                                "v-layout",
                                [
                                  !_vm.firstMission
                                    ? _c(
                                        "v-layout",
                                        {
                                          staticClass: "prev",
                                          on: { click: _vm.prevMission }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                "d-flex": "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c("v-flex", {
                                                staticClass: "arrow"
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "arrow-text",
                                              attrs: {
                                                "d-flex": "",
                                                "align-center": "",
                                                "ml-1": "",
                                                "mr-2": ""
                                              }
                                            },
                                            [_vm._v("BACK")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.firstMission && !_vm.lastMission
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "arrow-separator",
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": ""
                                          }
                                        },
                                        [_vm._v("|")]
                                      )
                                    : _vm._e(),
                                  !_vm.lastMission
                                    ? _c(
                                        "v-layout",
                                        {
                                          staticClass: "next",
                                          on: { click: _vm.nextMission }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "arrow-text",
                                                  attrs: {
                                                    "d-flex": "",
                                                    "align-center": "",
                                                    "ml-2": "",
                                                    "mr-1": ""
                                                  }
                                                },
                                                [_vm._v("NEXT")]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "150px"
                                                  },
                                                  attrs: {
                                                    "d-flex": "",
                                                    "align-center": ""
                                                  }
                                                },
                                                [
                                                  _c("v-flex", {
                                                    staticClass: "arrow"
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }