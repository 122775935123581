<template>
  <div class="tournament-sidebar-header">
    <v-layout row wrap class="tournament-sidebar-header__rounds">
      <v-flex
        shrink
        d-flex
        align-center
        v-for="(round, index) in rounds"
        :key="`tournament-header-round-${round}`"
        class="tournament-sidebar-header__round"
        :class="{ '--active': isActiveRound(index) }"
      >
        <svg-icon v-if="isActiveRound(index)" size="small" name="caret-right" />
        <template v-if="round.label">
          {{ round.label }}
        </template>
        <template v-else> Round {{ index + 1 }} </template>
      </v-flex>
    </v-layout>
    <div class="sidebar-divider"></div>
    <v-btn
      @click="showTournament"
      class="rtb-background-secondary rtb-color-white"
    >
      Tournament <svg-icon name="trophy" size="small" class="mx-2" /> Standings
    </v-btn>
    <div class="sidebar-divider"></div>
  </div>
</template>

<script>
import Vue from "vue"

import { ACTION_TYPES } from "@/store/TournamentModule"
import { TournamentService } from "@/services/tournament/tournament.service"

export default Vue.extend({
  name: "TournamentSideBarHeader",
  props: {
    tournament: {
      type: Object,
      required: true
    }
  },
  computed: {
    rounds() {
      return Object.keys(this.tournament.games)
    },
    roundList() {
      return TournamentService.getRoundList(this.tournament)
    },
    activeRound() {
      return TournamentService.getActiveRound(this.roundList)
    }
  },
  methods: {
    /**
     * @param {number} index
     */
    isActiveRound(index) {
      return this.activeRound.num === index
    },
    showTournament() {
      this.$store.dispatch(
        `tournament/${ACTION_TYPES.SHOW_TOURNAMENT_STANDING}`,
        true
      )
    }
  }
})
</script>

<style lang="scss">
.tournament-sidebar-header {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 15px 0 24px;

  &__round {
    margin: 0 4px;
    &.--active {
      color: $color-white;

      .svg-icon {
        color: $secondary_accent_color;
      }
    }
  }

  &__rounds {
    display: flex;
    justify-content: center;
    padding: 8px 0;
    list-style: none;
    font-size: 14px;
    color: #686868;
    text-transform: uppercase;

    button {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .svg-icon--sidebar-divider {
    height: 2px;
    width: 350px;
  }
  // selector
  $STRIP_OPACITY: 0.4;
  $GRADIENT_START: 20%;
  .sidebar-divider {
    position: relative;
    width: 100%;
    height: 1px;

    &::after {
      position: absolute;
      content: "";
      height: 1px;
      width: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, $STRIP_OPACITY) $GRADIENT_START,
        rgba(255, 255, 255, $STRIP_OPACITY) 100% - $GRADIENT_START,
        rgba(255, 255, 255, 0) 100%
      );

      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
