var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "tournament-standings-game", attrs: { "d-flex": "" } },
    [
      _vm.broadcast
        ? _c(
            "div",
            {
              staticClass: "tournament-standings-game__watch-small",
              on: {
                click: function($event) {
                  return _vm.$emit("onWatchLive")
                }
              }
            },
            [_c("TournamentWatchLiveSmall")],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c(
            "v-flex",
            {
              staticClass: "tournament-standings-game__host-container",
              attrs: { "d-flex": "", "align-center": "", shrink: "" }
            },
            [
              _vm.broadcast
                ? _c(
                    "div",
                    {
                      staticClass: "tournament-standings-game__watch",
                      on: {
                        click: function($event) {
                          return _vm.$emit("onWatchLive")
                        }
                      }
                    },
                    [_c("TournamentWatchLive")],
                    1
                  )
                : _vm._e(),
              _c("TournamentStandingsHost", {
                attrs: { imageUrl: _vm.user.imageUrl, name: _vm.user.name },
                on: {
                  mouseleave: _vm.onMouseLeave,
                  mouseover: function($event) {
                    return _vm.onMouseOver({ $event: $event })
                  }
                }
              })
            ],
            1
          ),
          _c("v-flex", { attrs: { xs9: "" } }, [_vm._t("default")], 2)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }