<template>
  <v-flex d-flex class="tournament-standings">
    <v-layout column>
      <TournamentStandingsHeader
        v-model="roundID"
        :roundList="roundList"
        :activeRound="activeRound"
      />
      <TournamentStandingsBody
        :round="selectedRound"
        :status="getRoundStatus(selectedRound)"
        :final="isFinalRound"
        @onSelect="data => $emit('onSelect', data)"
        @onGameSelect="data => $emit('onGameSelect', data)"
      />
    </v-layout>
  </v-flex>
</template>

<script>
import TournamentStandingsBody from "./TournamentStandingsBody"
import TournamentStandingsHeader from "./TournamentStandingsHeader"
import { TournamentService } from "@/services/tournament/tournament.service"

export default {
  name: "TournamentStandings",
  components: { TournamentStandingsHeader, TournamentStandingsBody },
  props: {
    roundList: {
      type: Array,
      required: true
    },
    activeRound: {
      type: Object,
      required: true
    },
    tournament: {
      type: Object
    }
  },
  data() {
    return {
      roundID: this.roundList[0].originalGameID
    }
  },
  computed: {
    activeRoundID() {
      return this.activeRound ? this.activeRound.originalGameID : null
    },
    isFinalRound() {
      return this.roundList.length - 1 === parseInt(this.selectedRound.num)
    },
    selectedRound() {
      return this.roundID === this.activeRoundID
        ? this.activeRound.round
        : this.roundList.find(item => item.originalGameID === this.roundID)
            .round
    }
  },
  methods: {
    getRoundStatus(round) {
      round.num = this.roundList.find(
        item => item.originalGameID === this.roundID
      ).num
      return TournamentService.getRoundStatus(round, this.activeRound)
    }
  },
  watch: {
    activeRound: {
      handler(newValue, oldValue) {
        if (newValue) {
          if (oldValue && oldValue.originalGameID !== newValue.originalGameID) {
            this.roundID = newValue.originalGameID
          } else if (!oldValue) {
            this.roundID = newValue.originalGameID
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.tournament-standings {
  padding-bottom: 20px;
  width: calc(100% - 48 * 2px);
  margin-top: 24px;
  z-index: 2;
}
</style>
