<template>
  <v-flex d-flex class="tournament-standings-game">
    <div
      v-if="broadcast"
      class="tournament-standings-game__watch-small"
      @click="$emit('onWatchLive')"
    >
      <TournamentWatchLiveSmall />
    </div>
    <v-layout row>
      <v-flex
        d-flex
        align-center
        shrink
        class="tournament-standings-game__host-container"
      >
        <div
          v-if="broadcast"
          class="tournament-standings-game__watch"
          @click="$emit('onWatchLive')"
        >
          <TournamentWatchLive />
        </div>
        <TournamentStandingsHost
          :imageUrl="user.imageUrl"
          :name="user.name"
          @mouseleave="onMouseLeave"
          @mouseover="$event => onMouseOver({ $event })"
        />
      </v-flex>
      <v-flex xs9>
        <slot />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import Vue from "vue"
import TournamentWatchLiveSmall from "./TournamentWatchLiveSmall"
import TournamentWatchLive from "./TournamentWatchLive"
import TournamentStandingsHost from "./TournamentStandingsHost.vue"

export default Vue.extend({
  name: "TournamentStandingsGame",
  components: {
    TournamentStandingsHost,
    TournamentWatchLive,
    TournamentWatchLiveSmall
  },
  props: {
    broadcast: Boolean,
    user: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    onMouseLeave() {
      this.$emit("onMouseLeave")
    },
    onMouseOver({ $event }) {
      if (this.user && this.user.id) {
        this.$emit("onMouseOver", { $event, userID: this.user.id })
      }
    }
  }
})
</script>

<style lang="scss">
.tournament-standings-game {
  margin-top: 18px;
  position: relative;

  &::before {
    width: 2px;
  }

  &__watch-small {
    position: absolute;
    z-index: 5;
    top: 8px;
    right: 0px;
  }

  &__watch {
    position: absolute;
    z-index: 5;
    top: calc(50% - 105px);
    left: calc(50% - 7px);
    transform: translateX(-50%);
  }

  &__host-container {
    position: relative;
    padding-right: 30px;
    padding-left: 15px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 50%;
      right: -3px;
      height: 3px;
      background-color: #fff;
      z-index: 2;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: calc(50% - 69px);
      top: 70px;
      right: -3px;
      width: 3px;
      background-color: #fff;
      z-index: 2;
    }
  }

  &__teams {
    padding: 16px;
    background-color: #d8d8d8;
  }

  &__team {
    position: relative;

    &::before {
      position: absolute;
      right: calc(100% + 16px);
      bottom: 40px;
      content: "";
      width: 44px;
      height: 2px;
      background-color: #68625f;
    }
  }

  &__team + &__team {
    margin-top: 8px;
  }
}
</style>
