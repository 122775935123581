var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "v-flex",
    { staticClass: "rtb-separator", attrs: { shrink: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "align-center": "" } },
        [
          _c("v-flex", { staticClass: "rtb-line before" }),
          _c(
            "v-flex",
            { staticStyle: { padding: "0 10px" }, attrs: { shrink: "" } },
            [_vm._t("default")],
            2
          ),
          _c("v-flex", { staticClass: "rtb-line after" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }