var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      ref: "gameinfo",
      staticClass: "game-info",
      class: _vm.status,
      attrs: { "d-flex": "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "game-card-header", attrs: { shrink: "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "game-title", attrs: { "d-flex": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _vm.canShowBigButton
                            ? _c(
                                "v-flex",
                                {
                                  staticClass: "join-game",
                                  attrs: { "d-flex": "" }
                                },
                                [
                                  _vm.status === "available"
                                    ? [
                                        _c(
                                          "button",
                                          {
                                            class: _vm.loading ? "loading" : "",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.onJoinGame($event)
                                              }
                                            }
                                          },
                                          [
                                            _vm.loading
                                              ? [
                                                  _c("v-progress-circular", {
                                                    staticClass: "spinner",
                                                    attrs: { indeterminate: "" }
                                                  })
                                                ]
                                              : [
                                                  _vm._v(" JOIN"),
                                                  _c("br"),
                                                  _vm._v("GAME ")
                                                ]
                                          ],
                                          2
                                        )
                                      ]
                                    : _vm._e(),
                                  !_vm.isHost &&
                                  !_vm.isGameCompletelyFull &&
                                  _vm.isGameSpectable
                                    ? [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "spectate-button",
                                            class: _vm.loading ? "loading" : "",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.onJoinGame({
                                                  audit: true
                                                })
                                              }
                                            }
                                          },
                                          [
                                            _vm.loading
                                              ? [
                                                  _c("v-progress-circular", {
                                                    staticClass: "spinner",
                                                    attrs: { indeterminate: "" }
                                                  })
                                                ]
                                              : [
                                                  _vm._v(" SPECTATE"),
                                                  _c("br"),
                                                  _vm._v(" GAME ")
                                                ]
                                          ],
                                          2
                                        )
                                      ]
                                    : _vm.status === "full"
                                    ? [
                                        _c(
                                          "button",
                                          { staticClass: "disabled full" },
                                          [
                                            _vm._v("GAME IS"),
                                            _c("br"),
                                            _vm._v("FULL")
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "v-flex",
                            {
                              staticClass: "host-icon-wrap",
                              attrs: { shrink: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "host-icon-big",
                                  class: { disabled: _vm.isDefaultHostImage }
                                },
                                [
                                  !!_vm.host
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-xs-center host-name"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.host.firstname) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("video", {
                                    ref: "video",
                                    staticClass:
                                      "reception-client-image video-an",
                                    class: {
                                      flipped:
                                        _vm.announcementVideo &&
                                        _vm.announcementVideo.flipped
                                    },
                                    attrs: { src: _vm.announcementVideo },
                                    on: { ended: _vm.videoEnded }
                                  }),
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        name: "fade-in-transition-yes",
                                        mode: "out-in"
                                      }
                                    },
                                    [
                                      _vm.isDefaultHostImage
                                        ? _c("div", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.playAnnouncement,
                                                expression: "!playAnnouncement"
                                              }
                                            ],
                                            staticClass: "pending-host-image",
                                            style: {
                                              "background-image":
                                                "url(" +
                                                require("../assets/game-card-profile-icon.png") +
                                                ")"
                                            }
                                          })
                                        : _c("div", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.playAnnouncement,
                                                expression: "!playAnnouncement"
                                              }
                                            ],
                                            staticClass: "host-image",
                                            style: {
                                              "background-image":
                                                "url(" + _vm.host.image + ")"
                                            }
                                          })
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm.announcementVideo || _vm.isHost
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "btn-game-intro",
                                      attrs: { href: "#" },
                                      on: { click: _vm.onGameIntro }
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "rtb-color-secondary",
                                        attrs: { name: "button-play" }
                                      }),
                                      _vm._v(" Game Intro ")
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "game-title-name",
                              attrs: { "d-flex": "" }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", "justify-center": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      _vm._m(0),
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "actual-start-time",
                                          attrs: { row: "", shrink: "" }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  !!_vm.startTime
                                                    ? _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "start-time",
                                                          attrs: {
                                                            "d-flex": "",
                                                            "align-center": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.startTime
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.status === "current" ||
                                                  _vm.status === "booked"
                                                    ? _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "leave-game",
                                                          attrs: {
                                                            "d-flex": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: { row: "" }
                                                            },
                                                            [
                                                              _vm.canShowEnterGameButton
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        "d-flex":
                                                                          "",
                                                                        xs5: "",
                                                                        lg3: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          class: _vm.loading
                                                                            ? "loading"
                                                                            : "",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.onEnterGame(
                                                                                $event
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm.loading
                                                                            ? [
                                                                                _c(
                                                                                  "v-progress-circular",
                                                                                  {
                                                                                    staticClass:
                                                                                      "spinner",
                                                                                    attrs: {
                                                                                      indeterminate:
                                                                                        ""
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ]
                                                                            : [
                                                                                _vm._v(
                                                                                  " ENTER GAME "
                                                                                )
                                                                              ]
                                                                        ],
                                                                        2
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    "d-flex":
                                                                      "",
                                                                    xs5: "",
                                                                    lg3: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm.canLeaveGame
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "leave",
                                                                          class: _vm.loading
                                                                            ? "loading"
                                                                            : "",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.onLeaveGame(
                                                                                $event
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm.loading
                                                                            ? [
                                                                                _c(
                                                                                  "v-progress-circular",
                                                                                  {
                                                                                    staticClass:
                                                                                      "spinner",
                                                                                    attrs: {
                                                                                      indeterminate:
                                                                                        ""
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ]
                                                                            : [
                                                                                _vm._v(
                                                                                  " LEAVE THIS GAME "
                                                                                )
                                                                              ]
                                                                        ],
                                                                        2
                                                                      )
                                                                    : _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "leave",
                                                                          class: _vm.loading
                                                                            ? "loading"
                                                                            : "",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.onLeaveGame(
                                                                                $event
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm.loading
                                                                            ? [
                                                                                _c(
                                                                                  "v-progress-circular",
                                                                                  {
                                                                                    staticClass:
                                                                                      "spinner",
                                                                                    attrs: {
                                                                                      indeterminate:
                                                                                        ""
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ]
                                                                            : [
                                                                                _vm.isAudit ||
                                                                                _vm.isSpectator
                                                                                  ? _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " STOP SPECTATING THIS GAME "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " YOU'RE ON THIS GAME "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                              ]
                                                                        ],
                                                                        2
                                                                      )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "timer",
                                              attrs: {
                                                "d-flex": "",
                                                shrink: ""
                                              }
                                            },
                                            [
                                              !!_vm.isGameEnded
                                                ? _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "label ended",
                                                      attrs: {
                                                        "d-flex": "",
                                                        "align-center": ""
                                                      }
                                                    },
                                                    [_vm._v("Game Ended")]
                                                  )
                                                : _vm.isBoarding
                                                ? _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "label started",
                                                      attrs: {
                                                        "d-flex": "",
                                                        "align-center": ""
                                                      }
                                                    },
                                                    [_vm._v("Boarding")]
                                                  )
                                                : !!_vm.isGameTime
                                                ? _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "label started",
                                                      attrs: {
                                                        "d-flex": "",
                                                        "align-center": ""
                                                      }
                                                    },
                                                    [_vm._v("In Progress")]
                                                  )
                                                : _c(
                                                    "v-layout",
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass: "label",
                                                          attrs: {
                                                            "d-flex": "",
                                                            shrink: "",
                                                            "align-center": "",
                                                            "text-xs-center": ""
                                                          }
                                                        },
                                                        [_vm._v("Starts in")]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass: "value",
                                                          attrs: {
                                                            "d-flex": "",
                                                            "align-center": "",
                                                            shrink: "",
                                                            "text-xs-center": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "PreGameCountdown",
                                                            {
                                                              attrs: {
                                                                endTime:
                                                                  _vm.gameStartTime
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "game-card-body",
              style: _vm.cardBodyStyles,
              attrs: { "d-flex": "" }
            },
            [
              _c(
                "v-layout",
                { staticClass: "game-card-layout", attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-space-between": "" } },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "content", attrs: { shrink: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { column: "" } },
                                [
                                  !!_vm.game.description
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "game-card-body-row description border",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.game.description) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.missionTypes.length !== 0
                                    ? _c("mission-types", {
                                        staticClass: "game-info__mission-types",
                                        attrs: { items: _vm.missionTypes }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { staticClass: "game-card-body-row players-title-row" },
                    [
                      _c("v-flex", { attrs: { shrink: "" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "game-detail",
                            staticStyle: { display: "inline-block" }
                          },
                          [
                            _vm._v(" Players "),
                            _c("span", { staticClass: "active-players" }, [
                              _vm._v(_vm._s(_vm.nOfPlayersOnline))
                            ]),
                            _vm._v(" of "),
                            _c("span", { staticClass: "max-players" }, [
                              _vm._v(_vm._s(_vm.nOfPlayersMax))
                            ])
                          ]
                        )
                      ]),
                      !_vm.isAudit &&
                      !_vm.isHost &&
                      _vm.isGameSpectable &&
                      _vm.isGameBooked &&
                      !_vm.isGameTime &&
                      (!_vm.game.tournamentID || _vm.game.assignable)
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "custom-switch",
                              attrs: {
                                shrink: "",
                                "d-flex": "",
                                "align-center": ""
                              }
                            },
                            [
                              _c(
                                "label",
                                {
                                  class: { "active-control": !_vm.isSpectator }
                                },
                                [_vm._v(" PLAY ")]
                              ),
                              _c("v-switch", {
                                model: {
                                  value: _vm.isSpectator,
                                  callback: function($$v) {
                                    _vm.isSpectator = $$v
                                  },
                                  expression: "isSpectator"
                                }
                              }),
                              _c(
                                "label",
                                {
                                  class: { "active-control": _vm.isSpectator }
                                },
                                [_vm._v(" SPECTATE ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "game-info-user-list",
                      staticStyle: { position: "relative" },
                      attrs: { "d-flex": "" }
                    },
                    [
                      _c("UserList", {
                        attrs: {
                          users: _vm.usersOnlineToDisplay,
                          selectedUserID: _vm.selectedUserID
                        },
                        on: { onSelect: _vm.onSelectUser }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "v-flex",
      { staticClass: "game-name", class: {}, attrs: { shrink: "" } },
      [
        _c("h1", [_vm._v(_vm._s(_vm.gameName))]),
        _vm.isHost
          ? _c(
              "div",
              {
                staticClass: "cancel-game",
                on: {
                  mouseleave: function($event) {
                    _vm.isOverCancel = false
                  },
                  mouseover: function($event) {
                    _vm.isOverCancel = true
                  }
                }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "cancel-button",
                    on: { click: _vm.toggleCancelOptions }
                  },
                  [_vm._v(" cancel game ")]
                ),
                _c("div", { ref: "cancel", staticClass: "cancel-options" }, [
                  _c(
                    "div",
                    {
                      staticClass: "cancel-option",
                      on: {
                        click: function($event) {
                          if ($event.target !== $event.currentTarget) {
                            return null
                          }
                          return _vm.deleteGameAttempt($event)
                        }
                      }
                    },
                    [_vm._v(" Delete ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "cancel-option",
                      on: {
                        click: function($event) {
                          if ($event.target !== $event.currentTarget) {
                            return null
                          }
                          return _vm.deactivateGame($event)
                        }
                      }
                    },
                    [_vm._v(" Deactivate ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "cancel-option" },
                    [
                      _vm._v(" Merge Into "),
                      _c("v-select", {
                        attrs: {
                          items: _vm.filteredGamesArray,
                          label: "Play Type",
                          required: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function(data) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          data.item.externalName ||
                                            data.item.name
                                        ) +
                                        " "
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(data.item.total) +
                                          "/" +
                                          _vm._s(data.item.max)
                                      )
                                    ])
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3836211424
                        ),
                        model: {
                          value: _vm.mergeToGameSelection,
                          callback: function($$v) {
                            _vm.mergeToGameSelection = $$v
                          },
                          expression: "mergeToGameSelection"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          : _vm._e()
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }