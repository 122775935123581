var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.object.hours.number > 9
        ? [_vm._v(" " + _vm._s(_vm.object.hours.string) + "h ")]
        : _vm.object.hours.number > 0
        ? [
            _vm._v(
              " " +
                _vm._s(_vm.object.hours.string) +
                ":" +
                _vm._s(_vm.object.minutes.string) +
                " "
            )
          ]
        : [
            _vm._v(
              " " +
                _vm._s(_vm.object.minutes.string) +
                ":" +
                _vm._s(_vm.object.seconds.string) +
                " "
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }