var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            {
              staticClass: "side-bar-tabs",
              attrs: { shrink: "", "d-flex": "" }
            },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "tab rooms-tab",
                      class: _vm.tab == "games" ? "selected" : null,
                      attrs: {
                        xs6: "",
                        "text-xs-center": "",
                        "d-flex": "",
                        "align-center": ""
                      },
                      on: {
                        click: function($event) {
                          _vm.tab = "games"
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "tab-label" }, [
                        _vm._v("GAME ROOMS")
                      ])
                    ]
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "tab players-tab",
                      class: _vm.tab == "players" ? "selected" : null,
                      attrs: {
                        xs6: "",
                        "text-xs-center": "",
                        "d-flex": "",
                        "align-center": ""
                      },
                      on: {
                        click: function($event) {
                          _vm.tab = "players"
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "tab-label" }, [
                        _vm._v("PLAYERS")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "side-bar-content-wrap", attrs: { "d-flex": "" } },
            [
              _vm.tab === "games" && _vm.tournament
                ? [
                    _c(
                      "v-flex",
                      {
                        ref: "scrollable",
                        staticClass: "scrollable side-bar-content rtl",
                        attrs: { "d-flex": "" }
                      },
                      [
                        _c("GameSearch", {
                          ref: "game_search",
                          staticStyle: { "margin-top": "100px" },
                          attrs: {
                            games: _vm.games,
                            selectedGameID: _vm.selectedGameID,
                            isGamePlayedByUser: _vm.isGamePlayedByUser
                          },
                          on: {
                            addTime: _vm.addTime,
                            removeTime: _vm.removeTime,
                            onGameCardSelectEvent: _vm.onGameCardSelectEvent
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      {
                        staticStyle: {
                          position: "absolute",
                          width: "100%",
                          height: "100px"
                        },
                        attrs: { "d-flex": "", "align-center": "" }
                      },
                      [
                        _c("TournamentSideBarHeader", {
                          attrs: { tournament: _vm.tournament }
                        })
                      ],
                      1
                    )
                  ]
                : [
                    _c(
                      "v-flex",
                      {
                        ref: "scrollable",
                        staticClass: "scrollable side-bar-content rtl",
                        attrs: { "d-flex": "" }
                      },
                      [
                        _vm.tab === "players"
                          ? _c("UserSearch", {
                              attrs: {
                                clientID: _vm.clientID,
                                selectedUserID: _vm.selectedUserID
                              },
                              on: { onSelect: _vm.onUserSelect }
                            })
                          : _vm.tab === "games"
                          ? _c("GameSearch", {
                              ref: "game_search",
                              attrs: {
                                games: _vm.games,
                                selectedGameID: _vm.selectedGameID,
                                isGamePlayedByUser: _vm.isGamePlayedByUser
                              },
                              on: {
                                addTime: _vm.addTime,
                                removeTime: _vm.removeTime,
                                onGameCardSelectEvent: _vm.onGameCardSelectEvent
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }