<script>
import { mergeData } from "vue-functional-data-merge"
import { DEFAULT_PROFILE_IMAGE } from "@/config"

export default {
  name: "TournamentStandingsHost",
  functional: true,
  props: {
    imageUrl: {
      type: String
    },
    name: {
      type: String,
      default: "PENDING..."
    }
  },
  render(h, { props, data }) {
    return h("div", mergeData(data, { staticClass: "tournament-host" }), [
      h("img", {
        attrs: { src: props.imageUrl || DEFAULT_PROFILE_IMAGE },
        staticClass: props.imageUrl
          ? "tournament-host__img"
          : "tournament-host__img default"
      }),
      h("div", { staticClass: "tournament-host__info-container" }, [
        h("div", { staticClass: "tournament-host__info" }, "HOSTED BY"),
        h("div", { staticClass: "tournament-host__name" }, props.name)
      ])
    ])
  }
}
</script>

<style lang="scss">
$tournament-host-width: 90px;

.tournament-host {
  width: $tournament-host-width;
  text-align: center;
  position: relative;
  z-index: 2;
  &__img {
    @extend .rtb-box-shadow;
    cursor: pointer;
    display: block;
    width: $tournament-host-width;
    height: $tournament-host-width;
    border: 2px solid $color_white;
    border-radius: 12px;
    overflow: hidden;
    margin: auto;
    background-color: $color-tertiary-dark;
    &.default {
      padding-top: 10px;
      object-fit: contain;
    }
  }

  &__info-container {
    position: absolute;
    width: 100%;
    height: 0px;
  }

  &__info {
    margin-top: 6px;
    font-size: 13px;
    color: $color_white;
    font-weight: bold;
    white-space: nowrap;
  }

  &__name {
    margin-top: 3px;
    font-size: 15px;
    color: $primary_accent_color;
    white-space: nowrap;
  }
}
</style>
