// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/game-card-profile-icon-big-light.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* These colors are supposed to by dynamuc during the runtime */\n/* Please, don't plase the static color here, if they are not supposed to be changed in runtime */\n.rtb-border-radius {\n  border-radius: 8px;\n}\n.rtb-border {\n  border: solid 3px var(--primary-accent-color);\n}\n.rtb-box-shadow {\n  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);\n          box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);\n}\n.live-chat-dummy-slot {\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n.live-chat-dummy-slot .outline {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 92%;\n  background-position: 50% 100%;\n  background-repeat: no-repeat;\n  color: #e1e1e1;\n  background-color: #acacac;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  position: relative;\n  border-radius: 8%;\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n  line-height: 1.1;\n  font-size: 14px;\n}", ""]);
// Exports
module.exports = exports;
