var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "pre-game-profile", attrs: { "d-flex": "" } },
    [
      !!_vm.profileUser && !!_vm.profileUser.id
        ? _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                {
                  staticStyle: { position: "relative" },
                  attrs: {
                    xs4: "",
                    "mt-3": "",
                    "ml-3": "",
                    "mb-3": "",
                    "mr-2": "",
                    "d-flex": "",
                    "align-center": "",
                    "justify-center": ""
                  }
                },
                [
                  _c("PreGamePlayer", {
                    key: _vm.profileUser.id,
                    staticClass: "player",
                    attrs: { imageURL: _vm.profileUser.image }
                  }),
                  _vm.isSpectator
                    ? _c(
                        "svg",
                        {
                          staticClass: "pre-game-profile__media-icon",
                          attrs: { viewBox: "0 0 487 487" }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M379 230c-26 0-46 20-46 46 0 6 5 11 11 11s11-5 11-11c0-13 11-24 24-24 6 0 11-5 11-11s-5-11-11-11z"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M455 198l-9-8-41-35c-16-11-35-18-55-19l2 2-25-25a39 39 0 00-63 17c-5-3-11-5-18-6h-3c-7 0-13 2-19 6a39 39 0 00-63-17h-1l-24 25 2-2c-21 1-40 8-55 20l-41 34-9 8a109 109 0 10184 92 54 54 0 0054-1 109 109 0 10184-91zM109 350a74 74 0 110-148 74 74 0 010 148zm135-84a24 24 0 110-48 24 24 0 010 48zm135 84a74 74 0 110-148 74 74 0 010 148z"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M109 230c-26 0-46 20-46 46 0 6 5 11 11 11s11-5 11-11c0-13 11-24 24-24 6 0 11-5 11-11s-5-11-11-11z"
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs8: "",
                    "d-flex": "",
                    "mt-3": "",
                    "mr-3": "",
                    "mb-3": ""
                  }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { row: "", "align-center": "", wrap: "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "", "mr-2": "" } },
                                [
                                  _c(
                                    "h1",
                                    {
                                      staticClass:
                                        "profile-user-name rtb-truncate"
                                    },
                                    [
                                      _c("UserName", {
                                        attrs: {
                                          firstname: _vm.profileUser.firstname,
                                          lastname: _vm.profileUser.lastname
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm.userID !== _vm.user.id
                                ? _c("v-flex", { attrs: { shrink: "" } }, [
                                    _vm.userStatus === "online"
                                      ? _c(
                                          "div",
                                          { staticClass: "online-status" },
                                          [_vm._v(" ONLINE ")]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "online-status offline"
                                          },
                                          [_vm._v("OFFLINE")]
                                        )
                                  ])
                                : _vm._e(),
                              !!_vm.profileUser.speaker
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass: "profile-speaker-icon",
                                      attrs: { shrink: "", "ml-2": "" }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-bullhorn"
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !!_vm.profileGame
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "game-details",
                              attrs: { "d-flex": "", "align-center": "" }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-flex",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "rtb-line-clamp rtb-line-clamp--3",
                                                      attrs: { xs7: "" }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "link game-name",
                                                        on: {
                                                          click: _vm.onGameClick
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.profileGame
                                                                .externalName ||
                                                                _vm.profileGame
                                                                  .name
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        492133398
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.profileGame.externalName ||
                                              _vm.profileGame.name
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  !!_vm.gameStartTime
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "countdown-wrap",
                                          attrs: { xs5: "" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "game-starts-in" },
                                            [
                                              _c("PreGameCountdown", {
                                                staticClass: "rtb-block",
                                                attrs: {
                                                  endTime: _vm.gameStartTime
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm.queueNumber > 0
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "game-details",
                              attrs: { "d-flex": "", "align-center": "" }
                            },
                            [
                              _c("span", { staticClass: "queue" }, [
                                _vm._v(
                                  " #" + _vm._s(_vm.queueNumber) + " in queue "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { attrs: { "d-flex": "", "align-center": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "rtb-self-end",
                                  attrs: {
                                    "d-flex": "",
                                    "align-center": "",
                                    xs7: ""
                                  }
                                },
                                [
                                  _c("RoomInfo", {
                                    attrs: {
                                      users: _vm.currentRoomUsers,
                                      nOfSlots: _vm.max - 1
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "buttons ml-2 rtb-flex-col",
                                  attrs: { "d-flex": "", xs5: "" }
                                },
                                [
                                  _vm.isHost &&
                                  (!_vm.isHostRole(_vm.profileUser.role) ||
                                    !!_vm.user.super)
                                    ? _c(
                                        "div",
                                        { staticClass: "move-to-game" },
                                        [
                                          _c("button", [
                                            _vm._v("Move to Game")
                                          ]),
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.filteredGamesArray,
                                              label: "Play Type",
                                              required: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function(data) {
                                                    return [
                                                      _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .externalName ||
                                                                data.item.name
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2971131368
                                            ),
                                            model: {
                                              value: _vm.assignedToGame,
                                              callback: function($$v) {
                                                _vm.assignedToGame = $$v
                                              },
                                              expression: "assignedToGame"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.requestButtonStatus === "onboarding"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "invite-button disabled",
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            disabled: ""
                                          }
                                        },
                                        [_vm._v(" ONBOARDING ")]
                                      )
                                    : _vm.requestButtonStatus === "full"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "invite-button disabled",
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            disabled: ""
                                          }
                                        },
                                        [_vm._v(" CHAT FULL ")]
                                      )
                                    : _vm.requestButtonStatus === "playing"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "invite-button disabled",
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            disabled: ""
                                          }
                                        },
                                        [_vm._v(" IN GAME ")]
                                      )
                                    : _vm.requestButtonStatus === "show"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "invite-button",
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": ""
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onInvite($event)
                                            }
                                          }
                                        },
                                        [_vm._v(" CHAT ")]
                                      )
                                    : _vm.requestButtonStatus === "loading"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "invite-button disabled",
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": ""
                                          }
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            staticClass: "spinner",
                                            attrs: { indeterminate: "" }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm.requestButtonStatus === "join"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "invite-button join",
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": ""
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onJoin($event)
                                            }
                                          }
                                        },
                                        [_vm._v(" CHAT ")]
                                      )
                                    : _vm.requestButtonStatus === "locked"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "invite-button disabled locked",
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": ""
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-lock locked-icon"
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "locked-text" },
                                            [_vm._v(" LOCKED ")]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !!_vm.currentRoomUsers.length
                        ? _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            _vm._l(_vm.currentRoomUsers, function(user) {
                              return _c(
                                "span",
                                {
                                  key: "room-info-user-names-" + user.id,
                                  staticClass: "room-user-name"
                                },
                                [
                                  _c("UserName", {
                                    attrs: {
                                      firstname: user.firstname,
                                      lastname: user.lastname
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }