<template>
  <v-container class="expo-tour" :class="{ done: expoTourDone }">
    <v-flex class="overlay"></v-flex>
    <v-flex class="frame" :style="frameStyle"></v-flex>

    <v-flex
      class="message-box"
      :class="msgboxClass"
      :style="{ left: messageLeft, top: messageTop }"
      ref="messagebox"
    >
      <v-flex class="message" v-html="message" ref="message"></v-flex>
      <v-flex class="button" :class="{ show: showButton }" @click="nextStep()"
        >Got it!</v-flex
      >
    </v-flex>
  </v-container>
</template>

<script>
export default {
  name: "ExpoTour",
  props: {
    expoHTML: {
      type: HTMLDivElement,
      default: null,
      required: false
    },
    isHost: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      toolbarHeight: 0,
      step: 0,
      frameLeft: 0,
      frameTop: 0,
      frameWidth: 0,
      frameHeight: 0,
      messageLeft: 0,
      messageTop: 0,
      message: "",
      messageAlign: "",
      messageArrow: false,
      showMessageBox: false,
      showButton: true,
      expoTourDone: false
    }
  },
  created() {
    if (this.isHost) this.toolbarHeight = 33
    window.addEventListener("resize", this.updateData)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateData)
  },
  watch: {
    expoHTML(val) {
      if (val) {
        this.nextStep()
      }
    }
  },
  computed: {
    outerCoords() {
      return [
        {
          x: "0",
          y: this.frameTop + "px"
        },
        {
          x: "0",
          y: "0"
        },
        {
          x: "100%",
          y: "0"
        },
        {
          x: "100%",
          y: "100%"
        },
        {
          x: "0",
          y: "100%"
        },
        {
          x: "0",
          y: this.frameTop + "px"
        }
      ]
    },
    innerCoords() {
      return [
        {
          x: this.frameLeft + "px",
          y: this.frameTop + "px"
        },
        {
          x: this.frameLeft + "px",
          y: this.frameTop + this.frameHeight + "px"
        },
        {
          x: this.frameLeft + this.frameWidth + "px",
          y: this.frameTop + this.frameHeight + "px"
        },
        {
          x: this.frameLeft + this.frameWidth + "px",
          y: this.frameTop + "px"
        },
        {
          x: "0",
          y: this.frameTop + "px"
        }
      ]
    },
    frameStyle() {
      var coords = ""
      this.outerCoords.forEach(element => {
        coords += element.x + " " + element.y + ", "
      })
      this.innerCoords.forEach(element => {
        coords += element.x + " " + element.y + ", "
      })
      coords = coords.substring(0, coords.length - 2)
      return "clip-path: polygon(" + coords + ")"
    },
    msgboxClass() {
      var classList = this.messageAlign
      if (this.showMessageBox) classList += " show"
      if (this.messageArrow) classList += " " + this.messageArrow
      return classList
    }
  },
  methods: {
    updateData() {
      switch (this.step) {
        case 1:
          this.step1()
          break
        case 2:
          this.step2()
          break
        case 3:
          this.step3()
          break
        case 4:
          this.step4()
          break
        case 5:
          this.step5()
          break
        case 6:
          this.step6()
          break
        case 7:
          this.step7()
          break
      }
    },
    nextStep() {
      this.step++
      this.updateData()

      if (this.step == 2 && this.step < 7) {
        setTimeout(() => {
          this.nextStep()
        }, 1500)
      }
      if (this.step != 3) {
        this.showMessageBox = false
      }
      if (this.step < 7) {
        setTimeout(() => {
          this.showMessageBox = true
        }, 500)
      }
    },
    step1() {
      var chatSlotWidth = this.expoHTML.querySelector(".live-chat-player-wrap")
        .offsetWidth

      this.frameLeft = 443
      this.frameTop = this.toolbarHeight + 57
      this.frameWidth = chatSlotWidth + 10
      this.frameHeight = 0

      this.messageLeft = "50%"
      this.messageTop = "50%"
      this.messageAlign = "center"
      this.message =
        "<h2>Welcome to the EXPO!!</h2><p>This is where everyone playing can choose games to join and socialize while you wait for your game to launch.</p>"
    },
    step2() {
      var chatSlotWidth = this.expoHTML.querySelector(".live-chat-player-wrap")
        .offsetWidth
      var chatSlotHeight = this.expoHTML.querySelector(".live-chat-player-wrap")
        .offsetHeight

      this.frameLeft = 443
      this.frameTop = this.toolbarHeight + 57
      this.frameWidth = chatSlotWidth + 10
      this.frameHeight = chatSlotHeight + 10

      this.showButton = false
      this.messageArrow = "arrow-top"
      this.messageLeft = 443 + chatSlotWidth / 2 + "px"
      this.messageTop = this.toolbarHeight + chatSlotHeight + 77 + "px"
      this.messageAlign = "top"
      this.message = "<h2>Say hi</h2>"

      this.$refs.messagebox.style.height = "70px"
    },
    step3() {
      var chatSlotsWidth = this.expoHTML.querySelector(".live-chat-players")
        .offsetWidth
      var chatSlotsHeight = this.expoHTML.querySelector(".live-chat-players")
        .offsetHeight

      this.frameLeft = 443
      this.frameTop = this.toolbarHeight + 57
      this.frameWidth = chatSlotsWidth + 10
      this.frameHeight = chatSlotsHeight + 10

      this.messageLeft = 443 + chatSlotsWidth / 2 + "px"
      this.messageTop = this.toolbarHeight + chatSlotsHeight + 77 + "px"
      this.messageAlign = "top"

      this.$refs.messagebox.style.height = "146px"

      this.message =
        "<h2>Say hi</h2><p class='hide'>You can <strong>Video Chat</strong> with up to<br><strong>5 other people</strong> here at one time</p>"

      setTimeout(() => {
        this.showButton = true
        this.$refs.message.querySelector(".hide").classList.remove("hide")
      }, 500)
    },
    step4() {
      this.expoHTML.querySelector(".players-tab").click()
      var chatRowHeight = this.expoHTML.querySelector(".live-chat-row")
        .offsetHeight
      var sideBarHeight = this.expoHTML.querySelector(".reception-side-bar")
        .offsetHeight

      this.frameLeft = 0
      this.frameTop = this.toolbarHeight + chatRowHeight - 2
      this.frameWidth = 405
      this.frameHeight = sideBarHeight + 5

      this.messageArrow = "arrow-left"
      this.messageLeft = 415 + "px"
      this.messageTop =
        this.toolbarHeight + chatRowHeight + sideBarHeight / 2 + "px"
      this.messageAlign = "left"
      this.message =
        "<h2>Find other people<br>hanging out in the EXPO</h2><p>Hover over their circle<br>to <strong>start a video chat</strong></p>"

      this.$refs.messagebox.style.removeProperty("height")
    },
    step5() {
      this.expoHTML.querySelector(".rooms-tab").click()
      var chatRowHeight = this.expoHTML.querySelector(".live-chat-row")
        .offsetHeight
      var sideBarHeight = this.expoHTML.querySelector(".reception-side-bar")
        .offsetHeight

      this.frameLeft = 0
      this.frameTop = this.toolbarHeight + chatRowHeight - 2
      this.frameWidth = 405
      this.frameHeight = sideBarHeight + 5

      this.messageArrow = "arrow-left"
      this.messageLeft = 415 + "px"
      this.messageTop =
        this.toolbarHeight + chatRowHeight + sideBarHeight / 2 + "px"
      this.messageAlign = "left"
      this.message =
        "<h2>Explore the Game Rooms</h2><p>See the list of available <strong>Game Rooms</strong> and related content.</p>"
    },
    step6() {
      var chatSlotWidth = this.expoHTML.querySelector(".live-chat-player-wrap")
        .offsetWidth
      var chatSlotHeight = this.expoHTML.querySelector(".live-chat-player-wrap")
        .offsetHeight

      this.frameLeft = 443
      this.frameTop = this.toolbarHeight + chatSlotHeight + 42
      this.frameWidth = chatSlotWidth + 10
      this.frameHeight = 25

      this.messageArrow = "arrow-top"
      this.messageLeft = 443 + chatSlotWidth / 2 + "px"
      this.messageTop = this.toolbarHeight + chatSlotHeight + 77 + "px"
      this.messageAlign = "top"
      this.message =
        "<h2>See when your game will start</h2><p>Don't worry, we'll launch your game <strong>automatically</strong></p>"
    },
    step7() {
      this.expoHTML.querySelector(".players-tab").click()
      this.frameLeft = 0
      this.frameTop = 0
      this.frameWidth = window.innerWidth
      this.frameHeight = window.innerHeight
      setTimeout(() => {
        this.expoTourDone = true
        this.$emit("completeExpoTour")
      }, 550)
    }
  }
}
</script>

<style lang="scss">
.expo-tour {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  max-width: unset;

  &.done {
    display: none;
  }

  .overlay,
  .frame {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .frame {
    z-index: 1;
    background: rgba($color-black, 0.6);
    transition: all 0.5s ease-out;
  }

  .message-box {
    position: fixed;
    background: $color-white;
    width: 300px;
    border-radius: 2px;
    box-shadow: 0 0 10px $color-black;
    z-index: 100;
    text-align: center;
    padding: 20px;
    visibility: hidden;
    opacity: 0;

    &.center {
      transform: translate(-50%, -50%);
    }
    &.top {
      transform: translateX(-50%);
    }
    &.left {
      transform: translateY(-50%);
    }

    &.arrow-top:after {
      content: "";
      position: absolute;
      top: -6px;
      left: calc(50% - 3px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent $color-white transparent;
    }
    &.arrow-left:after {
      content: "";
      position: absolute;
      left: -6px;
      top: calc(50% - 3px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 6px 0;
      border-color: transparent $color-white transparent transparent;
    }

    &.show {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.25s ease-out, left 0.5s ease-out,
        height 0.5s ease-out;
    }

    .message {
      & > * {
        opacity: 1;
        transition: opacity 0.5s ease-out;
      }
      & > .hide {
        opacity: 0;
        transition: none;
      }
      h2 {
        font-size: 18px;
        line-height: 20px;
        margin: 0 0 10px;
        color: $primary_accent_color;
      }
      p {
        margin: 0;
        font-size: 16px;
        line-height: 18px;
      }
    }

    .button {
      display: inline-block;
      margin-top: 20px;
      padding: 5px 10px;
      border-radius: 2px;
      color: $color-white;
      background: $primary_accent_color;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 13px;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      cursor: pointer;
      &:hover {
        filter: brightness(1.1);
      }
      &.show {
        position: static;
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s ease-out;
      }
    }
  }
}
</style>
