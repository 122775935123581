var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "tournament-team",
      class: _vm.team.winner ? "winner" : null
    },
    [
      _c(
        "header",
        { staticClass: "tournament-team__header" },
        [
          !!_vm.team.winner
            ? _c("svg-icon", { staticClass: "crown", attrs: { name: "crown" } })
            : _vm._e(),
          _c("v-icon", { staticClass: "tournament-team__icon" }, [
            _vm._v(
              " " + _vm._s(_vm.team.icon || "radio_button_unchecked") + " "
            )
          ]),
          _c("span", { staticClass: "tournament-team__name" }, [
            _vm._v(_vm._s(_vm.team.name))
          ]),
          _c("span", { staticClass: "mx-2 tournament-team__divider" }),
          _c("span", { staticClass: "tournament-team__pts" }, [
            _vm._v(_vm._s(_vm._f("round")(_vm.team.score)) + " "),
            _c("span", { staticClass: "tournament-team__pts-text" }, [
              _vm._v("PTS")
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tournament-team__body" },
        [
          _c(
            "ul",
            { staticClass: "tournament-team__users flex d-flex align-center" },
            _vm._l(_vm.users, function(user) {
              return _c(
                "li",
                {
                  key: "tournament-user-standing-" + user.id,
                  staticClass: "tournament-team__user"
                },
                [
                  _c("TournamentUser", {
                    attrs: { imageUrl: user.imageUrl, name: user.name },
                    on: {
                      mouseleave: _vm.onMouseLeave,
                      mouseover: function($event) {
                        return _vm.onMouseOver({
                          $event: $event,
                          userID: user.id
                        })
                      }
                    }
                  })
                ],
                1
              )
            }),
            0
          ),
          _c(
            "v-layout",
            { staticClass: "tournament-team__dots" },
            [
              _c("v-flex", { staticClass: "dot" }),
              _c("v-flex", { staticClass: "dot" }),
              _c("v-flex", { staticClass: "dot" }),
              _c("v-flex", { staticClass: "dot" }),
              _c("v-flex", { staticClass: "dot" }),
              _c("v-flex", { staticClass: "dot" })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }