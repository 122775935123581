<template>
  <v-flex class="tournament-tab-text">
    <svg-icon
      v-if="round.num < activeRound.num"
      name="checkbox"
      class="checkbox"
    />
    {{ label }}
    <div class="starts-at" v-if="round.num > activeRound.num">
      starts @ {{ new Date(round.round.timestamp) | moment("LT") }}
    </div>
    <add-to-calendar
      v-if="round && activeRound && round.num > activeRound.num"
      :title="`Go Remote Tournament - Round ${round ? round.num + 1 : null}`"
      location="ANYWHERE"
      :start="new Date(round.round.timestamp)"
      :end="end"
      :details="
        `RTB Tournament - Round ${round ? round.num + 1 : null} - ${name}`
      "
      inline-template
      class="calendar"
    >
      <google-calendar id="google-calendar">
        <svg-icon name="calendar" />
      </google-calendar>
    </add-to-calendar>
    <div class="vertical-divider"></div>
  </v-flex>
</template>

<script>
import { MomentFilter } from "@/mixins/filters"

export default {
  name: "TournamentTabText",
  props: {
    round: {
      type: Object,
      required: true
    },
    activeRound: {
      type: Object,
      required: true
    }
  },
  mixins: [MomentFilter],
  computed: {
    label() {
      return (
        (this.round.round && this.round.round.label) ||
        `ROUND ${this.round.num + 1}`
      )
    },
    name() {
      return this.$store.state.auth.client.name
    },
    /**
     * @returns {Date}
     */
    end() {
      const { expectedEndTime, timestamp } = this.round.round
      return expectedEndTime
        ? new Date(expectedEndTime)
        : new Date(
            new Date(timestamp).setHours(new Date(timestamp).getHours() + 1)
          )
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/variables";

.tournament-standing__header-rounds .round {
  .tournament-tab-text {
    font-size: 16px;
    line-height: 36px;

    .checkbox {
      width: 14px;
      height: 14px;
      margin-bottom: 3px;
    }
  }

  &.incoming {
    position: relative;

    .tournament-tab-text {
      padding: 5px 23px 0 0;
      font-size: 14px;
      line-height: 13px;
      .starts-at {
        font-size: 11px;
      }
    }

    .calendar {
      position: absolute;
      right: 10px;
      top: 7px;
      .svg-icon {
        width: 22px;
        height: 22px;
        fill: $color-white;
      }
    }
    &.--selected .calendar {
      .svg-icon {
        fill: $color-primary-accent;
      }
      &:hover {
        filter: brightness(1.2);
      }
    }
  }
}
</style>
