<template>
  <v-flex d-flex class="tournament-standing__body">
    <v-layout column>
      <v-flex shrink>
        <header class="tournament-standing__body-header">
          <div class="icon">
            <svg-icon name="game-on" />
          </div>
          <h4>
            <span class="font-bold">{{ name }}</span>
            <span
              v-if="!!round.label"
              class="tournament-standing__body-header__round-label"
            >
              ROUND {{ round.num + 1 }}
            </span>
          </h4>
          <span class="tournament-standing__body-header__divider"></span>
          <span class="tournament-standing__body-header__accent"
            >{{ numberOfTeams }}&nbsp;</span
          >
          <span class="tournament-standing__body-header__label">Teams</span>
          <span class="tournament-standing__body-header__divider"></span>
          <span class="tournament-standing__body-header__accent"
            >{{ numberOfGames }}&nbsp;</span
          >
          <span class="tournament-standing__body-header__label">
            <span v-if="status == 'ended'">Ended&nbsp;</span>
            <span v-else-if="status == 'active'">Active&nbsp;</span>
            <span v-else-if="status == 'incoming'">Incoming&nbsp;</span>
            <span>Games</span>
          </span>
        </header>
      </v-flex>
      <v-flex
        d-flex
        v-if="round && !!round.teams"
        class="tournament-standing__body-inner"
      >
        <div class="tournament-standing__body-inner__absolute-wrap">
          <v-flex
            v-if="!!finalGameWinner"
            ml-4
            class="tournament-standing__body__absolute-winner"
          >
            <v-layout row>
              <v-flex shrink style="position: relative">
                <svg-icon
                  class="tournament-standing__body__absolute-winner__final-round-cup"
                  name="cup"
                  size="large"
                />
                <logo-small
                  class="tournament-standing__body__absolute-winner__rtb-logo svg-logo--colored"
                />
              </v-flex>
              <v-flex d-flex align-center ml-3>
                <v-layout column>
                  <v-flex
                    class="tournament-standing__body__absolute-winner__title"
                  >
                    <h1>{{ finalGameWinner.name }} WIN!!!</h1>
                  </v-flex>
                  <v-flex
                    class="tournament-standing__body__absolute-winner__score-text"
                  >
                    <span
                      class="tournament-standing__body__absolute-winner__score"
                    >
                      {{ finalGameWinner.score }}
                    </span>
                    <span
                      class="tournament-standing__body__absolute-winner__pts"
                    >
                      PTS
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-layout column ml-3 mr-3 mt-2 mb-2>
            <TournamentStandingsGame
              v-for="(group, gameID) in normalizedTeamsGroupedByGameId"
              :key="`tournament-standing-${gameID}`"
              :user="getHostByGroup(group)"
              :broadcast="!!broadcastUrl(gameID)"
              @onMouseOver="onMouseOver"
              @onMouseLeave="onMouseLeave"
              @onWatchLive="onWatchLive(gameID)"
              :class="
                final && !!gameInfo[gameID].endTimestamp
                  ? `tournament-standing__absolute-winner`
                  : null
              "
            >
              <TournamentTeam
                v-for="team in group"
                :key="`tournament-standing-team-${team.id}`"
                :team="team"
                class="tournament-standing__body-team"
                @onMouseOver="onMouseOver"
                @onMouseLeave="onMouseLeave"
              />
            </TournamentStandingsGame>
          </v-layout>
        </div>
      </v-flex>
      <v-flex
        v-else
        d-flex
        align-center
        class="tournament-standing__body-inner"
        text-xs-center
      >
        <span class="tournament-standing__body-inner__no-data">
          NOT STARTED
        </span>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import TournamentStandingsGame from "./TournamentStandingsGame.vue"
import TournamentTeam from "./TournamentTeam.vue"
import { Role } from "@/helpers"
import { mapGetters } from "vuex"
import _ from "lodash"
import LogoSmall from "@/components/LogoSmall.vue"

export default {
  name: "TournamentStandingsBody",
  components: {
    TournamentStandingsGame,
    TournamentTeam,
    LogoSmall
  },
  props: {
    round: {
      type: Object,
      required: true
    },
    final: Boolean,
    status: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      mouseOverTimeout: null
    }
  },
  beforeDestroy() {
    clearInterval(this.mouseOverTimeout)
  },
  computed: {
    ...mapGetters("pregame", ["games"]),
    numberOfGames() {
      return this.gameIDs.length
    },
    numberOfTeams() {
      return Object.keys(this.round.teams || {}).length
    },
    gameIDs() {
      return Object.keys(this.normalizedTeamsGroupedByGameId)
    },
    finalGameWinner() {
      if (!this.final) return null
      for (let key in this.gameInfo) {
        if (this.gameInfo[key] && this.gameInfo[key].endTimestamp) {
          const [winner] = this.teamsGroupedByGameID[key] || []
          return winner
        }
      }
      return null
    },
    gameInfo() {
      return this.gameIDs.reduce((acc, val) => {
        if (this.games && this.games[val]) {
          acc[val] = this.games[val]
        }
        return acc
      }, {})
    },
    teamsGroupedByGameID() {
      return Object.entries(this.round.teams || {})
        .map(([id, team]) => ({ ...team, id }))
        .sort((a, b) => b.score - a.score)
        .reduce((acc, val) => {
          if (acc[val.gameID]) {
            acc[val.gameID].push(val)
          } else {
            acc[val.gameID] = [{ ...val, winner: true }]
          }
          return acc
        }, {})
    },
    normalizedTeamsGroupedByGameId() {
      return _.pickBy(
        this.teamsGroupedByGameID,
        ([firstTeam], key) =>
          !firstTeam.pending ||
          (!this.games[key].ondeck && !this.games[key].deactivate)
      )
    },
    name() {
      return this.$store.state.auth.client.name
    }
  },
  methods: {
    broadcastUrl(gameID) {
      return this.games &&
        this.games[gameID] &&
        this.games[gameID].gameType === "Broadcast" &&
        this.games[gameID].twitchChannel
        ? `https://www.twitch.tv/${this.games[gameID].twitchChannel}`
        : null
    },
    onWatchLive(gameID) {
      this.$emit("onGameSelect", { gameID })
    },
    onMouseOver({ userID, $event }) {
      const rect = $event.target.getBoundingClientRect()
      const { x, y, height, width } = rect
      clearTimeout(this.mouseOverTimeout)
      this.mouseOverTimeout = setTimeout(() => {
        this.$emit("onSelect", { userID, x, y, height, width })
      }, 500)
    },
    onMouseLeave() {
      clearTimeout(this.mouseOverTimeout)
    },
    getHostByGroup(game) {
      const [value] = Object.values(game || {})
      const { users } = value || {}
      const entry = Object.entries(users || {}).find(
        entry => entry[1].role === Role.Host
      )
      const [userID, user] = entry || []
      return entry ? { ...user, id: userID } : undefined
    }
  }
}
</script>

<style lang="scss">
.tournament-standing {
  &__absolute-winner {
    .tournament-team:not(.winner) {
      &:before,
      &:after {
        background-color: $color-tertiary-dark !important;
      }
      filter: grayscale(1) brightness(50%);
      .dot {
        &:before {
          background-color: $color-tertiary-dark !important;
        }
      }
    }
  }
  &__body-header {
    box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.33);
    h4 {
      text-transform: uppercase;
      font-size: 16px;
    }
    &__divider {
      width: 2px;
      height: 12px;
      margin: 0 10px;
      background: #868585;
    }
    &__accent {
      color: $secondary_accent_color;
      font-weight: bold;
      font-size: 16px;
    }
    &__label {
      color: #2d2b2a;
      font-weight: 500;
      font-size: 14px;
    }
    &__round-label {
      color: $primary_accent_color !important;
      display: inline-block;
      margin-left: 5px;
    }
  }
  &__body {
    background-color: rgba(0, 0, 0, 0.25);

    &__absolute-winner {
      color: #fff;
      &__final-round-cup {
        width: 100px;
        height: 100px;
      }
      &__rtb-logo {
        position: absolute;
        top: 50%;
        margin-top: -30%;
        fill: #000 !important;
        left: 25%;
        width: 50%;
        height: auto;
      }
      &__pts {
        font-size: 13px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
      }
      &__score {
        color: $primary_accent_color;
        font-size: 18px;
        display: inline-block;
        vertical-align: middle;
      }
      &__score-text {
        margin-top: 5px;
        font-weight: bold;
      }
      &__title {
        font-size: 20px;
        text-transform: uppercase;
      }
    }

    &-header {
      display: flex;
      position: relative;
      align-items: center;
      padding: 10px 10px 10px 47px;
      font-size: 18px;
      line-height: 16px;
      background-color: #fff;
      .icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 36px;
        height: 36px;
        background: $color-secondary-accent;
        .svg-icon {
          margin: 6px;
          width: 24px;
          height: 24px;
          fill: $color-white;
        }
      }
    }

    &-inner {
      // padding: 12px 32px;
      overflow-y: auto;
      position: relative;
      /* Track */
      // &::-webkit-scrollbar-track {
      //   background: rgba(0, 0, 0, 0) !important;
      // }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $color-tertiary-dark !important;
        // border: 3px solid #fff !important;
      }
      &__absolute-wrap {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      &__no-data {
        color: $secondary_accent_color;
        font-size: 30px;
        font-weight: bold;
      }
    }
  }
}
</style>
