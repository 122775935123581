<template>
  <v-container fill-height pa-0 fluid class="pre-game" ref="expo">
    <v-layout column>
      <v-flex d-flex>
        <v-layout column>
          <v-flex shrink>
            <Header
              :headline="$theme.getMeta('hideExpoText') ? undefined : 'EXPO'"
            >
              <v-layout row class="inner-header-row">
                <v-flex
                  xs6
                  d-flex
                  align-center
                  class="page-headline"
                  v-slot:potato
                >
                  <v-layout>
                    <v-flex
                      shrink
                      class="remote-reception-text"
                      d-flex
                      align-center
                    >
                      {{ expoText }}
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs6 d-flex pr-2>
                  <v-layout row justify-end>
                    <v-flex shrink d-flex align-center v-if="isHost">
                      <span
                        v-if="isHost"
                        @click="toggleDeveloperMode"
                        class="time-link"
                      >
                        DEVELOPER MODE ({{ developer ? "ON" : "OFF" }})
                      </span>
                      <span
                        v-if="!!user.super"
                        @click="runUserSpread"
                        class="time-link"
                      >
                        USER ASSIGN
                      </span>
                      <span @click="editClient" class="time-link">
                        EDIT EVENT
                      </span>
                      <span
                        v-if="isSuper"
                        @click.alt="resetTimes($event)"
                        class="time-link"
                      >
                        RESET 5 MINUTES
                      </span>
                    </v-flex>
                    <template v-if="!!user">
                      <v-flex
                        shrink
                        pr-1
                        d-flex
                        align-center
                        class="logged-in-as-text"
                      >
                        Logged in as:
                      </v-flex>
                      <v-flex
                        shrink
                        d-flex
                        align-center
                        class="logged-in-user-name"
                        v-once
                      >
                        <UserName
                          :firstname="user.firstname"
                          :lastname="user.lastname"
                        />
                      </v-flex>
                    </template>
                    <v-flex v-once shrink d-flex align-center>
                      <div
                        class="logout-btn-wrap"
                        @click="$router.push('/logout')"
                      >
                        <div class="logout-btn">LOG OUT</div>
                      </div>
                      <FullscreenToggle
                        class="ml-2 self-center"
                        style="position: relative; z-index: 3"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </Header>
          </v-flex>
          <v-flex shrink d-flex class="live-chat-row">
            <v-layout row>
              <v-flex xs4 d-flex class="reception-client-logo-container-wrap">
                <v-flex
                  d-flex
                  class="reception-client-logo-container video-container"
                >
                  <video-play-pause-btn
                    v-if="playAnnouncement"
                    :state="isVideoPaused ? 'play' : 'pause'"
                    class="video-container__btn"
                    @click="
                      isVideoPaused ? playVideo({ rest: false }) : pauseVideo()
                    "
                  ></video-play-pause-btn>
                  <video
                    ref="video"
                    autoplay
                    class="video-an video-normal"
                    :src="announcementClientURL"
                    @ended="onVideoEnded"
                    @loadeddata="onLoadVideo"
                    v-on="videoListeners"
                    :class="{ flipped: announcementClientURL_flipped }"
                  />
                  <transition name="fade-in-transition-yes" mode="out-in">
                    <div
                      v-show="!playAnnouncement"
                      class="reception-client-image"
                      :style="{ 'background-image': announcementImageStyle }"
                    >
                      <template v-if="!client && !client.imageUrl">
                        NO LOGO
                      </template>
                      <a
                        v-if="isHost || isSpeaker"
                        href="#"
                        class="btn-client-intro btn-client-intro--record"
                        @click.prevent="recordClientAnnouncement"
                      >
                        <svg-icon name="record" />
                      </a>
                      <a
                        v-else-if="announcementClientURL && !isHost"
                        href="#"
                        class="btn-client-intro"
                        @click.prevent="playVideo"
                      >
                        <svg-icon name="play" />
                      </a>
                    </div>
                  </transition>
                </v-flex>
              </v-flex>
              <v-flex>
                <LiveChat
                  @onDisconnect="onDisconnect"
                  @onGameClick="onGameCardSelectEvent"
                  @onPlayerInvite="onInvite"
                  @onFlashGameSelect="onFlashGameSelect"
                  :flashGames="flashCardGames"
                  :selectedGameID="selectedGameID"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex d-flex>
            <v-layout row>
              <SideBar
                class="reception-side-bar xs4 grow d-flex"
                v-if="!!clientID"
                :isGamePlayedByUser="isGamePlayedByUser"
                :selectedUserID="selectedUserID"
                :selectedGameID="actualSelectedGameID"
                :games="sortedGamesArray"
                :tournament="tournament"
                @onUserSelect="onUserSelect"
                @addTime="addTime"
                @removeTime="removeTime"
                @onGameCardSelectEvent="onGameCardSelectEvent"
                ref="side_bar"
              />
              <section class="game-info__wrapper">
                <transition name="notification-transition" mode="out-in">
                  <article
                    v-if="!!notification"
                    class="notification"
                    :key="notificationKey"
                  >
                    <v-layout class="notification__text" align-center>
                      <v-flex class="text">
                        {{ notification }}
                        <span
                          v-if="notification == notifications.flashgame"
                          class="countdown"
                        >
                          &nbsp;
                          <div>3<br />2<br />1</div>
                        </span>
                      </v-flex>
                      <a
                        v-if="!!client.returnUrl"
                        class="return-url flex shrink fill-height"
                        :class="returnUrlLogo"
                        :href="client.returnUrl"
                        target="_blank"
                      >
                        <v-layout fill-height align-center>
                          {{ returnUrlText }}
                        </v-layout>
                      </a>
                    </v-layout>
                  </article>
                </transition>
                <!-- {{ Date.now() }} -->
                <TournamentStandings
                  v-if="!!(tournament && showTournamentStanding && activeRound)"
                  :tournament="tournament"
                  :roundList="roundList"
                  :activeRound="activeRound"
                  @onSelect="onUserSelect"
                  @onGameSelect="onGameCardSelectEvent"
                />
                <v-flex
                  d-flex
                  class="game-info-container"
                  v-else-if="!!selectedGame"
                >
                  <GameInfo
                    v-if="isStandard(selectedGame)"
                    :selectedUserID="selectedUserID"
                    :isTournamentWinner="isTournamentWinner"
                    :key="`game-info-${selectedGame.id}-${selectedGame.startTimestamp}`"
                    :game="selectedGame"
                    :announcementVideo="announcementGameURL"
                    :isPlayedByUser="isGamePlayedByUser(selectedGame)"
                    @onJoinGame="onJoinGame"
                    @onLeaveGame="onLeaveGame"
                    @onEnterGame="onEnterGame"
                    @onBookGame="onBookGame"
                    @onSelect="onUserSelect"
                    @onMessage="onMessage"
                    @onGameIntro="recordGameAnnouncement"
                  />
                  <GameGreenRoom
                    v-if="isGreenRoom(selectedGame)"
                    :selectedUserID="selectedUserID"
                    :isTournamentWinner="isTournamentWinner"
                    :key="`game-info-${selectedGame.id}-${selectedGame.startTimestamp}`"
                    :game="selectedGame"
                    :announcementVideo="announcementGameURL"
                    :isPlayedByUser="isGamePlayedByUser(selectedGame)"
                    @onJoinGame="onJoinGame"
                    @onLeaveGame="onLeaveGame"
                    @onEnterGame="onEnterGame"
                    @onBookGame="onBookGame"
                    @onSelect="onUserSelect"
                    @onMessage="onMessage"
                    @onGameIntro="recordGameAnnouncement"
                  />
                  <GameBroadcast
                    v-if="isBroadcast(selectedGame)"
                    :selectedUserID="selectedUserID"
                    :key="`game-info-${selectedGame.id}-${selectedGame.startTimestamp}`"
                    :game="selectedGame"
                    :announcementVideo="announcementGameURL"
                    :isPlayedByUser="isGamePlayedByUser(selectedGame)"
                    @onJoinGame="onJoinGame"
                    @onLeaveGame="onLeaveGame"
                    @onEnterGame="onEnterGame"
                    @onBookGame="onBookGame"
                    @onSelect="onUserSelect"
                    @onMessage="onMessage"
                    @onGameIntro="recordGameAnnouncement"
                  />
                  <GameYouTube
                    v-if="isVideo(selectedGame)"
                    :game="selectedGame"
                    :can-play="noAnnouncement && isExpoTourCompleted"
                  />
                  <GameFlashCards
                    v-else-if="isFlashCards(selectedGame)"
                    :selectedUserID="selectedUserID"
                    :key="`flash-card-game-${selectedGame.id}`"
                    :game="selectedGame"
                    :announcementVideo="announcementGameURL"
                    @onJoinGame="onJoinGame"
                    @onLeaveGame="onLeaveGame"
                    @onEnterGame="onEnterGame"
                    @onBookGame="onBookGame"
                    @onSelect="onUserSelect"
                    @onMessage="onGameInfoMessage"
                    @onGameIntro="recordGameAnnouncement"
                  />
                </v-flex>
                <v-flex
                  v-else-if="!!gamesArray && !!gamesArray.length"
                  d-flex
                  align-center
                  class="loading-text-game-section"
                >
                  LOADING...
                </v-flex>
              </section>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-flex
          :style="{ visibility: selectedUserID ? 'visible' : 'hidden' }"
          class="overlay"
          @mousemove.self="onMouseLeave"
        >
          <transition name="popup">
            <Profile
              v-if="!!selectedUserID"
              :style="{ left: `${x}px`, top: `${y}px` }"
              class="profile-popup"
              :class="popupClass"
              :key="`user-profile-${selectedUserID}`"
              :userID="selectedUserID"
              :clientID="clientID"
              @onInvite="onInvite"
              @onJoin="onJoin"
              @onGameClick="onGameCardSelectEvent"
            />
          </transition>
        </v-flex>
      </v-flex>
    </v-layout>
    <Popup
      :header="recordAnnouncementHeader"
      :isOpen="showRecordingDialog"
      @onClose="showRecordingDialog = false"
    >
      <RecordVideo
        :clientID="clientID"
        :gameID="recordAnnouncementGameID"
        :videoTarget="recordAnnouncementTarget"
        @onPosted="clientAnnouncementPosted"
      />
    </Popup>
    <UserEditor v-if="isHost" />
    <ExpoTour
      v-if="!isExpoTourCompleted && !client.noExpoTour"
      :expoHTML="expoHTML"
      :isHost="isHost"
      @completeExpoTour="onExpoTourCompleted"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import { VideoMixin } from "../mixins/Video"
import Header from "@/components/GroupTeams/Common/Header"
import Profile from "@/components/Profile"
import GameInfo from "@/components/GameInfo"
import GameGreenRoom from "@/components/GameGreenRoom"
import GameYouTube from "@/components/GameYouTube"
import GameBroadcast from "@/components/GameBroadcast"
import GameFlashCards from "@/components/GameFlashCards"
import LiveChat from "@/components/LiveChat"
import Popup from "@/components/Popups/Popup"
import UserName from "@/components/GroupTeams/Common/User/UserName"
import SideBar from "@/pages/SideBar"
import RecordVideo from "@/components/Recording/RecordVideo"
import ExpoTour from "@/components/ExpoTour"
import VideoPlayPauseBtn from "@/components/VideoPlayPauseBtn.vue"
import TournamentStandings from "@/components/Tournament/TournamentStandings"
import FullscreenToggle from "@/components/FullscreenToggle.vue"
const UserEditor = () => import("@/components/UserEditor.vue")

import * as moment from "moment"
import { TARGET_GAME, TARGET_CLIENT } from "@/store/RecordingModule"
import { ACTION_TYPES } from "@/store/TournamentModule"
import { Role } from "@/helpers"
import _ from "lodash"
import {
  getKeynoteVisited,
  setKeynoteVisited
} from "@/services/localstorage.service"

import { runUserSpreadWorker } from "@/services/api.service"
import { TournamentService } from "@/services/tournament/tournament.service"

const FLASH_GAME_TYPE = "Flash Cards"

const defaultBrandingUrl = require("../assets/default-branding.png")

import { fetchGameTeamsObject } from "@/services/game.service"

export default {
  name: "PreGame",
  mixins: [VideoMixin],
  components: {
    TournamentStandings,
    UserEditor,
    SideBar,
    Header,
    Profile,
    GameInfo,
    GameYouTube,
    GameGreenRoom,
    GameBroadcast,
    GameFlashCards,
    LiveChat,
    Popup,
    UserName,
    RecordVideo,
    VideoPlayPauseBtn,
    ExpoTour,
    FullscreenToggle
  },
  data() {
    return {
      announcementClientURL: null,
      announcementClientURL_delayed: null,
      announcementClientURL_flipped: false,
      recordAnnouncementTarget: null,
      recordAnnouncementGameID: null,
      noAnnouncement: false,
      playAnnouncement: false,
      timeout: null,
      x: 0,
      y: 0,
      selectedUserID: null,
      userTimer: null,
      requestUserID: null,
      requestRoomID: null,
      showRecordingDialog: false,
      selectedGameID: null,
      popupClass: null,
      isUserInitializing: false,
      flashCardTimeout: null,
      initializing: false,
      isDefaultKeynoteViewed: false,
      expoHTML: null,
      activeRound: null,
      updateTime: 0,
      interval: null
    }
  },
  async created() {
    try {
      this.initializing = true

      const promises = [
        this.updateUsers(),
        this.$store.dispatch("livechat/subscribe"),
        this.$store.dispatch("pregame/subscribeToClientGames"),
        this.$store.dispatch("pregame/subscribeTournaments", {
          clientID: this.clientID
        })
      ]

      if (!this.isHost) {
        promises.push(
          this.$store.dispatch("pregame/fetchUserPlayedGames", {
            clientID: this.clientID,
            userID: this.user.id
          })
        )
      }

      await Promise.all(promises)

      const userGame =
        this.userGameID && this.games ? this.games[this.userGameID] : null

      const isUserGameInactive = userGame
        ? !!userGame.endTimestamp ||
          !!userGame.deactivate ||
          !!userGame.deletedTimestamp ||
          !!userGame.ondeck
        : false

      if (
        (isUserGameInactive && !this.isHost) ||
        (this.isHost &&
          userGame &&
          (userGame.endTimestamp || userGame.deletedTimestamp))
      ) {
        await this.$store.dispatch("auth/deinitializeGame")
      }

      if (!this.isHost) {
        const obj = { selected: false }
        if (isUserGameInactive || !this.userGameID) {
          obj.noGameTimestamp = Date.now()

          if (this.isSpectator) {
            obj.role = Role.Player
          }
        }
        await this.$store.dispatch("updateUser", { userID: this.user.id, obj })
      }

      if (!this.isHost) {
        await this.$store.dispatch("updateUserClientPresence", {
          userID: this.user.id,
          clientID: this.clientID
        })
      }

      const keynote = this.getDefaultKeynote(this.games)
      this.isDefaultKeynoteViewed = keynote
        ? getKeynoteVisited(keynote.theKey)
        : false
      if (this.userGameID) {
        this.onGameCardSelect({ gameID: this.userGameID })
      } else if (this.firstGameID) {
        this.onGameCardSelect({ gameID: this.firstGameID })
      } else if (keynote) {
        this.onGameCardSelect({ gameID: keynote.theKey })
      }

      // Subscription for the client
      this.subscribeAnnouncementVideo({ clientID: this.clientID })
      this.userTimer = setInterval(this.updateUsers, 10000)

      console.log("this.roundList", this.roundList)

      if (this.roundList.length) {
        // run watcher only if tournament is exists
        this.$watch("time", async value => {
          const activeRound = TournamentService.getActiveRound(this.roundList)
          // update every 10 sec

          console.log("activeRound", activeRound)
          console.log("this.activeRound", this.activeRound)

          const updateTime = Math.round(value / 1000 / 10)
          const isTheSameRound =
            this.activeRound &&
            this.activeRound.originalGameID === activeRound.originalGameID

          console.log("isTheSameRound", isTheSameRound)

          console.log("this.updateTime", this.updateTime)
          console.log("updateTime", updateTime)

          if (!isTheSameRound || this.updateTime !== updateTime) {
            console.log("UPDATE!!")
            this.updateTime = updateTime
            const round = _.cloneDeep(activeRound)

            console.log("round", round)

            const tournamentID = this.tournament && this.tournament.id
            const activeRoundGamesID = this.gamesArray
              .filter(
                game =>
                  game.originalGameID === round.originalGameID &&
                  game.tournamentID === tournamentID
              )
              .map(game => game.id)

            console.log("activeRoundGamesID", activeRoundGamesID)

            const promises = activeRoundGamesID.map(gameID =>
              fetchGameTeamsObject({ orgID: this.orgID, gameID })
            )

            const groups = await Promise.all(promises)
            const teamsToMergeIn = {}

            groups.forEach((group, index) => {
              for (const teamID in group) {
                const team = group[teamID]
                if (!team || !team.name || !team.show) continue
                const originalUsers = {
                  ...this.$store.getters["allusers/GET_USERS_BY_TEAM_ID"](
                    teamID
                  ),
                  ...this.$store.getters["allusers/GET_HOSTS_BY_GAME_ID"](
                    activeRoundGamesID[index]
                  )
                }
                const users = {}
                for (const userID in originalUsers) {
                  users[userID] = {}
                  users[userID].imageUrl = originalUsers[userID].image
                  users[
                    userID
                  ].name = `${originalUsers[userID].firstname} ${originalUsers[userID].lastname}`
                  users[userID].role = originalUsers[userID].role
                }
                teamsToMergeIn[teamID] = {
                  name: team.name,
                  score: team.totalScore,
                  icon: team.icon,
                  pending: true,
                  gameID: activeRoundGamesID[index],
                  users
                }
              }
            })

            if (!round.round.teams) round.round.teams = {}

            Object.keys(teamsToMergeIn).forEach(teamID => {
              if (!round.round.teams[teamID]) {
                round.round.teams[teamID] = teamsToMergeIn[teamID]
              }
            })

            this.activeRound = round
          }
        })
      }
      this.interval = setInterval(this.onTick, 1000)
    } catch (e) {
      await this.updateMessage(e.message)
      console.error(e)
    } finally {
      this.initializing = false
    }
  },
  mounted() {
    if (this.$cookies.get("isGamePlayed") === "true" && this.$refs.side_bar) {
      this.$refs.side_bar.tab = "games"
    }
    if (this.client.noExpoTour) this.onExpoTourCompleted()
    this.expoHTML = this.$refs.expo
  },
  async beforeDestroy() {
    clearInterval(this.userTimer)
    clearInterval(this.interval)
    const promises = [
      this.$store.dispatch("livechat/end"),
      this.$store.dispatch("livechat/unsubscribe"),
      this.$store.dispatch(
        "recording/unsubscribeAnnouncementVideo",
        this.clientID
      ),
      this.$store.dispatch("pregame/unsubscribeFromClientGames"),
      this.$store.dispatch("pregame/unsubscribeTournaments"),
      this.$store.dispatch("allusers/purgeUsers"),
      this.purgeUser()
    ]
    if (this.selectedGameID) {
      promises.push(
        this.$store.dispatch(
          "recording/unsubscribeAnnouncementVideo",
          this.selectedGameID
        )
      )
    }
    await Promise.all(promises)
  },
  watch: {
    activeRound: {
      handler(newValue, oldValue) {
        const [firstRound] = this.roundList || []
        if (
          newValue &&
          oldValue &&
          newValue.originalGameID === oldValue.originalGameID
        )
          return
        if (
          newValue &&
          firstRound &&
          (newValue.originalGameID === firstRound.originalGameID ||
            this.sortedGamesArray.length === 0)
        ) {
          this.showTournamentGrid()
        }
      },
      immediate: true
    },
    async originatorTimestamp(value) {
      if (value && !this.roomGameID) {
        const [game] = this.flashCardGames || []
        if (!game) return console.warn(`No flash games available`)
        const { id: gameID } = game
        await this.onFlashGameSelect({ gameID })
      }
    },
    roomGameID(value) {
      if (value && value !== this.selectedGameID) {
        const currentGame = this.games ? this.games[this.selectedGameID] : null
        const isCurrentGameFlashCard = currentGame
          ? this.isFlashCards(currentGame)
          : false
        const game = this.games ? this.games[value] : null
        const isGameFlashCard = game ? this.isFlashCards(game) : false
        if (!isGameFlashCard) return
        if (isCurrentGameFlashCard) {
          this.selectedGameID = value
        } else {
          clearTimeout(this.flashCardTimeout)
          this.flashCardTimeout = null
          this.flashCardTimeout = setTimeout(() => {
            this.flashCardTimeout = null
            this.selectedGameID = value
          }, 4000)
        }
      }
      if (!value) {
        // console.log("NO VALUE OF ROOMID", this.user.gameID)
        if (this.userGameID) {
          this.onGameCardSelect({ gameID: this.userGameID })
        } else if (this.firstGameID) {
          this.onGameCardSelect({ gameID: this.firstGameID })
        } else {
          // For case if keynote is single games
          const keynote = this.getDefaultKeynote(this.games)
          if (keynote) {
            this.onGameCardSelect({ gameID: keynote.theKey })
          }
        }
      }
    },
    userGameID: {
      async handler(value) {
        if (this.initializing) return
        if (value && !this.isFlashCards(this.games[value])) {
          await this.onGameCardSelect({ gameID: value })
        } else {
          if (this.firstGameID)
            this.onGameCardSelect({ gameID: this.firstGameID })
          // push a user into the queue simply by updating the timestamp that
          // indicates when they landed on the reception page without a game ID
          await this.$store.dispatch("updateUser", {
            userID: this.user.id,
            obj: { noGameTimestamp: Date.now() }
          })
        }
      }
    },
    requests: {
      async handler(newValue) {
        if (!Array.isArray(newValue)) return
        const [room] = newValue
        if (!room) return
        const { roomID } = room
        console.log("room", room)
        if (this.isUserLocked || !this.isExpoTourCompleted) {
          console.log("Ignoring incoming")
          return await this.onRequestResponse({ status: false, roomID })
        }
        if (this.roomID) {
          if (this.roomUsers && this.roomUsers.length > 1) {
            console.error(
              "Ignore incoming. Room has more then one participant."
            )
            return await this.onRequestResponse({ status: false, roomID })
          }
          await this.$store.dispatch("livechat/end")
        }
        await this.onRequestResponse({ status: true, roomID })
      },
      deep: true,
      immediate: false
    },
    endTwilioConnection: {
      async handler(value) {
        if (value) {
          console.warn("Ending twilio connection due to undefined room ID")
          await this.$store.dispatch("livechat/end")
        }
      },
      immediate: true
    },
    announcementClient: {
      handler(value) {
        if (value) {
          const user =
            JSON.parse(localStorage.getItem(`user-${this.user.id}`)) || {}
          const recordings = user.recordings || []
          const stringified = JSON.stringify(value)
          if (recordings.every(obj => JSON.stringify(obj) !== stringified)) {
            recordings.unshift(value)
            localStorage.setItem(
              `user-${this.user.id}`,
              JSON.stringify({
                ...user,
                recordings: recordings.slice(0, 10)
              })
            )
            this.announcementClientURL = null
            this.announcementClientURL_flipped = value.flipped
            if (this.isExpoTourCompleted)
              setTimeout(() => {
                this.announcementClientURL = value.url
              }, 1000)
            else this.announcementClientURL_delayed = value.url
          } else this.noAnnouncement = true
        } else {
          this.noAnnouncement = true
        }
      },
      immediate: true
    },
    isExpoTourCompleted(value) {
      if (value && this.announcementClientURL_delayed)
        this.announcementClientURL = this.announcementClientURL_delayed
    }
  },
  computed: {
    ...mapState(["developer"]),
    ...mapGetters(["time"]),
    ...mapGetters("tournament", ["showTournamentStanding"]),
    ...mapGetters(["orgID", "isExpoTourCompleted"]),
    ...mapGetters("auth", [
      "user",
      "clientID",
      "client",
      "isHost",
      "isSpectator"
    ]),
    ...mapGetters("pregame", [
      "games",
      "gamesArray",
      "access",
      "userPlayedGames",
      "tournaments"
    ]),
    ...mapGetters("twilio", { isTwilioConnected: "isConnected" }),
    ...mapGetters("allusers", ["onlineUsersGroupedByGameID"]),
    ...mapGetters("livechat", {
      originatorTimestamp: "originatorTimestamp",
      isUserLocked: "isUserLocked",
      roomID: "roomID",
      room: "room",
      requests: "requestsArray",
      roomUsers: "roomUsers"
    }),
    ...mapGetters("recording", ["announcementGameURL", "announcementClient"]),
    actualSelectedGameID() {
      if (this.showTournamentStanding) return null
      return this.defaultKeynoteID && !this.roomGameID
        ? this.defaultKeynoteID
        : this.selectedGameID
    },
    isSpeaker() {
      return this.user.speaker
    },
    isTournamentWinner() {
      if (this.isHost) return false

      const finalRoundID =
        this.roundList &&
        this.roundList.length &&
        this.roundList[this.roundList.length - 1] &&
        this.roundList[this.roundList.length - 1].originalGameID

      const activeRoundID = this.activeRound && this.activeRound.originalGameID

      const teams =
        this.activeRound &&
        this.activeRound.round &&
        this.activeRound.round.teams

      const teamsGroupedByGames = Object.entries(teams || {}).reduce(
        (acc, [id, value]) => {
          if (acc[value.gameID]) {
            acc[value.gameID].push({ ...value, id })
          } else {
            acc[value.gameID] = [{ ...value, id }]
          }
          return acc
        },
        {}
      )

      const winners = Object.values(teamsGroupedByGames).reduce((acc, val) => {
        const [winner] = val.sort((a, b) => b.score - a.score)
        if (winner) acc[winner.id] = true
        return acc
      }, {})

      return (
        !!this.user.teamID &&
        !!winners &&
        !!winners[this.user.teamID] &&
        activeRoundID !== finalRoundID
      )
    },
    notification() {
      return this.isFlashCardTimeout
        ? this.notifications.flashgame
        : this.notifications.default
    },
    notificationKey() {
      return String(this.notification).toLowerCase().replace(" ", "-")
    },
    isFlashCardTimeout() {
      return !!this.flashCardTimeout
    },
    isFlashCardSelected() {
      return !!this.selectedGame && this.isFlashCards(this.selectedGame)
    },
    notifications() {
      return {
        default: this.client.announcement,
        flashgame: "Time to Break The Ice! Get Ready in"
      }
    },
    returnUrlLogo() {
      var url = this.client.returnUrl
      if (url.includes("gotomeeting")) return "haslogo gotomeeting"
      else if (url.includes("zoom")) return "haslogo zoom"
      else return false
    },
    returnUrlText() {
      var url = this.client.returnUrl
      if (url.includes("gotomeeting")) return "Return To GoToMeeting"
      else if (url.includes("zoom")) return "Return To Zoom"
      else return "Return To Webinar"
    },
    /**
     * First game ID, that not is a default keynote
     **/
    firstGameID() {
      const game = this.sortedGamesArray.find(
        ({ defaultKeynote }) => !defaultKeynote
      )
      return game ? game.id : null
    },
    roomGameID() {
      return this.room ? this.room.gameID : null
    },
    flashCardGames() {
      return this.gamesArray.filter(
        ({ gameType, deactivate, ondeck, deletedTimestamp }) =>
          gameType == FLASH_GAME_TYPE &&
          !deactivate &&
          !ondeck &&
          !deletedTimestamp
      )
    },
    isSuper() {
      return !!this.user && !!this.user.super
    },
    userGameID() {
      return this.user ? this.user.gameID : null
    },
    selectedGame() {
      return this.games &&
        this.actualSelectedGameID &&
        this.games[this.actualSelectedGameID]
        ? {
            ...this.games[this.actualSelectedGameID],
            id: this.actualSelectedGameID
          }
        : null
    },
    recordAnnouncementGame() {
      return this.games && this.recordAnnouncementGameID
        ? {
            ...this.games[this.recordAnnouncementGameID],
            id: this.recordAnnouncementGameID
          }
        : null
    },
    endTwilioConnection() {
      return this.isTwilioConnected && !this.roomID
    },
    filteredGamesArray() {
      if (this.developer) return this.gamesArray
      return this.gamesArray.filter(
        ({
          deactivate,
          id,
          deletedTimestamp,
          ondeck,
          gameType,
          endTimestamp
        }) => {
          return (
            !endTimestamp &&
            (!deactivate || id === this.userGameID || this.isHost) &&
            !deletedTimestamp &&
            !ondeck &&
            !this.isFlashCards({ gameType })
          )
        }
      )
    },
    sortedGamesArray() {
      return _.chain(this.filteredGamesArray)
        .sortBy("startTimestamp")
        .sortBy(({ started }) => !!started)
        .sortBy(({ endTimestamp }) => !!endTimestamp)
        .sortBy(game => this.isGamePlayedByUser(game))
        .sortBy(({ id }) => id !== this.userGameID)
        .sortBy("defaultKeynote")
        .value()
    },
    announcementImageStyle() {
      if (this.client && this.client.imageUrl) {
        return `url(${this.client.imageUrl})`
      } else {
        return "url(" + defaultBrandingUrl + ")"
      }
    },
    recordAnnouncementHeader() {
      if (
        this.recordAnnouncementTarget === TARGET_GAME &&
        this.recordAnnouncementGame
      ) {
        return `Video for ${this.recordAnnouncementGame.name}`
      } else if (this.recordAnnouncementTarget === TARGET_CLIENT) {
        return `Video Announcement`
      } else {
        return ""
      }
    },
    defaultKeynoteID() {
      const keynote = this.getDefaultKeynote(this.games)
      return keynote && !this.isDefaultKeynoteViewed ? keynote.theKey : null
    },
    tournament() {
      const [key] = Object.keys(this.tournaments || {}).filter(
        key => parseInt(key) !== 0
      )
      return key && this.tournaments ? this.tournaments[key] : null
    },
    roundList() {
      return this.tournament && this.tournament.games
        ? TournamentService.getRoundList(this.tournament)
        : []
    }
  },
  methods: {
    ...mapActions("auth", ["initializeToGame"]),
    ...mapActions("recording", [
      "subscribeAnnouncementVideo",
      "unsubscribeAnnouncementVideo"
    ]),
    ...mapActions(["updateExpoTourState", "toggleDeveloperMode"]),
    ...mapActions("pregame", ["updateMessage"]),
    ...mapActions({ updateGlobalTime: "updateTime" }),
    onTick() {
      this.updateGlobalTime(Date.now())
    },
    async onExpoTourCompleted() {
      await this.updateExpoTourState(true)
      if (!this.isHost) {
        if (!this.userGameID) {
          await this.$store.dispatch("updateUser", {
            userID: this.user.id,
            obj: { noGameTimestamp: Date.now() }
          })
        }
        await this.$store.dispatch("updateUserClientPresence", {
          userID: this.user.id,
          clientID: this.clientID
        })
      }
    },
    async runUserSpread() {
      await runUserSpreadWorker(this.clientID)
    },
    async onFlashGameSelect({ gameID }) {
      if (!gameID) throw new Error(`No game ID is given`)
      if (this.roomID) {
        if (this.roomGameID !== gameID) {
          await this.$store.dispatch("livechat/updateRoomMission", null)
          await this.$store.dispatch("livechat/updateRoomGameID", { gameID })
        } else {
          this.selectedGameID = gameID
          if (this.showTournamentStanding) this.hideTournamentGrid()
        }
      } else {
        const message =
          "You need to start a video chat to play an Ice Breaker Game"
        this.updateMessage(message)
      }
    },
    onMessage(string) {
      this.updateMessage(string)
    },
    isFlashCards(game) {
      return game ? game.gameType === FLASH_GAME_TYPE : false
    },
    editClient() {
      this.$router.push({
        name: "eventSettings",
        params: { id: this.clientID }
      })
    },
    onGameInfoMessage(string) {
      if (!this.isUserInitializing) this.updateMessage(string)
    },
    isStandard({ gameType }) {
      return !gameType || gameType === "Standard"
    },
    isBroadcast({ gameType }) {
      return gameType === "Broadcast"
    },
    isGreenRoom({ gameType }) {
      return gameType === "Green Room"
    },
    isVideo(game) {
      return this.isYouTube(game) || this.isTwitch(game)
    },
    isYouTube({ gameType }) {
      return gameType === "YouTube"
    },
    isTwitch({ gameType }) {
      return gameType === "Twitch"
    },
    isGamePlayedByUser({ originalGameID, theKey }) {
      if (this.isHost) return false
      const gameID = originalGameID || theKey
      return this.userPlayedGames && !!this.userPlayedGames[gameID]
    },
    updateUsers() {
      return this.$store.dispatch("allusers/fetchUsers", {
        clientID: this.clientID
      })
    },
    onVideoEnded() {
      this.playAnnouncement = false
      this.noAnnouncement = true
    },
    onLoadVideo() {
      this.playAnnouncement = true
      this.noAnnouncement = false
    },
    playVideo({ reset } = { reset: true }) {
      if (this.announcementClientURL) {
        this.playAnnouncement = true
        if (reset) {
          this.$refs.video.currentTime = 0
        }
        this.$refs.video.play()
      }
    },
    pauseVideo() {
      this.$refs.video.pause()
    },
    addTime(gameID) {
      if (!this.isHost) return
      console.log("THE ADD TIME FUNCTION")
      const MINUTES_TO_ADD = 5
      const game = this.games[gameID]
      const newGame = { ...game }
      newGame.gameTime = newGame.gameTime + 1000 * 60 * MINUTES_TO_ADD
      newGame.started = false
      newGame.startTimestamp =
        newGame.startTimestamp + 1000 * 60 * MINUTES_TO_ADD
      newGame.theKey = gameID
      this.$store.dispatch("Games/updateGame", newGame)
    },
    removeTime(gameID) {
      if (!this.isHost) return
      console.log("THE REMOVE TIME FUNCTION")
      const MINUTES_TO_REMOVE = 1
      const game = this.games[gameID]
      const newGame = { ...game }
      newGame.gameTime = newGame.gameTime - 1000 * 60 * MINUTES_TO_REMOVE
      newGame.started = false
      newGame.startTimestamp =
        newGame.startTimestamp - 1000 * 60 * MINUTES_TO_REMOVE
      newGame.theKey = gameID
      console.log("THE GAME UPDATE", newGame)
      this.$store.dispatch("Games/updateGame", newGame)
    },
    resetTimes(event) {
      if (!this.isHost) return
      if (event.altKey || event.ctrlKey) {
        var sTime = new Date()
        const now = Date.now() + 1000 * 60 * 5
        var theTime = moment(sTime).unix()
        var arr = Object.entries(this.games)
        for (var i in arr) {
          const game = arr[i][1]
          const newGame = { ...game }
          if (newGame.deletedTimestamp || newGame.endTimestamp) continue
          newGame.gameTime = theTime + 10000
          newGame.started = false
          newGame.startTimestamp = now
          newGame.theKey = arr[i][0]
          console.log("EACH GAME", newGame)
          this.$store.dispatch("Games/updateGame", newGame)
        }
      }
    },
    async onMouseLeave() {
      await this.purgeUser()
      this.selectedUserID = null
    },
    showTournamentGrid() {
      return this.$store.dispatch(
        `tournament/${ACTION_TYPES.SHOW_TOURNAMENT_STANDING}`,
        true
      )
    },
    hideTournamentGrid() {
      return this.$store.dispatch(
        `tournament/${ACTION_TYPES.SHOW_TOURNAMENT_STANDING}`,
        false
      )
    },
    async purgeUser() {
      await Promise.all([
        this.$store.dispatch("profile/unsubscribeFromUserRoom"),
        this.$store.dispatch("profile/unsubscribeFromUserRoomID"),
        this.$store.dispatch("profile/purgeData"),
        this.$store.dispatch("profile/unsubscribeFromUser")
      ])
    },
    async onGameCardSelectEvent({ gameID, scroll }) {
      this.hideTournamentGrid()
      if (this.defaultKeynoteID && !this.isDefaultKeynoteViewed) {
        setKeynoteVisited(this.defaultKeynoteID)
        this.isDefaultKeynoteViewed = true
      } else if (scroll) {
        this.selectedUserID = null
        if (this.$refs.side_bar && this.$refs.side_bar.tab !== "games") {
          this.$refs.side_bar.tab = "games"
        }
      }
      this.onGameCardSelect({ gameID })
    },
    async onGameCardSelect({ gameID }) {
      await this.updateUsers()
      const prevSelectedGameID = this.selectedGameID
      this.selectedGameID = gameID
      if (prevSelectedGameID === gameID) return
      // Unsubscribe from previous game video
      await this.unsubscribeAnnouncementVideo(prevSelectedGameID)
      // Subscribe to new game video
      await this.subscribeAnnouncementVideo({
        clientID: this.clientID,
        targetID: this.selectedGameID
      })
    },
    async onRequestResponse({ status, roomID }) {
      this.requestUserID = null
      this.requestRoomID = null
      if (status) {
        try {
          await this.$store.dispatch("livechat/accept", { roomID })
        } catch (e) {
          console.error(e)
          await this.$store.dispatch("livechat/end")
        }
      } else {
        await this.$store.dispatch("livechat/decline", { roomID })
      }
    },
    async onBookGame({ gameID, _this }) {
      try {
        await this.initializeToGame({ gameID, clientID: this.clientID })
        const game = this.games[gameID]
        const gameStartTime = (game && game.startTimestamp) || 0
        const name = game ? game.externalName || game.name : null
        if (!this.isHost && gameStartTime > 0) {
          const string = moment(gameStartTime).format("h:mm a")
          const message = `Thanks for joining ${name}. You will be escorted in at ${string}`
          await this.updateMessage(message)
        }
        await this.updateUsers()
      } catch (e) {
        this.updateMessage(e.message)
        console.error(e)
      }
      _this.loading = false
    },
    async onJoinGame({ gameID, _this }) {
      try {
        await this.initializeToGame({ gameID, clientID: this.clientID })
        await this.updateUsers()
      } catch (e) {
        this.updateMessage(e.message)
        console.error(e.message)
      }
      _this.loading = false
    },
    async onLeaveGame({ _this }) {
      try {
        await this.$store.dispatch("auth/deinitializeGame")
        if (this.user.role === Role.Spectator) {
          await this.$store.dispatch("updateUser", {
            userID: this.user.id,
            obj: { role: Role.Player }
          })
        }
        await this.updateUsers()
      } catch (e) {
        this.updateMessage(e.message)
        console.error(e)
      }
      _this.loading = false
    },
    async onEnterGame({ gameID }) {
      // console.log("ENtering, ", gameID)
      await this.$store.dispatch("fetchMissions", gameID)
      await this.$store.dispatch("bringBackActualGame")
      var arr = Object.values(this.$store.getters.missions)
      await this.$store.dispatch("setLocalCurrentMission", arr[0])
      // console.log("setting local mistsiton", arr[0])
      // console.log("LOCAL GAME ID SET", this.$store.getters.gameID)
      const game = this.games ? this.games[gameID] : null
      const gameStatus = game && game.started ? "started" : "boarding"
      if (gameStatus === "started") {
        await this.$navigator.navigateTo(`/game/${this.clientID}${gameID}`)
      } else {
        await this.$navigator.navigateTo(`/lobby/${this.clientID}${gameID}`)
      }
    },
    async onInvite({ userID }) {
      await this.$store.dispatch("livechat/invite", { userID })
    },
    async onJoin({ roomID }) {
      try {
        await this.$store.dispatch("livechat/enter", { roomID })
      } catch (e) {
        console.error(e)
        this.updateMessage(e.message)
      }
    },
    async onDisconnect() {
      await this.$store.dispatch("livechat/end")
    },
    async onUserSelect({ userID, x, y, height, width }) {
      const popupWidth = 380
      const required = x + popupWidth + 10
      const available = window.innerWidth
      console.log("required", required)
      console.log("available", available)
      if (available < required + 10) {
        this.x = x - popupWidth + width + 5
        this.y = y - 140 + height + 5
        this.popupClass = "right"
      } else {
        this.x = x - 5
        this.y = y - 140 + height + 5
        this.popupClass = "left"
      }
      this.selectedUserID = userID
    },
    getGameNameByID({ gameID }) {
      return this.games ? this.games[gameID] : null
    },
    recordGameAnnouncement(gameID) {
      this.recordAnnouncementGameID = gameID
      this.recordAnnouncementTarget = TARGET_GAME
      this.showRecordingDialog = true
    },
    recordClientAnnouncement() {
      this.recordAnnouncementGameID = null
      this.recordAnnouncementTarget = TARGET_CLIENT
      this.showRecordingDialog = true
    },
    clientAnnouncementPosted() {
      // Close popup after half second
      setTimeout(() => (this.showRecordingDialog = false), 500)
    },
    getDefaultKeynote(games) {
      if (!games) return null
      return Object.values(games).find(
        ({ defaultKeynote, deactivate, ondeck, deletedTimestamp }) =>
          defaultKeynote && !deactivate && !ondeck && !deletedTimestamp
      )
    }
  }
}
</script>

<style lang="scss">
@keyframes countdown {
  0% {
    top: 0;
  }
  20% {
    top: 0;
  }
  25% {
    top: -100%;
  }
  40% {
    top: -100%;
  }
  45% {
    top: -200%;
  }
  60% {
    top: -200%;
  }
  65% {
    top: -300%;
  }
  80% {
    top: -300%;
  }
  85% {
    top: -400%;
  }
  100% {
    top: -400%;
  }
}

.pre-game {
  background-color: $color-tertiary-dark;
  position: relative;
  overflow: hidden;
  line-height: 1;
  position: relative;
  background-image: url("../assets/honeycomb-texture.png");
  background-repeat: repeat;
  background-size: 35px;

  .loading-text-game-section {
    color: #fff;
    font-size: 30px;
    font-weight: 700;
  }

  // current game over team transition
  .notification-transition-enter-active,
  .notification-transition-leave-active {
    transition: all ease 0.3s;
  }
  .notification-transition-enter,
  .notification-transition-leave-to {
    transform: translateY(-80px) !important;
  }

  ::-webkit-scrollbar {
    width: 14px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: $color-primary-dark;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $primary_accent_color;
    border: 3px solid $color-primary-dark;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $primary_accent_color;
    filter: brightness(1.2);
  }
  & > .layout {
    min-width: 1024px;
  }
  .pre-game-user-list .user .outline {
    border-color: $color-white !important;
    &:before {
      border-color: $color-white !important;
    }
  }
  .fade-in-transition-yes-enter-active,
  .fade-in-transition-yes-leave-active {
    transition: opacity ease 0.3s;
    opacity: 1;
  }
  .fade-in-transition-yes-enter {
    opacity: 0;
  }
  .fade-in-transition-yes-leave-to {
    opacity: 0;
  }
  .rtb-header {
    position: relative;
    z-index: 10;
  }
  .notification {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    position: relative;
    z-index: 0;
    top: 0;
    display: flex;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    transform: translateY(0);
    &__text {
      position: relative;
      text-transform: uppercase;
      background-color: rgba($color-black, 0.33);
    }

    .text {
      padding: 10px;
    }

    .return-url {
      display: inline-block;
      text-decoration: none;
      padding: 10px 12px;
      background-color: rgba($color-black, 0.33);
      text-transform: none;
      text-decoration: none;
      color: $color-white;
      white-space: nowrap;
      font-size: 13px;
      &.haslogo {
        padding-left: 38px;
        background-repeat: no-repeat;
        background-size: 19px;
        background-position: left 12px center;
      }
      &.gotomeeting {
        background-image: url("../assets/meeting-platforms/gotomeeting.svg");
      }
      &.zoom {
        background-image: url("../assets/meeting-platforms/zoom.svg");
      }
    }

    .countdown {
      display: inline-block;
      position: relative;
      overflow: hidden;
      width: 10px;
      vertical-align: top;
      div {
        position: absolute;
        text-align: center;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        animation-duration: 5s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
        animation-name: countdown;
      }
    }
  }
  .game-info__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $max_width;
    margin: 0 auto;
    align-items: center;
    z-index: 0;
  }
  .game-info-container {
    padding-bottom: 20px;
    width: calc(100% - 48 * 2px);
    margin-top: 24px;
  }
  .inner-header-row {
    position: relative;
    z-index: 1;
  }
  .popup-enter-active,
  .popup-leave-active {
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .popup-enter,
  .popup-leave-to {
    opacity: 0;
    transform: scale(0.8);
  }
  .live-chat-row {
    background-color: $color-white;
    z-index: 2;
  }
  .side-bar-content-wrap {
    position: relative;
  }
  .side-bar-content {
    background-color: $color-primary-dark;
    width: 99.9%;
  }
  .game-title-row {
    background-color: #fff;
  }
  .welcome-text {
    color: $primary_accent_color;
    font-size: 22px;
    font-weight: bold;
  }
  .get-ready-text {
    font-size: 18px;
    color: $color-primary-dark;
  }
  .profile-popup {
    @extend .rtb-box-shadow;
    position: fixed;
    left: 200px;
    top: 200px;
    width: 380px;
    height: 140px;
    background-color: rgba(0, 0, 0, 0.85);
    &.left {
      transform-origin: 5% 85%;
    }
    &.right {
      transform-origin: 95% 85%;
    }
  }
  .video-an {
    object-fit: cover;
    width: 100%;
    /*22px is unactive tab*/
    height: calc(100% - 22px);
  }
  .time-link,
  .record-link {
    cursor: pointer;
    position: relative;
    z-index: 99;
    margin-right: 5px;
    font-size: 11px;
    text-decoration: underline;
  }
  .time-link {
    color: $logo_color;
  }
  .side-bar-content.scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    height: 100%;
    width: 99.9%;
  }
  .overlay {
    z-index: 99;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .popup {
    width: 100%;
    height: 100%;
    min-width: 250px;
    max-width: 40vmin;
    min-height: 250px;
    max-height: 40vmin;
  }
  .max-width {
    max-width: $max_width !important;
    margin-left: auto;
    margin-right: auto;
  }
  .side-bar-tabs {
    height: 25px;
    width: 99.95%;
    overflow: hidden;
    border-bottom: 2px solid $primary_accent_color;
  }
  .tab {
    position: relative;
    user-select: none;
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;
    color: $color-white;
    background-color: $color-tertiary-dark;
    font-size: 15px;
    line-height: 15px;
    line-height: 1;
    &:not(.selected) {
      margin-top: 3px;
      padding-top: 1px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: $primary_accent_color;
        background: $color-primary-dark;
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          width: 28px;
          height: 100%;
          background: $color-primary-dark;
        }
        &.rooms-tab:after {
          left: 100%;
        }
        &.players-tab:after {
          right: 100%;
        }
      }
    }
    &.selected {
      background-color: $primary_accent_color;
      box-shadow: 0px 4px 10px 1px rgba($color-black, 0.5);
      font-weight: 600;
      z-index: 1;
      min-width: 52%;
      &.rooms-tab {
        border-top-right-radius: 4px;
      }
      &.players-tab {
        border-top-left-radius: 4px;
      }
    }
  }
  .logged-in-as-text {
    color: $color-secondary-light;
    font-size: 12px;
    text-transform: uppercase;
    padding-left: 10px;
  }
  .reception-side-bar {
    flex-basis: 400px;
    max-width: 400px;
    position: relative;
    z-index: 2;
    margin-top: -25px;
    box-shadow: 4px 22px 3px -3px rgba(0, 0, 0, 0.5);
  }
  .reception-client-logo-container-wrap {
    flex-basis: 400px;
    max-width: 400px;
    min-width: 400px;
    position: relative;
  }
  .reception-client-logo-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    .reception-client-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 22px);
      z-index: 2;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-color: $primary_accent_color;
    }
    a.btn-client-intro {
      position: absolute;
      right: 12px;
      bottom: 12px;
      border-radius: 50%;
      white-space: nowrap;
      background-repeat: no-repeat;
      background-size: 22px;
      background-position: left;
      background-color: transparent;
      color: $color-white;
      text-decoration: none;
      transition: all 0.1s ease-out;
      color: $secondary_accent_color;
      svg {
        width: 20px;
        height: 20px;
      }
      &--record {
        padding: 8px;
        background-color: #fff;
      }
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
  .logged-in-user-name {
    color: $color-secondary-light;
    text-transform: uppercase;
    font-weight: bold;
    padding-right: 15px;
  }
  .potato-toggle {
    padding: 0;
    margin: 0;
    font-size: 11px;
    z-index: 99;
    .v-input__slot {
      margin: 0;
    }
    .v-messages {
      display: none;
    }
    .v-label {
      color: #fff;
      font-size: 12px;
    }
  }
  .logout-btn-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .logout-btn {
      white-space: nowrap;
      margin-top: 1px;
      line-height: 12px;
      position: relative;
      color: #fff;
      font-size: 10px;
      border-radius: 0.75rem;
      padding: 1px 12px 0;
      border: 1px solid $primary_accent_color;
      z-index: 99;
      user-select: none;
      cursor: pointer;
      &:hover {
        border: 1px solid $secondary_accent_color;
      }
    }
  }
}
</style>
