<template>
  <v-flex shrink d-flex>
    <button
      @click.stop="onClick"
      class="game-card-btn"
      :disabled="isButtonDisabled"
      @mouseenter="isButtonHovered = true"
      @mouseleave="isButtonHovered = false"
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        size="25"
        :color="progressColor"
        class="game-card-btn__progress"
      />
      <template v-else>{{ gameButtonText }}</template>
    </button>
    <v-flex
      v-if="status == 'running'"
      class="countdown running"
      @click="changeTime"
    >
      In Progress
    </v-flex>
    <v-flex v-else-if="status == 'ended'" class="countdown running">
      Ended
    </v-flex>
    <v-flex v-else-if="status == 'played'" class="countdown running">
      Played
    </v-flex>
    <v-flex
      v-else-if="status == 'boarding'"
      class="countdown running"
      @click="changeTime"
    >
      Boarding
    </v-flex>
    <v-flex
      @click="changeTime"
      v-else-if="status == 'pending' && !!gameStartTime"
      class="countdown"
    >
      Starts in <PreGameCountdown :endTime="gameStartTime" />
    </v-flex>
  </v-flex>
</template>

<script>
import PreGameCountdown from "@/components/PreGameCountdown"
import { mapGetters } from "vuex"
import { Role } from "../helpers"
export default {
  name: "newButton",
  components: {
    PreGameCountdown
  },
  props: {
    game: Object,
    time: Number,
    gameStartTime: Number,
    gameEndTime: Number,
    isGameBooked: Boolean,
    isSelected: Boolean,
    isPlayedByUser: Boolean,
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      isButtonHovered: false,
      isGameTime: false,
      loadingValue: 30
    }
  },
  methods: {
    changeTime(event) {
      if (event.altKey || event.ctrlKey) {
        console.log("REMOVE TIME")
        this.$emit("removeTimeToGame")
      }
      if (event.shiftKey) {
        console.log("ADD TIME")
        this.$emit("addTimeToGame")
      }
    },
    onClick() {
      this.$emit("onClick")
    }
  },
  watch: {
    globalTime: {
      handler(value) {
        const isGameTime = value > this.gameStartTime && !!this.gameStartTime
        if (this.isGameTime !== isGameTime) this.isGameTime = isGameTime
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({ globalTime: "time" }),
    ...mapGetters("auth", ["role"]),
    isGameEnded() {
      return !!this.gameEndTime
    },
    isSpectator() {
      return this.role === Role.Audit || this.role === Role.Spectator
    },
    isBoarding() {
      return this.isGameTime && !this.game.started
    },
    isStandard() {
      return !this.game.gameType || this.game.gameType == "Standard"
    },
    status() {
      if (this.isStandard) {
        if (this.isPlayedByUser) {
          return "played"
        } else if (this.isGameEnded) {
          return "ended"
        } else if (this.isBoarding) {
          return "boarding"
        } else if (this.isGameTime) {
          return "running"
        } else {
          return "pending"
        }
      } else {
        return null
      }
    },
    gameButtonText() {
      if (this.isGameBooked) {
        if (this.isSpectator) {
          return "YOU ARE SPECTATING THIS GAME"
        } else {
          return "YOU ARE PLAYING THIS GAME"
        }
      } else if (this.isSelected) {
        return "CURRENTLY VIEWING"
      } else {
        return "VIEW GAME ROOM"
      }
    },
    /**
     * @description Prevent from emitting selection event
     */
    isButtonDisabled() {
      return this.loading === true || this.isSelected === true
    },
    progressColor() {
      return this.isButtonHovered === true ? "#fff" : "#f36523"
    }
  }
}
</script>

<style lang="scss">
.game-card-btn {
  align-items: center;

  &__progress {
    margin: {
      top: 0;
      bottom: 0;
    }
  }
}
</style>
