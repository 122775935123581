var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "game-search", attrs: { "d-flex": "" } },
    [
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !!_vm.truncatedGames.length,
              expression: "!!truncatedGames.length"
            }
          ],
          ref: "scrollable",
          staticClass: "scrollable",
          attrs: { column: "" },
          on: {
            "&scroll": function($event) {
              return _vm.onScroll($event)
            }
          }
        },
        _vm._l(_vm.truncatedGames, function(game) {
          return _c(
            "v-flex",
            {
              key: "game-card-" + game.id + "-" + game.startTimestamp,
              attrs: { "d-flex": "", shrink: "" }
            },
            [
              _c("GameCard", {
                ref: "game_card_" + game.id,
                refInFor: true,
                staticClass: "pre-game-card",
                attrs: {
                  game: game,
                  selected: _vm.selectedGameID,
                  isPlayedByUser: _vm.isGamePlayedByUser(game)
                },
                on: {
                  addTimeToGame: _vm.addTime,
                  removeTimeToGame: _vm.removeTime,
                  onSelect: _vm.onGameCardSelectEvent
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }