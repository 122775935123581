<template>
  <v-flex d-flex class="game-info-youtube">
    <v-layout column>
      <v-flex class="game-card-header" shrink>
        <v-layout column>
          <v-flex d-flex class="game-title">
            <v-layout row>
              <v-flex d-flex class="game-title-name">
                <v-layout row justify-center>
                  <v-flex>
                    <v-flex v-once shrink class="game-name">
                      <h1>{{ name }}</h1>
                    </v-flex>
                    <v-layout row shrink class="actual-start-time">
                      <v-flex>
                        <v-layout row>
                          <v-flex d-flex align-center class="start-time">
                            Viewable Anytime
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex d-flex class="game-card-green" :style="cardBodyStyles"> </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  name: "GameYouTube",
  props: {
    game: {
      type: Object,
      required: true
    },
    canPlay: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    name() {
      return this.game.externalName || this.game.name
    },
    isTwitch() {
      return this.game.gameType === "Twitch"
    },
    cardBodyStyles() {
      return this.game.image
        ? { "background-image": `url("${this.game.image}")` }
        : null
    }
  }
}
</script>

<style lang="scss">
.game-info-youtube {
  line-height: 1;
  background-color: $color-white;

  .game-card-green {
    position: relative;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    background-color: $color-grey;
    background-size: cover;
    background-position: center;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .game-card-layout {
      position: relative;
    }
  }
  .game-card-header {
    position: relative;
    min-height: 40px;
    background-color: $color-white;
    & > .column {
      position: relative;
      z-index: 1;
    }
    .game-title {
      height: 60px;
      background-color: $color-white;
      .game-title-name {
        position: relative;
        display: block;
        background-color: $color-white;
        padding: 10px 12px;
        padding-right: 145px;
        .actual-start-time {
          font-size: 14px;
          font-weight: bold;
          padding-top: 2px;
          .start-time {
            flex: none;
            padding-right: 12px;
          }
        }
        .game-name {
          margin-bottom: 3px;
          h1 {
            font-size: 20px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
